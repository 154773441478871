/**
 * Parse and build items from the RDF data
 * Used to build a sidebar for an ontology or a tree structure
 * 
 * Description: This form provides the fields representing the use case information
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-12
 * 
 */


// Function to recursively build sidebar items from RDF data
const buildSidebarItems = (rdfData) => {
  const sidebarItems = [];
  
  // Helper function to recursively build subconcepts
  const buildSubconcepts = (parentClass) => {
    const parentItem = {
      title: parentClass.label,
      subconcepts: [],
      collapsed: true,
      linkedData: false, // False as it's retrieved directly from the RDF data
    };
    
    // Find subclasses of the parent class
    const subclassObjects = rdfData.filter(item => item.subclasses && item.subclasses.includes(parentClass.classNode));
    
    // Recursively build subconcepts for each subclass
    subclassObjects.forEach(subclass => {
      const subItem = buildSubconcepts(subclass);
      parentItem.subconcepts.push(subItem);
    });

    return parentItem;
  };

  // Find top-level classes (classes without a parent)
  const topLevelClasses = rdfData.filter(item => !item.subclasses || item.subclasses.length === 0);

  // Build sidebar items for each top-level class
  topLevelClasses.forEach(cls => {
    const topLevelItem = buildSubconcepts(cls);
    sidebarItems.push(topLevelItem);
  });

  return sidebarItems;
};

export { buildSidebarItems };