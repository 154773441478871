/**
 * Model Actor View
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-17
 * 
 */

import React, { useState, useEffect, useRef } from 'react';
import { Button, OverlayTrigger, Tooltip, Accordion, Badge, ListGroup } from 'react-bootstrap';
import vis from 'vis-network';

export const ActorView = ({ nodes, expandedCategory, onToggle }) => {
    // Group nodes by their categories
    const categoryNodes = nodes.value.reduce((acc, node) => {
      if (!acc[node.group]) {
        acc[node.group] = [];
      }
      acc[node.group].push(node);
      return acc;
    }, {});
  
    return (
      <Accordion activeKey={expandedCategory}>
        {Object.keys(categoryNodes).map((category, index) => (
          <Accordion.Item eventKey={category} key={category}>
            <Accordion.Header onClick={() => onToggle(category)}>
              <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', marginRight:'20px' }}>
                <strong>{category}</strong>
                <Badge pill bg="primary">
                  {categoryNodes[category].length}
                </Badge>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {categoryNodes[category].map((node) => (
                  <ListGroup.Item key={node.id}>
                    <Badge bg="light" text="dark" style={{ margin: '5px' }}>
                      {node.label}
                    </Badge>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
};

export const ComponentView = ({ components, expandedCategory, onToggle }) => {
  return (
    <Accordion activeKey={expandedCategory}>
      {components.map((category, catIndex) => (
        <Accordion.Item eventKey={category.category} key={catIndex}>
          <Accordion.Header onClick={() => onToggle(category.category)}>{category.category}</Accordion.Header>
          <Accordion.Body>
            <ListGroup>
              {category.components.map((component, compIndex) => (
                <ListGroup.Item key={compIndex}>
                  <strong>{component.description}</strong>
                  <div>
                    <p>Type: {component.type}</p>
                    <p>Status: {component.status}</p>
                    <div>
                      {component.items.map((item, itemIndex) => (
                        <Badge key={itemIndex} bg="light" text="dark" style={{ margin: '5px' }}>
                          {item}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export const ActorComponentView = ({ data, expandedCategory, onToggle }) => {
  return (
    <Accordion activeKey={expandedCategory}>
      <Accordion.Item eventKey="actors">
        <Accordion.Header onClick={() => onToggle('actors')}>
          <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', marginRight:'20px' }}>
            <strong>Actors</strong>
            <Badge pill bg="primary">
              {data.actors.nodes.length}
            </Badge>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <ActorView nodes={data.actors.nodes} expandedCategory={expandedCategory} onToggle={onToggle} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="components">
        <Accordion.Header onClick={() => onToggle('components')}>
          <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', marginRight:'20px' }}>
            <strong>Components</strong>
            <Badge pill bg="primary">
              {data.components.reduce((total, category) => total + category.components.length, 0)}
            </Badge>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <ComponentView components={data.components} expandedCategory={expandedCategory} onToggle={onToggle} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export const ActorComponentNetwork = ({ actors, components }) => {
  const networkRef = useRef(null);
  const [network, setNetwork] = useState(null);

  const [originalNodes, setOriginalNodes] = useState([]);
  const [originalEdges, setOriginalEdges] = useState([]);

  useEffect(() => {
    const nodes = [];
    const edges = [];

    // Actors as nodes
    actors.value.forEach(actor => {
      nodes.push({
        id: `actor_${actor.id}`,
        label: actor.label,
        group: 'actor',
        title: `Actor: ${actor.label}\nGroup: ${actor.group}`,
        color: 'rgba(75,192,192,0.6)',
        shape: 'box',
      });
    });

    // Components as nodes
    components.forEach(category => {
      category.components.forEach(component => {
        nodes.push({
          id: `component_${component.description}`,
          label: component.description,
          group: 'component',
          title: `Component: ${component.description}`,
          color: 'rgba(255,99,132,0.6)',
          shape: 'box',
        });

        actors.value.forEach(actor => {
          if (actor.group === category.category) {
            edges.push({
              from: `actor_${actor.id}`,
              to: `component_${component.description}`,
              color: 'rgba(153,102,255,0.8)',
              width: 2,
            });
          }
        });
      });
    });

    // Store original nodes and edges for reset functionality
    setOriginalNodes(nodes);
    setOriginalEdges(edges);

    // Initialize network
    const container = networkRef.current;
    const data = { nodes, edges };
    const options = {
      nodes: {
        borderWidth: 2,
        size: 20,
        font: { size: 14 },
      },
      edges: { length: 150 },
      interaction: { hover: true, zoomView: true },
      physics: { stabilization: true },
    };

    const newNetwork = new vis.Network(container, data, options);
    setNetwork(newNetwork);

    return () => {
      newNetwork.destroy(); // Clean up
    };
  }, [actors, components]);

  // Search functionality
  const handleSearch = (searchTerm) => {
    const nodeId = network.body.data.nodes.getIds().find((id) => {
      const node = network.body.data.nodes.get(id);
      return node.label.toLowerCase() === searchTerm.toLowerCase();
    });

    if (nodeId) {
      network.focus(nodeId, { scale: 1.5, animation: { duration: 1000 } });
      network.selectNodes([nodeId]); // Highlight the node
    }
  };

  // Fit network
  const handleFitNetwork = () => {
    network.fit({ animation: true });
  };

  // Cluster all actors
  const handleClusterActors = () => {
    const clusterOptionsByData = {
      joinCondition: function (nodeOptions) {
        return nodeOptions.group === 'actor'; // Only cluster actors
      },
      processProperties: function (clusterOptions, childNodes) {
        const totalActors = childNodes.length;
        clusterOptions.label = `Cluster: ${totalActors} Actors`;
        clusterOptions.title = `Cluster of ${totalActors} actors`;
        return clusterOptions;
      },
      clusterNodeProperties: {
        borderWidth: 3,
        shape: 'circle',
        font: { size: 16, bold: true },
      },
    };
    network.cluster(clusterOptionsByData);
  };

  // Cluster all components
  const handleClusterComponents = () => {
    const clusterOptionsByData = {
      joinCondition: function (nodeOptions) {
        return nodeOptions.group === 'component'; // Only cluster components
      },
      processProperties: function (clusterOptions, childNodes) {
        const totalComponents = childNodes.length;
        clusterOptions.label = `Cluster: ${totalComponents} Components`;
        clusterOptions.title = `Cluster of ${totalComponents} components`;
        return clusterOptions;
      },
      clusterNodeProperties: {
        borderWidth: 3,
        shape: 'box',
        font: { size: 16, bold: true },
      },
    };
    network.cluster(clusterOptionsByData);
  };

  // Reset to original unclustered state
  const handleResetNetwork = () => {
    const data = {
      nodes: originalNodes,
      edges: originalEdges,
    };
    network.setData(data);
  };

  // Zoom controls
  const handleZoomIn = () => {
    const currentScale = network.getScale();
    network.moveTo({ scale: currentScale * 1.2 });
  };

  const handleZoomOut = () => {
    const currentScale = network.getScale();
    network.moveTo({ scale: currentScale * 0.8 });
  };

  return (
    <div className="network-wrapper">

      <div className="d-flex justify-content-between align-items-center" style={ { marginBottom:'15px' } }>
        {/* Search Box */}
        <input
          type="text"
          placeholder="Search actors or components..."
          onChange={(e) => handleSearch(e.target.value)}
          style={{ padding: '5px', width: '300px', borderRadius: '5px', border: '1px solid #ddd' }}
        />
        <div>
          {/* Zoom Controls */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Zoom In</Tooltip>}>
            <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleZoomIn}>
              <i className="bi bi-zoom-in"></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Zoom Out</Tooltip>}>
            <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleZoomOut}>
              <i className="bi bi-zoom-out"></i>
            </Button>
          </OverlayTrigger>

          {/* Fit Network */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Fit to Screen</Tooltip>}>
            <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleFitNetwork}>
              <i className="bi bi-arrows-fullscreen"></i>
            </Button>
          </OverlayTrigger>

          {/* Cluster Actors */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Cluster Actors</Tooltip>}>
            <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleClusterActors}>
              <i className="bi bi-people"></i>
            </Button>
          </OverlayTrigger>

          {/* Cluster Components */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Cluster Components</Tooltip>}>
            <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleClusterComponents}>
              <i className="bi bi-diagram-3"></i>
            </Button>
          </OverlayTrigger>

          {/* Reset Network */}
          <OverlayTrigger placement="top" overlay={<Tooltip>Reset Network</Tooltip>}>
            <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleResetNetwork}>
              <i className="bi bi-arrow-counterclockwise"></i>
            </Button>
          </OverlayTrigger>   
        </div>
      </div>

      {/* Network Container with Border */}
      <div ref={networkRef} className="network-container" style={{ height: '600px', border: '2px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }} />

      {/* Legend */}
      <div className="legend mt-3">
        <h5>Legend:</h5>
        <div><span style={{ backgroundColor: 'rgba(75,192,192,0.6)', padding: '5px', borderRadius: '5px', display: 'inline-block', width: '20px', marginRight: '10px' }} /> Actors</div>
        <div><span style={{ backgroundColor: 'rgba(255,99,132,0.6)', padding: '5px', borderRadius: '5px', display: 'inline-block', width: '20px', marginRight: '10px' }} /> Components</div>
        <div><span style={{ backgroundColor: 'rgba(153,102,255,0.8)', padding: '5px', borderRadius: '5px', display: 'inline-block', width: '20px', marginRight: '10px' }} /> Connections</div>
      </div>
    </div>
  );
};



