/**
 * Provides an aggregated view of the modeled use case, including the actors
 *
 * Description: ...
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-16
 * 
 */

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Badge, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import vis from 'vis-network';

//import EcoUCDashboard from '../eco_analytics_handling/eco_uc_dashboard';

const UseCaseDiagram = ({ data, networkRef }) => {

  const containerRef = useRef(null);

  useEffect(() => {
    if (!data) {
      console.error("No data provided");
      return;
    }

    if (!data.actors || !data.actors.value.list || !Array.isArray(data.components.value)) {
      console.error("Invalid data structure:", data);
      return;
    }

    const actorCategories = Object.keys(data.actors.value.list);
    const nodes = [
      { id: 'UseCase', label: data.title.value, shape: 'box', color: '#ffcc00' }, // Central use case node
      ...actorCategories.flatMap((category) =>
        data.actors.value.list[category].map((actor, index) => ({
          id: `actor_${category}_${index}`,
          label: actor,
          shape: 'ellipse',
          color: '#86c5da'
        }))
      ),
      ...data.components.value.flatMap((componentCategory, index) =>
        componentCategory.components.flatMap((component, componentIndex) =>
          component.items.map((item, itemIndex) => ({
            id: `component_${index}_${componentIndex}_item_${itemIndex}`,
            label: item,
            shape: 'box',
            color: '#f3a683'
          }))
        )
      ),
    ];

    const edges = [
      ...actorCategories.flatMap((category) =>
        data.actors.value.list[category].map((_, index) => ({
          from: `actor_${category}_${index}`,
          to: 'UseCase'
        }))
      ),
      ...data.components.value.flatMap((componentCategory, index) =>
        componentCategory.components.flatMap((component, componentIndex) =>
          component.items.map((_, itemIndex) => ({
            from: `component_${index}_${componentIndex}_item_${itemIndex}`,
            to: 'UseCase'
          }))
        )
      ),
    ];

    const networkData = {
      nodes: nodes,
      edges: edges,
    };

    const options = {
      layout: {
        improvedLayout: true,
        hierarchical: false,
      },
      physics: {
        enabled: true,
        solver: 'forceAtlas2Based',
        forceAtlas2Based: {
          gravitationalConstant: -50,
          centralGravity: 0.005,
          springLength: 200,
          springConstant: 0.08,
        },
        stabilization: {
          iterations: 150,
        },
      },
      nodes: {
        shape: 'box',
        margin: 10,
        widthConstraint: {
          maximum: 200,
        },
        size: 20,
      },
      edges: {
        arrows: {
          to: {
            enabled: true,
            type: 'arrow',
          },
        },
      },
      interaction: {
        hover: true,
        hoverConnectedEdges: true,
        selectConnectedEdges: false,
      },
    };

    if (containerRef.current) {
      networkRef.current = new vis.Network(containerRef.current, networkData, options);
    }

    return () => {
      if (networkRef.current) {
        networkRef.current.destroy();
      }
    };
  }, [data, networkRef]);

  return  (
    <Card className="mb-3 h-100" style={{ padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
      <div ref={containerRef} style={{ height: '500px', width: '100%' }} />
    </Card>
  );
};

const countComponentsAndItemsByCategory = (components) => {
  return components.map(category => {
      const totalComponents = category.components.length;
      const totalItems = category.components.reduce((sum, comp) => {
          return sum + (comp.items ? comp.items.length : 0);
      }, 0);

      //console.log('Category:', category.category, 'Total components:', totalComponents, 'Total items:', totalItems);
      
      return {
          category: category.category,
          totalComponents,
          totalItems
      };
  });
};

const UseCaseSummary = ({ data }) => {

  return (
    <Card className="mb-3 h-100" style={{ padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
      <Row>
        <Col md={12} align='left'>
          <div style={{ fontSize: '20px', color: '#0d6efd' }}>
            <i className="bi bi-box"></i> {data.title.value} <Badge bg="primary" style={{ marginLeft: '10px' }}>{data.acronym.value}</Badge>
          </div>          
        </Col>
      </Row>
      <Row align='left' style={{ marginTop: '30px' }}>
        <Col md={5}>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-clipboard-check"></i> <strong>Status:</strong> {data.status.value}
          </div>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-clipboard-check"></i> <strong>Maturity:</strong> {data.maturity.value}
          </div>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-clipboard-check"></i> <strong>Application:</strong> {data.application.value}
          </div>
        </Col>
        <Col md={7}>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-calendar"></i> <strong>Created:</strong> {data.created.value}
          </div>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-calendar"></i> <strong>Updated:</strong> {data.updated.value}
          </div>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-person"></i> <strong>Created by:</strong> {data.created_by.value}
          </div>
        </Col>        
      </Row>
      <Row align='left'>
        <Col md={12}>
            <div style={{ fontSize: '14px', marginBottom: '10px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {data.continents.value !== undefined && data.continents.value.length > 0 && (
                    <span>{data.continents.value.join(", ")}: </span>
                )}
                
                {data.country.value && Array.isArray(data.country.value) && Array.isArray(data.country_flag.value) ? (
                    data.country.value.map((country, index) => (
                        <span key={index} style={{ margin: '0 2px' }}>
                            {country} {data.country_flag.value[index]}
                        </span>
                    ))
                ) : (
                    <span style={{ margin: '0 2px' }}>
                        {data.country.value} {data.country_flag.value}
                    </span>
                )}

                {data.city.value !== undefined && data.city.value.length > 0 && (
                    <>
                        <span style={{ margin: '0 2px' }}>, </span>
                        <span>
                            {Array.isArray(data.city.value) ? data.city.value.join(", ") : data.city.value}
                        </span>
                    </>
                )}
            </div>
        </Col>
      </Row>
      <Row align='left' style={{ marginTop: '10px' }}>
        <Col md={12}>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-info-circle"></i> <strong>Description:</strong> {data.description.value}
          </div>
          <div style={{ fontSize: '14px', marginBottom: '10px' }}>
            <i className="bi bi-tags"></i> <strong>Tags:</strong> {data.tags.value.join(', ')}
          </div>
        </Col>
      </Row>
      <Row align='left' style={{ marginTop: '10px' }}>
        <Col md={5}>
          <div style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
            <i className="bi bi-people"></i> <strong>Actors:</strong> {data.actors.value.nodes.value.length}
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr', marginBottom: '5px' }}>
            <div style={{ fontWeight: 'bold' }}>Total:</div>
            <div>{data.actors.value.nodes.value.length + data.actors.value.edges.value.length}</div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr', marginBottom: '5px' }}>
            <div style={{ fontWeight: 'bold' }}>Categories:</div>
            <div>{Object.keys(data.actors.value.list).length}</div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr', marginBottom: '5px' }}>
            <div style={{ fontWeight: 'bold' }}>Actors:</div>
            <div>{data.actors.value.nodes.value.length}</div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '150px 1fr', marginBottom: '5px' }}>
            <div style={{ fontWeight: 'bold' }}>Connections:</div>
            <div>{data.actors.value.edges.value.length}</div>
          </div>
        </Col>
        <Col md={7}>
          <div style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
            <i className="bi bi-cpu"></i> <strong>Components:</strong> 
          </div>
          <div style={{ textAlign: 'left', paddingLeft: '10px' }}>              
            {countComponentsAndItemsByCategory(data.components.value).map((category, index) => (       
              <div key={index} style={{ display: 'grid', gridTemplateColumns: '150px 1fr', marginBottom: '5px' }}>
                <div>{category.category}:</div>
                <div>{category.totalComponents} components; {category.totalItems} items</div>
              </div>
            ))} 
          </div>
        </Col>
      </Row>
    </Card>
  );
};

const QuickActions = ({ isEdit, navigateAnalysis, navigateRepository, navigateUCM }) => {

  return (
    <Card className="mb-3 h-100" style={{ padding: '20px', borderRadius: '15px', boxShadow: '0 8px 16px rgba(0,0,0,0.1)', background: 'linear-gradient(145deg, #f3f4f6, #ffffff)' }}>
      <Row>
        <Col md={12} align='left'>
          <div style={{ fontSize: '22px', fontWeight: '600', color: '#0d6efd', marginBottom: '20px' }}>
            <i className="bi bi-clipboard-data"></i> Quick Actions
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col md={12}>
          <div style={{ fontSize: '16px', color: '#6c757d', marginBottom: '40px' }}>
            <i className="bi bi-pencil"></i> <strong>Mode:</strong> {isEdit ? 'Edit Use Case' : 'Create Use Case'}
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Button variant="primary" style={{ marginRight: '10px', minWidth: '300px', minHeight: '80px', fontSize: '18px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(13,110,253,0.3)' }} onClick={navigateAnalysis}>
              <i className="bi bi-graph-up"></i> <b>Analyze Use Case</b>
            </Button>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Button variant="primary" style={{ marginRight: '10px', minWidth: '300px', minHeight: '80px', fontSize: '18px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(13,110,253,0.3)' }} onClick={navigateRepository}>
              <i className="bi bi-database"></i> <b>Lookup Repository</b>
            </Button>
          </div>
          <div>
            <Button variant="outline-danger" style={{ marginRight: '10px', minWidth: '300px', minHeight: '80px', fontSize: '18px', borderRadius: '12px', boxShadow: '0 4px 12px rgba(220,53,69,0.3)' }} onClick={navigateUCM}>
              <i className="bi bi-door-closed"></i> <b>Exit Creator</b>
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};


const Model = ({ data, setData, useCaseDetails, isEdit, setIsEdit, useCases, handleSave, handleSaveEdit }) => {

  const networkRef = useRef(null);

  const [isInit, setIsInit] = useState(true);
  const [isQuickAction, setIsQuickAction] = useState(true);

  // provide initial save view once entered this view
  useEffect(() => {

    if (!isInit) { return; }
    
    if (!isEdit) {
      handleSave();
    } else {
      handleSaveEdit();
    }

    setIsInit(false);

  }, [handleSave, handleSaveEdit, isEdit, isInit]);

  // Individual use case inspection / dashboard handling
  //const [selectedUseCase, setSelectedUseCase] = useState(data);
  //const [comparisonType, setComparisonType] = useState('all'); // initial state in the dashboard view
  //const [selectedComparisonUseCases, setSelectedComparisonUseCases] = useState([]);

  const handleZoomIn = () => {
    if (networkRef.current) {
        const scale = networkRef.current.getScale();
        networkRef.current.moveTo({
            scale: scale * 1.2, // Zoom in
        });
    }
  };

  const handleZoomOut = () => {
      if (networkRef.current) {
          const scale = networkRef.current.getScale();
          networkRef.current.moveTo({
              scale: scale * 0.8, // Zoom out
          });
      }
  };

  const navigate = useNavigate();

  const navigateRepository = () => {
    navigate('/Repository');
  };

  const navigateAnalysis = () => {
    navigate('/Analytics');
  }

  const navigateUCM = () => {
    navigate('/UCM');
  }

  return (    
    <div>      
      <Row>
        {/* Headline and Toolbar */}
        <Col md={3}>
            <div>
                <h3 className="text-start">Model Actors</h3>
                <h6 className="text-start">Look up, adjust or add manually</h6>
            </div>
        </Col>
        {/* Toolbar */}
        <Col md={9} className="d-flex justify-content-end align-items-center">      
            
            <OverlayTrigger placement="top" overlay={<Tooltip>Go to analytics to analyze the use case</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={navigateAnalysis}>
                    <i className="bi bi-graph-up"></i>
                </Button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>Look up your use case in the repository</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={navigateRepository}>
                    <i className="bi bi-database"></i>
                </Button>
            </OverlayTrigger>

            <div>
              {/*}
              {!isEdit && (
                <OverlayTrigger placement="top" overlay={<Tooltip>Save to Repository</Tooltip>}>
                  <Button style={{ marginLeft: '10px' }} variant="outline-success" onClick={handleSave}>
                    <i className="bi bi-floppy"></i>
                  </Button>
                </OverlayTrigger>
              )}
              */}
              {isEdit && (
                <OverlayTrigger placement="top" overlay={<Tooltip>Save Update</Tooltip>}>
                  <Button style={{ marginLeft: '10px' }} variant="outline-success" onClick={handleSaveEdit}>
                    <i className="bi bi-pencil"></i>
                  </Button>
                </OverlayTrigger>
              )}
            </div>

            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Toggle quick actions</Tooltip>}>
            <Button onClick={() => setIsQuickAction(!isQuickAction)} style={{ marginLeft: '10px' }} variant="outline-secondary">
                <i className="bi bi-gear"></i>
            </Button>
            </OverlayTrigger>
            {!isQuickAction && (
            <>
            <Button onClick={() => handleZoomIn()} style={{ marginLeft: '10px' }} variant="outline-secondary">
                    <i className="bi bi-zoom-in"></i>
            </Button>
            <Button onClick={() => handleZoomOut()} style={{ marginLeft: '10px' }} variant="outline-secondary">
                <i className="bi bi-zoom-out"></i>
            </Button>
            </>
            )}
            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>

            <OverlayTrigger placement="top" overlay={<Tooltip>Download actor landscape</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                    <i className="bi bi-download"></i>
                </Button>
            </OverlayTrigger>    
        </Col>
      </Row>   
      <Row style={{marginTop: '15px' }} className='h-100'>
        <Col md={5}>
          <Row className='h-100'>
            <UseCaseSummary data={data} />
          </Row>        
        </Col>
        <Col md={7}>
          {isQuickAction ? (
            <QuickActions isEdit={isEdit} navigateAnalysis={navigateAnalysis} navigateRepository={navigateRepository} navigateUCM={navigateUCM} />
          ) : (
            <UseCaseDiagram data={data} networkRef={networkRef} />
         )}
        </Col>
      </Row>    
      {/*}
      <Row>
        {selectedUseCase.actors.nodes && (
        <EcoUCDashboard
          mode={'Compile Use Case Model'}
          data={useCases}
          selectedUseCase={selectedUseCase}
          setSelectedUseCase={setSelectedUseCase}
          comparisonType={comparisonType}
          setComparisonType={setComparisonType}
          selectedComparisonUseCases={selectedComparisonUseCases}
          setSelectedComparisonUseCases={setSelectedComparisonUseCases}
          isEdit={isEdit}
          handleSave={handleSave}
          handleSaveEdit={handleSaveEdit}
        />
        )}
      </Row>
      */}      
    </div>
  );
};

export default Model;
