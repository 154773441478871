/**
 * Handle the expansion of all sidebar items.
 * 
 * Description: This form provides the fields representing the use case information
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-12
 * 
 */

const expandAllItems = (sidebarItems) => {
    const updatedSidebarItems = sidebarItems.map((item) => {
      const updatedItem = { ...item };
      updatedItem.collapsed = false; // Expand the item
      if (updatedItem.subconcepts) {
        updatedItem.subconcepts = expandAllItems(updatedItem.subconcepts); // Recursively expand subconcepts
      }
      return updatedItem;
    });
    return updatedSidebarItems;
 };
  
const collapseAllItems = (sidebarItems) => {
    const updatedSidebarItems = sidebarItems.map((item) => {
      const updatedItem = { ...item };
      updatedItem.collapsed = true; // Collapse the item
      if (updatedItem.subconcepts) {
        updatedItem.subconcepts = collapseAllItems(updatedItem.subconcepts); // Recursively collapse subconcepts
      }
      return updatedItem;
    });
    return updatedSidebarItems;
};

export { expandAllItems, collapseAllItems };