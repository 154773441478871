/**
 * Offcanvas component for the chat modal
 * Currently not in use
 */

import React, { useState } from 'react';
import { Modal, Button, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Chat from '../guide/chat';

import { getCurrentDateTime } from '../utils/utils_date';
import { UserInformation } from '../auth_mgmt/auth_handling';

import JourneyBar from '../uc_handling/uc_journey';
//import LoadingModal from '../elems/elem_load_prompt_gen';

const ChatModal = ({ domainModels, setDomainModels, data, setData, user, messages, inputValue, handleSubmit, handleChange, onClose }) => {

    const [showModal, setShowModal] = useState(true);
    //const [isLoading, setIsLoading] = useState(false);

    const [selectedCard, setSelectedCard] = useState(null);

    const [currentStep, setCurrentStep] = useState(0);
    const steps = ['General', 'Domain', 'Prompt'];
    const [copilotSteps] = useState(['']);

    /**
     * Navigate to the next step
     * @param {*} stepIndex 
     */
    const handleStepClick = (stepIndex) => {
        if (stepIndex === 2 && data.tags.value.length === 0) {
            alert('Please select a domain area first.');
            return;
        }
        setCurrentStep(stepIndex);
    };

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    const handleCardClick = (card) => {
        setSelectedCard(card);
    };
    
    const getCardContent = (card) => {
    switch (card) {
        case 'guco':
            return "In UCM, the Generic Use Case Ontology (GUCO) is used as a knowledge representation for use cases.The Explorer module provides insights to its elements.";
        case 'domain':
            return "Select a domain area matching your use case; if not available, please select the `Baseline (agnostic)` option."; // go back and upload a dedicated semantic model in the Explorer module or use the advanced settings
        default:
            return "";
    }
    };

    const closeModal = () => {
        setShowModal(false);
        onClose();
    };

    // Handle the prompt submission and trigger the loading modal
    const handlePromptSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        //setIsLoading(true);
        closeModal();
        await handleSubmit(event);  // Pass the event if exists
        //setIsLoading(false);        
    };

    return (
        <>
        <Modal show={showModal} onHide={closeModal} placement="end">
            <Modal.Header closeButton>
                <Modal.Title>UCM Co-Pilot</Modal.Title> {/* Association Guide */}
            </Modal.Header>
            <Modal.Body>

                <p>You decide to what degree you add knowledge to UCM. The better tailored, the better UCM can guide you through the process of use case generation.</p>

                {/* Journey bar*/}
                <Row style={ { marginBottom:'30px' } }>
                    <JourneyBar steps={steps} currentStep={currentStep} onStepClick={handleStepClick} copilotSteps={copilotSteps} />
                </Row>

                {/* GUCO */}
                {currentStep === 0 && (
                <Row align='center' className="d-flex justify-content-center">
                    <Col className="d-flex">
                        <Card 
                            className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'guco' ? 'bg-primary text-white' : ''}`} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => handleCardClick('guco')}
                        >
                            <Row>
                                <Col md={5}>
                                    <Card.Body>
                                    <i className="bi bi-compass" style={{ fontSize: '2rem' }}></i>
                                    <Card.Title>Baseline Semantic Model (GUCO)</Card.Title>
                                    </Card.Body>
                                </Col>
                                <Col>
                                    <Card.Body align='right'>
                                        <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                            <i className="bi bi-info-circle"></i> {getCardContent('guco')}
                                        </Card.Text>
                                        <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Move on to define the domain area</Tooltip>}>
                                        <Button variant="light" size="sm" onClick={() => handleStepChange(currentStep + 1)}>
                                            <i className="bi bi-arrow-right"></i> Next
                                        </Button>
                                        </OverlayTrigger>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                )}

                {/* Domain */}
                {currentStep === 1 && (
                <Row>
                    <Col className="d-flex">
                        <Card 
                            className={`text-center mb-3 flex-fill w-100 ${selectedCard === 'domain' ? 'bg-primary text-white' : ''}`} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => handleCardClick('domain')}
                        >
                            <Row>
                                <Col md={5}>
                                    <Card.Body>
                                        <i className="bi bi-geo" style={{ fontSize: '2rem' }}></i>
                                        <Card.Title>Domain Semantic Model</Card.Title>
                                    </Card.Body>
                                </Col>
                                <Col>
                                    <Card.Body align='right'>
                                        <Card.Text style={{ fontSize:'12px',  }} align='right'>
                                            <i className="bi bi-info-circle"></i> {getCardContent('domain')}
                                        </Card.Text>

                                        {/* select existing domain area */}
                                        {/*
                                        <label className="form-label small">Select a domain area:</label>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <select className="form-select" onChange={(e) => {
                                                const selectedModel = e.target.value;
                                                if (selectedModel && !data.tags.includes(selectedModel)) {
                                                    setData(prevData => (
                                                        { 
                                                            ...prevData, 
                                                            tags: [...prevData.tags, selectedModel],
                                                            // get acronym of selected model
                                                            domain_model: domainModels.find(model => model.name === selectedModel).acronym,
                                                        }));
                                                    setDomainModels(prevModels => {
                                                        const updatedModels = prevModels.map(model => {
                                                            if (model.name === selectedModel) {
                                                                model.isSelected = true;
                                                            }
                                                            return model;
                                                        });
                                                        return updatedModels;
                                                    }
                                                    );
                                                }
                                            }}>
                                                <option value="">-- Select Domain Model --</option>
                                                {domainModels.map((model, index) => (
                                                <option key={index} value={model.name} disabled={!model.isActive}>
                                                    {model.name}
                                                </option>
                                                ))}
                                            </select>
                                        </div>
                                        */}
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <select
                                                className="form-select"
                                                onChange={(e) => {
                                                    const selectedModel = e.target.value;

                                                    if (selectedModel) {
                                                        // Update the data state to include only the selected domain model
                                                        /*
                                                        setData((prevData) => ({
                                                            ...prevData,
                                                            tags: [selectedModel], // Ensure only the selected model is in the tags array
                                                            domain_model: domainModels.find((model) => model.name === selectedModel).acronym,
                                                        }));                                                        
                                                        */

                                                        setData((prevData) => ({
                                                            ...prevData,
                                                            tags: {
                                                                value: [selectedModel],
                                                                source: 'copilot',
                                                                modified: true,
                                                                created_timestamp: getCurrentDateTime(),
                                                                updated_by: UserInformation.displayName,
                                                                uid: UserInformation.uid,                        
                                                            },
                                                            domain_model: {
                                                                value: domainModels.find((model) => model.name === selectedModel).acronym,
                                                            }
                                                        }));                                                        
                                                    }

                                                            // Update the domainModels state to reflect the selection
                                                            setDomainModels((prevModels) =>
                                                                prevModels.map((model) => ({
                                                                    ...model,
                                                                    isSelected: model.name === selectedModel, // Mark only the selected model as true
                                                                }))
                                                            );
                                                }}
                                            >
                                                <option value="">-- Select Domain Model --</option>
                                                {domainModels.map((model, index) => (
                                                    <option key={index} value={model.name} disabled={!model.isActive}>
                                                        {model.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {data.tags.value.length > 0 && (
                                            <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip">Move on and input the prompt to get your use case modeled</Tooltip>}>
                                            <Button variant="light" size="sm" onClick={() => handleStepChange(currentStep + 1)}>
                                                <i className="bi bi-arrow-right"></i> Next
                                            </Button>
                                            </OverlayTrigger>
                                        )}
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                )}

                {/*}
                <p>Provide some input to your envisioned use case and UCM will guide you through the process.</p>
                */}
                {currentStep === 2 && (
                    <Chat user={user} messages={messages} handleSubmit={handlePromptSubmit} inputValue={inputValue} handleChange={handleChange} />
                )}
            </Modal.Body>
            <Modal.Footer>                
                {inputValue && inputValue.length > 0 && (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Click here and UCM will apply its method and semantic model on your initial prompt to build up your entered use case in question</Tooltip>}>
                        <Button variant="outline-primary" onClick={handlePromptSubmit}>
                            <i className="bi bi-send"></i> Let's go
                        </Button>
                    </OverlayTrigger>
                )}
                {/*}
                {!inputValue && (
                    <>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Go to repository to select and use an existing use case as a template</Tooltip>}>
                        <Button variant="outline-primary" onClick={navigateRepository}>
                            <i className="bi bi-database"></i> Repository
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Model the use case with manual input only</Tooltip>}>
                        <Button variant="outline-primary" onClick={closeModal}>
                            <i className="bi bi-keyboard"></i> Create manually
                        </Button>
                    </OverlayTrigger>
                    </>
                )}
                */}
            </Modal.Footer>
        </Modal>
        {/*
        <LoadingModal show={isLoading} messages={messages} />
        */}
        </>
    );
};

export default ChatModal;