/**
 * Utils for handling transcribed answers in the study interview component.
 *
 * Description: Used in the table and for further analysis
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 */

import { useState, useEffect } from 'react';

// Translate the transcription to English if needed
export const translateToEnglish = async (openai, transcription) => {
    
    if (!transcription) return 'No translation available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are a language expert that translates text to English."
                },
                {
                    role: "user",
                    content: `Only if not yet in English, translate the following transcription to English:\n"${transcription}". Just provide the translation.`
                }
            ],
        });
        
        const translation = response.choices[0].message.content;
        return translation || 'Translation failed';
    } catch (error) {
        console.error("Error translating to English:", error);
        return 'Translation failed';
    }
};

// Generate a summary using OpenAI's Chat API
export const generateSummary = async (openai, transcription) => {

    if (!transcription) return 'No transcription available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are a helpful assistant that summarizes text."
                },
                {
                    role: "user",
                    content: `Summarize the following transcription in 3 phrases or less:\n"${transcription}". Return 'No summary', if the answer is not clear / addressing the question.`
                }
            ],
            //max_tokens: 50
        });

        const summary = response.choices[0].message.content;
        return summary || 'Summary not available';
    } catch (error) {
        console.error("Error generating summary:", error);
        return 'Summary generation failed';
    }
};

// Extract keywords using OpenAI's Chat API
export const extractKeywords = async (openai, transcription) => {

    if (!transcription) return 'No keywords available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are a helpful assistant that extracts keywords."
                },
                {
                    role: "user",
                    content: `Extract the most relevant keywords from the following text:\n"${transcription}". Just provide the term or terms. Do not provide any phrases, sentences, or bullets; just the keywords in a comma-separated list.`
                }
            ],
            //max_tokens: 20
        });

        const keywords = response.choices[0].message.content;
        return keywords || 'Keywords not available';
    } catch (error) {
        console.error("Error extracting keywords:", error);
        return 'Keywords extraction failed';
    }
};

// Detect the theme using OpenAI's Chat API
export const detectTheme = async (openai, transcription) => {

    if (!transcription) return 'No theme detected';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are a helpful assistant that identifies themes."
                },
                {
                    role: "user",
                    content: `Determine the general theme for the following transcription:\n"${transcription}". Possible themes may include Platform Economy, Autonomous Mobility, Distributed AI or General, to name just a few examples. Just provide the term or terms, max. 3. Do not provide any phrases or sentences.`
                }
            ],
            //max_tokens: 20
        });

        const theme = response.choices[0].message.content;
        return theme || 'Theme detection failed';
    } catch (error) {
        console.error("Error detecting theme:", error);
        return 'Theme detection failed';
    }
};
  
// Detect the theme of the answer
/*
const detectTheme = (transcription) => {
    if (!transcription) return 'No theme detected';

    // Basic logic for detecting the theme; you can refine this
    if (transcription.includes('platform') || transcription.includes('economy')) {
        return 'Platform Economy';
    }
    if (transcription.includes('urban') || transcription.includes('mobility')) {
        return 'Urban Mobility';
    }
    if (transcription.includes('autonomous') || transcription.includes('vehicles')) {
        return 'Autonomous Mobility';
    }

    return 'General';
};
*/

// Detect the original recorded language
export const detectOriginalLanguage = async (openai, transcription) => {

    if (!transcription) return 'Language detection failed';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are a language expert that identifies the original language of a given text."
                },
                {
                    role: "user",
                    content: `Identify the original language of the following transcription:\n"${transcription}" (e.g., EN, DE). Just return the language code, e.g. EN, DE.`
                }
            ],
            //max_tokens: 20
        });

        const language = response.choices[0].message.content;
        return language || 'Language detection failed';
    } catch (error) {
        console.error("Error detecting language:", error);
        return 'Language detection failed';
    }
};

// Extract contextual tags
export const extractContextualTags = async (openai, transcription) => {

    if (!transcription) return 'No tags available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are an expert that extracts contextual tags from a given text."
                },
                {
                    role: "user",
                    content: `Extract the most relevant contextual tags from the following transcription:\n"${transcription}". Just provide the tags in a comma-separated list.`
                }
            ],
            //max_tokens: 50
        });

        const tags = response.choices[0].message.content.split(',').map(tag => tag.trim());
        return tags || [];
    } catch (error) {
        console.error("Error extracting tags:", error);
        return [];
    }
};

// Perform sentiment analysis
export const analyzeSentiment = async (openai, transcription) => {

    if (!transcription) return { sentiment: 'Neutral', score: 0.5 };

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are a sentiment analysis expert. Analyze the sentiment of a given text."
                },
                {
                    role: "user",
                    content: `Analyze the sentiment of the following transcription:\n"${transcription}". Return 'Positive', 'Negative' or 'Neutral'. Look at the overall sentiment of the text.`
                }
            ],
            max_tokens: 50
        });

        const sentimentResponse = response.choices[0].message.content;
        const sentiment = sentimentResponse.includes('positive') ? 'Positive'
                         : sentimentResponse.includes('negative') ? 'Negative'
                         : 'Neutral';
        const score = sentiment === 'Positive' ? 0.9 : sentiment === 'Negative' ? 0.2 : 0.5;

        return { sentiment, score };
    } catch (error) {
        console.error("Error analyzing sentiment:", error);
        return { sentiment: 'Neutral', score: 0.5 };
    }
};

// Estimate answer quality based on relevance
export const estimateAnswerQuality = async (openai, transcription) => {

    if (!transcription) return 'Answer quality evaluation failed';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are a helpful assistant that evaluates the quality of an answer."
                },
                {
                    role: "user",
                    content: `Evaluate the quality of the following answer based on its relevance and completeness:\n"${transcription}"`
                }
            ],
            max_tokens: 50
        });

        const quality = response.choices[0].message.content;
        return quality || 'Answer quality evaluation failed';
    } catch (error) {
        console.error("Error estimating answer quality:", error);
        return 'Answer quality evaluation failed';
    }
};

// Extract findings from the transcription in a comma-separated list; these are quotes from the transcription
export const extractFindings = async (openai, transcription) => {

    if (!transcription) return 'No findings available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are an expert that extracts findings from a given interview response."
                },
                {
                    role: "user",
                    content: `Extract the most relevant findings from the following transcription:\n"${transcription}". Just provide the findings in a comma-separated list.`
                }
            ],
            //max_tokens: 50
        });

        const findings = response.choices[0].message.content.split(',').map(finding => finding.trim());
        return findings || [];
    }
    catch (error) {
        console.error("Error extracting findings:", error);
        return [];
    }

};

// Extract the main argument from the transcription
export const extractMainArgument = async (openai, transcription) => {

    if (!transcription) return 'No main argument available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are an expert that extracts the main argument from a given interview response."
                },
                {
                    role: "user",
                    content: `Extract the main argument from the following transcription:\n"${transcription}".`
                }
            ],
            //max_tokens: 50
        });
        
        const mainArgument = response.choices[0].message.content;
        return mainArgument || 'Main argument extraction failed';
    } catch (error) {
        console.error("Error extracting main argument:", error);
        return 'Main argument extraction failed';
    }
};

export const extractQuotes = async (openai, transcription) => {

    if (!transcription) return 'No quotes available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are an expert that extracts quotes from a given interview response."
                },
                {
                    role: "user",
                    content: `Extract the most relevant quotes from the following transcription:\n"${transcription}". Just provide the quotes in a comma-separated list and make sure the quote is actually a quote and meaningful.`
                }
            ],
            //max_tokens: 50
        });

        const quotes = response.choices[0].message.content.split(',').map(quote => quote.trim());

        return quotes || [];
    }
    catch (error) {
        console.error("Error extracting quotes:", error);
        return [];
    }
};

export const extractResponseRelevance = async (openai, transcription) => {

    if (!transcription) return 'No response relevance available';

    try {
        const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are an expert that evaluates the response relevance of a given interview response."
                },
                {
                    role: "user",
                    content: `Evaluate the response relevance of the following transcription to its question:\n"${transcription}". Return a semicolon-separated list with 3 entries how the response addresses 1) platform economy, 2) autonomous mobility solutions, 3) distributed AI.`
                }
            ],
            //max_tokens: 50
        });

        //const relevance = response.choices[0].message.content;
        const relevance = response.choices[0].message.content.split(';').map(rel => rel.trim());
        //console.log("Relevance:", relevance);
        return relevance || 'Relevance evaluation failed';
    }
    catch (error) {
        console.error("Error evaluating the response relevance:", error);
        return 'Response Relevance evaluation failed';
    }

};

// Custom hook to perform transcription analysis
export const useTranscriptionAnalysis = (openai, transcription, setIsProcessed) => {

    const [targetLangTranscription, setTargetLangTranscription] = useState('');
    const [summary, setSummary] = useState('');
    const [theme, setTheme] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [originalLanguage, setOriginalLanguage] = useState('');
    const [contextualTags, setContextualTags] = useState([]);
    const [sentiment, setSentiment] = useState('');
    const [sentimentScore, setSentimentScore] = useState(0);
    const [answerQuality, setAnswerQuality] = useState('');
    const [findings, setFindings] = useState([]);
    const [mainArgument, setMainArgument] = useState('');
    const [quotes, setQuotes] = useState([]);
    const [relevance, setRelevance] = useState([]);

    useEffect(() => {

        if (!transcription) return;
        let isCancelled = false;

        const analyzeTranscription = async () => {                        
            try {

                //console.log("Transcription:", transcription);

                // Generate target language transcription on which to perform analysis
                const detectedLanguage = await detectOriginalLanguage(openai, transcription);
                const translateTranscription = detectedLanguage !== 'EN' ? 
                    await translateToEnglish(openai, transcription) : transcription;

                if (!isCancelled) {
                    setTargetLangTranscription(translateTranscription);
                    setOriginalLanguage(detectedLanguage);
                }

                //console.log("Target language transcription:", translateTranscription);

                // Perform analysis
                const [generatedSummary, detectedTheme, extractedKeywords, extractedTags, { sentiment, score }, quality, findings, mainArgument, quotes, relevance] = await Promise.all([
                    generateSummary(openai, translateTranscription),
                    detectTheme(openai, translateTranscription),
                    extractKeywords(openai, translateTranscription),
                    extractContextualTags(openai, translateTranscription),
                    analyzeSentiment(openai, translateTranscription),
                    estimateAnswerQuality(openai, translateTranscription),
                    extractFindings(openai, translateTranscription),
                    extractMainArgument(openai, translateTranscription),
                    extractQuotes(openai, translateTranscription),
                    extractResponseRelevance(openai, translateTranscription)
                ]);

                if (!isCancelled) {
                    // Set the states
                    setSummary(generatedSummary);
                    setTheme(detectedTheme);
                    setKeywords(extractedKeywords);
                    setContextualTags(extractedTags);
                    setSentiment(sentiment);
                    setSentimentScore(score);
                    setAnswerQuality(quality);
                    setFindings(findings);
                    setMainArgument(mainArgument);
                    setQuotes(quotes);
                    setRelevance(relevance);

                    // Indicate the processing is complete
                    setIsProcessed(true);
                }
            
        } catch (error) {
            console.error("Error analyzing transcription:", error);
        }

    };

    analyzeTranscription();

    // Clean-up function to avoid setting state on unmounted components
    return () => {
        isCancelled = true;
    };

}, [transcription, openai, setIsProcessed]);

    return {
        targetLangTranscription,
        originalLanguage,
        summary,
        theme,
        keywords,        
        contextualTags,
        sentiment,
        sentimentScore,
        answerQuality,
        findings,
        mainArgument,
        quotes,
        relevance
    };
};