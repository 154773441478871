import React from 'react';
import './App.css';

import { BrowserRouter as Router } from 'react-router-dom';

import { CallRoutes } from './components/layout/routes'; // contains specified routes; NavBar
import { Footer } from './components/layout/Footer';

/*
import { useNavigate, Link, Redirect } from 'react-router-dom';
*/

export default function Start () {

  return (
    <div> {/* className="container-fluid" */}
      <Router>
        <div style={{ minHeight: 'calc(100vh - 210px)' }}>
          <CallRoutes/> 
        </div>                    
        {/*<Header title="peccam" subtitle="DAI-Driven PECCAM Solution Builder" />*/}            
        <Footer name="Use Case Mapper for Solution Engineering" />      
      </Router>
    </div>
  );
}

//export { Start } from './start';

  /*
  if (user) {
    return (
      <Router>
        <Routes>
          <Route path="/Start" element={<Start />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <FirebaseUI1 />
    );
  } */
  /*
  return (
    <Router>
      <div>
        {user ? (
          // If user is signed in, show the app content
          <div>
            <h1>Welcome to the App!</h1>
            <Routes>
            <Route exact path="/Start" element={<Start/>} />
            </Routes>
            
          </div>
        ) : (
          // If user is signed out, show the FirebaseUI component
          <SignIn />
        )}
      </div>
    </Router>
  );
  */