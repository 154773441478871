/**
 * Modal Add Node
 *
 * Description: Modal to edit an edge between two nodes
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-16
 * 
 */

import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddNodeModal = ({ show, onHide, nodes, onAddNode }) => {
    const [newNodeLabel, setNewNodeLabel] = useState('');
    const [selectedNodeId, setSelectedNodeId] = useState('');
  
    const handleAddNode = () => {
      if (newNodeLabel && selectedNodeId) {
        onAddNode(newNodeLabel, selectedNodeId);
        setNewNodeLabel('');
        setSelectedNodeId('');
        onHide();
      }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission
          handleAddNode();
        }
    };
  
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Actor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewNodeLabel">
                <div style={ { marginBottom: '10px' } }> 
                    <Form.Label>Actor Label</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter node label"
                        value={newNodeLabel}
                        onChange={(e) => setNewNodeLabel(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </Form.Group>
            <Form.Group controlId="formConnectedNode">
                <Form.Label>Connect to Actor</Form.Label>
                <Form.Control
                    as="select"
                    value={selectedNodeId}
                    onChange={(e) => setSelectedNodeId(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                    <option value="">Select node...</option>
                    {/*
                    {nodes.map((node) => (
                    <option key={node.id} value={node.id}>
                        {node.label}
                    </option>
                    ))}
                    */}
                    {nodes
                    .filter((node) => node.group === 'Initial' && node.id !== 'Actor')
                    .map((node) => (
                        <option key={node.id} value={node.id}>
                        {node.label}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddNode}>
            Add Actor
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default AddNodeModal;