/**
 * Transcription handling for the Study Interview component.
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 */


// Function to handle Whisper transcription via OpenAI API
/*
export const transcribeWithWhisper = async (audioBlob) => {
    try {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.mp3'); // Ensure 'audioBlob' is a valid Blob
        formData.append('model', 'whisper-1');

        // Send the audio to OpenAI Whisper API
        const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                // 'Content-Type' is automatically set by the browser when using FormData
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        return data.text; // Extract transcription text

    } catch (error) {
        console.error('Error during Whisper transcription:', error);
        return 'Transcription failed';
    }
};
*/

export const transcribeWithWhisper = async (openaiInstance, audioBlob) => {
    try {
        // Ensure the audioBlob is correctly converted to a `File` object
        const audioFile = new File([audioBlob], 'audio.mp3', { type: 'audio/mp3' });

        // Use the OpenAI SDK to create the transcription
        const transcription = await openaiInstance.audio.transcriptions.create({
            model: 'whisper-1',
            file: audioFile,
        });

        return transcription.text; // Extract transcription text

    } catch (error) {
        console.error('Error during Whisper transcription:', error);
        return 'Transcription failed';
    }
};
