/**
 * Database Management - Write survey data
 *
 * Description: This module contains functions to write data to the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 * 
 */

import { doc, updateDoc, deleteDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

const db_name = 'beta_survey';

const saveSurveyData = async (surveyData) => {
  try {
    await addDoc(collection(db, db_name), {
      ...surveyData,
      created: serverTimestamp(), // Use Firestore server timestamp
      updated: serverTimestamp()
    });
    console.log('Survey data saved successfully!');
  } catch (error) {
    console.error('Error saving survey data: ', error);
  }
};

const updateSurveyData = async (surveyData) => {
  
  try {
    const surveyDataDocRef = doc(db, db_name, surveyData.id);
    await updateDoc(surveyDataDocRef, {
      ...surveyData,
      updated: serverTimestamp()
    });
    console.log('Survey data updated successfully!');
  } catch (error) {
    console.error('Error updating survey data: ', error);
  }
  
};

// Delete a use case
const deleteSurveyData = async (surveyID) => {
  
  try {
    const surveyDataDocRef = doc(db, db_name, surveyID);
    await deleteDoc(surveyDataDocRef);
    console.log('Survey data deleted successfully!');
  } catch (error) {
    console.error('Error deleting survey data: ', error);
  }
};

export { saveSurveyData, updateSurveyData, deleteSurveyData };