/**
 * Render given concepts and their subconcepts in a collapsible tree structure
 * 
 * Description: This form provides the fields representing the use case information
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-12
 * 
 */
  
  // Function to toggle collapse state of a concept
  const toggleCollapse = (concept, sidebarItems, setSidebarItems) => {
  
    // Find the clicked concept in the sidebarItems array
    const updatedSidebarItems = sidebarItems.map((item) => {
      // If the current item is the clicked concept, toggle its collapse state
      if (item === concept) {
        return { ...item, collapsed: !item.collapsed };
      }
      // If the current item has subconcepts, recursively update them
      if (item.subconcepts) {
        return { ...item, subconcepts: toggleCollapseSubconcepts(concept, item.subconcepts) };
      }
      return item;
    });
  
    // Update the sidebarItems with the modified array
    setSidebarItems(updatedSidebarItems);
  };
  
  // Function to recursively toggle collapse state of subconcepts
  const toggleCollapseSubconcepts = (concept, subconcepts) => {
    return subconcepts.map(subconcept => {
      // If the current subconcept is the clicked concept, toggle its collapse state
      if (subconcept === concept) {
        return { ...subconcept, collapsed: !subconcept.collapsed };
      }
      // If the current subconcept has subconcepts, recursively update them
      if (subconcept.subconcepts) {
        return { ...subconcept, subconcepts: toggleCollapseSubconcepts(concept, subconcept.subconcepts) };
      }
      return subconcept;
    });
  };
  
  // Recursive function to render concepts and their immediate children
  const renderConcepts = (concepts, sidebarItems, setSidebarItems) => {
  
    return concepts.map((concept, index) => (
      <div className="text-start" key={index}>
        {/* Concept title */}
        <span
          style={{ cursor: 'pointer', marginTop: '10px', display: 'block'}}
          variant="outline-primary"
          className="text-start"
          //onClick={() => toggleCollapseNEW(index, concept, sidebarItems, setSidebarItems)}
          onClick={() => toggleCollapse(concept, sidebarItems, setSidebarItems)}
        >       
          {/* Indicator for collapsed or expanded */}
          {concept.subconcepts && concept.subconcepts.length > 0 ? (concept.collapsed ? '▶' : '▼') : ''}
          <span style={{ 
              marginLeft: concept.subconcepts && concept.subconcepts.length > 0 ? '10px' : '22px', 
              fontWeight: concept.subconcepts && concept.subconcepts.length > 0 ? 'bold' : 'normal' 
              }}>
            {concept.title}
          </span>
        </span>
        {/* Subconcepts */}
        {!concept.collapsed && concept.subconcepts && concept.subconcepts.length > 0 && (
          <ul className="list-unstyled" style={{marginLeft: '20px'}}>
            {renderConcepts(concept.subconcepts, sidebarItems, setSidebarItems)}
          </ul>
        )}
      </div>
    ));
  };

  export { renderConcepts, toggleCollapse}