/**
 * Dashboard
 *
 * Description: User dashboard view
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-23
 * 
 */

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import RepositoryView from '../components/repository_handling/uc_repository';

export default function Dashboard() {

    const [showUserCases, setShowUserCases] = useState(true);
  
    return (
        <div align='center'>
          <Container fluid>
            <h4>Dashboard</h4>
            <RepositoryView showUserCases={showUserCases} setShowUserCases={setShowUserCases} />          
          </Container>
        </div>
    );
};

export { default as Dashboard } from './Dashboard';