/**
 * Chat utilities 
 *
 * Description: This component provides the functionality for a chat-like interface to interact with the OpenAI API.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-13
 * Notes: This component is used to bundle the chat-like interface for the use case creator.
 */

import { useState, useEffect } from 'react';
import OpenAI from 'openai';
import retrieveUCDetails from './chat_uc_details';
import { systemMessage } from './chat_prompts';

import { getOpenAIKey } from '../db_mgmt/read';

// Retrieve the OpenAI API key
/*
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
*/

// Initialize the OpenAI API

/*
let openai = null;
try {
  const apiKey = await getOpenAIKey();
  openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  });
} catch (error) {
  console.error('Error fetching API key:', error);
}*/

/*
if (!apiKey) {
  //throw new Error("The REACT_APP_OPENAI_API_KEY environment variable is missing or empty");
  console.log("The REACT_APP_OPENAI_API_KEY environment variable is missing or empty");
}
*/

/*
const openai = new OpenAI({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true, // removed later on...
});
*/

/**
 * Process the prompts and delegate dedicated prompts retrieval
 * @param {*} requestMode 
 * @returns 
 */
export const useChat = (requestMode, semanticData, setIsPrompt, components_semantic_enrichment) => {

    const [messages, setMessages] = useState([{ role: 'system', content: systemMessage }]);
    const [copilotSteps, setCopilotSteps] = useState([]);
    const [useCaseDetails, setUseCaseDetails] = useState([]); // null
    const [inputValue, setInputValue] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [loaderResponse, setLoaderResponse] = useState('');

    const [openai, setOpenai] = useState(null);

    // Initialize the OpenAI API
    useEffect(() => {
      const initializeOpenAI = async () => {
        try {
          const apiKey = await getOpenAIKey();
          const openaiInstance = new OpenAI({
            apiKey: apiKey,
            dangerouslyAllowBrowser: true,
          });
          setOpenai(openaiInstance);
        } catch (error) {
          console.error('Error fetching API key:', error);
        }
      };

      initializeOpenAI();
    }, []);
  
    // Handle the input value change from the user
    const handleChange = (event) => {
      setInputValue(event.target.value);
    };
  
    // Handle the input value submission and execute the UCM prompt mapping logic
    // Includes the semantic data enrichment
    const handleSubmit = async (event) => {
      if (event) {
        event.preventDefault();
      }
  
      if (inputValue.trim() === '') return;
  
      const userMessage = { role: 'user', content: inputValue };
  
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputValue('');

      // initialize the loader
      setIsLoading(true);
  
      try {
        const completion = await openai.chat.completions.create({
          model: 'gpt-4o-mini', //'gpt-3.5-turbo', // 'gpt-4o' // 'gpt-4o'
          messages: messages.concat(userMessage),
        });
  
        const systemMessage = { role: 'system', content: completion.choices[0].message.content };
        setMessages((prevMessages) => [...prevMessages, systemMessage]);
  
        if (systemMessage.content !== null) {
          // Retrieve the use case details based on the user prompts
          
          const useCaseDetailsRes = await retrieveUCDetails(openai, systemMessage.content, requestMode, semanticData, setLoaderResponse, components_semantic_enrichment);
          setIsLoading(false);
  
          setUseCaseDetails((prevUseCaseDetails) => {
            let updatedUseCaseDetails = [...(prevUseCaseDetails || [])];
            let newCopilotSteps = [];
  
            switch (requestMode) {
              case 'init':
                newCopilotSteps = [0, 1, 2, 3, 4];
                updatedUseCaseDetails = useCaseDetailsRes;
                break;
              case 'uc_info':
                newCopilotSteps = [0];
                updatedUseCaseDetails = [...useCaseDetailsRes, ...updatedUseCaseDetails.slice(useCaseDetailsRes.length)];
                break;
              case 'uc_actions':
                newCopilotSteps = [1];
                updatedUseCaseDetails.splice(4, useCaseDetailsRes.length, ...useCaseDetailsRes);
                updatedUseCaseDetails.splice(7, useCaseDetailsRes.length, ...useCaseDetailsRes);
                break;
              case 'uc_actor':
                newCopilotSteps = [2];
                updatedUseCaseDetails.splice(19, useCaseDetailsRes.length, ...useCaseDetailsRes);
                break;
              case 'uc_components':
                newCopilotSteps = [3];
                updatedUseCaseDetails.splice(25, useCaseDetailsRes.length, ...useCaseDetailsRes);
                break;
              case 'uc_model':
                newCopilotSteps = [4];
                break;
              default:
                break;
            }
  
            setCopilotSteps(newCopilotSteps);
            return updatedUseCaseDetails;
          });

          setIsPrompt(true);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Reset variables such as messages after the completion of the chat
    useEffect(() => {
      if (useCaseDetails.length > 0) {
        setMessages([{ role: 'system', content: systemMessage }]);
        setCopilotSteps([]);
        setUseCaseDetails([]);
        setLoaderResponse('');
      }
    }, [useCaseDetails]);
  
    return {
      copilotSteps,
      messages,
      inputValue,
      useCaseDetails,
      handleChange,
      handleSubmit,
      isLoading,
      loaderResponse,
    };
  };