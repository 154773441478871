

/*
export const handleKeyPressTag = (event, tags, setData) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        const newTag = event.target.innerText.trim();
        if (newTag) {
        event.target.innerText = '';
        setData(prevData => ({
            ...prevData,
            tags: [...tags, newTag],
          }));
        }
    }
};
*/

import { UserInformation } from "../auth_mgmt/auth_handling";

/*
export const removeTag = (index, tags, setData) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setData(prevData => ({
        ...prevData,
        tags: updatedTags,
      }));
};
*/

export const handleKeyPressTag = (event, tags, setData) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        const newTag = event.target.innerText.trim();
        if (newTag) {
            event.target.innerText = '';
            setData(prevData => ({
                ...prevData,
                tags: {
                    ...prevData.tags,
                    value: [...prevData.tags.value, newTag], // Add the new tag to the tags.value array
                    modified: true, // Mark as modified
                    updated_timestamp: new Date().toISOString(), // Set the updated timestamp
                    updated_by: UserInformation().displayName, // Record who updated it
                    uid: UserInformation().uid,
                    version: prevData.tags.version + 1 // Increment the version
                }
            }));
        }
    }
};

export const removeTag = (index, tags, setData) => {
    const updatedTags = [...tags.value];
    updatedTags.splice(index, 1);
    setData(prevData => ({
        ...prevData,
        tags: {
            ...prevData.tags,
            value: updatedTags, // Update the tags.value array
            modified: true, // Mark as modified
            updated_timestamp: new Date().toISOString(), // Set the updated timestamp
            updated_by: UserInformation().displayName, // Record who updated it
            uid: UserInformation().uid,
            version: prevData.tags.version + 1 // Increment the version
        }
    }));
};