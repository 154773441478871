/**
 * Ecosystem Statistics
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-26
 * 
 */

import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col } from 'react-bootstrap';

  const useCountUp = (start, end, duration) => {
    const [count, setCount] = useState(start);
  
    useEffect(() => {
      let startTimestamp = null;
      const step = timestamp => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        setCount(Math.floor(progress * (end - start) + start));
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }, [start, end, duration]);
  
    return count;
  };
  
  const useInView = (options) => {
    const [inView, setInView] = useState(false);
    const ref = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setInView(entry.isIntersecting);
      }, options);
  
      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, [options]);
  
    return [ref, inView];
  };
  
  const StatisticsCard = ({ targetCount, caption }) => {
    const [ref, inView] = useInView({ threshold: 0.5 });
    const count = useCountUp(0, inView ? targetCount : 0, 2000);
  
    return (
      <Col md={3}>
        <Card className="text-center h-100" style={{  padding: '20px' }} ref={ref}> {/* margin: '10px', */}
          <Card.Body>
            <Card.Title style={{ fontSize: '48px', fontWeight: 'bold' }}>
              {count}
            </Card.Title>
            <Card.Text style={{ fontSize: '14px' }}>
              {caption}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  };
  
  const StatisticsDashboard = ({ stats }) => (
    <>
      <Row className="justify-content-center">
        {stats.map((stat, index) => (
          <StatisticsCard key={index} targetCount={stat.count} caption={stat.caption} />
        ))}
      </Row>
    </>
  );

  // Compute statistics for Dashboard
  // Used in UCM and Eco Analytics Page
  const computeEcoStats = (useCases) => {

    // Standard template actors to exclude
    const standardActors = new Set([
      'Actors',
      'Consumer / Recipient',
      'Platform Provider',
      'Producer / Supplier',
      'Regulator',
      'Operator',
      'Technical Enabler'
    ]);

    // Extract unique actors excluding standard ones
    const uniqueActors = new Set();
    useCases.forEach(useCase => {
      // Old state structure
      if (useCase.actors.nodes) {
        useCase.actors.nodes.forEach(node => {
          if (!standardActors.has(node.label)) {
            uniqueActors.add(node.id);
          }
        });
      // New state structure
      } else if (useCase.actors.value.nodes.value) {
        useCase.actors.value.nodes.value.forEach(node => {
          if (!standardActors.has(node.label)) {
            uniqueActors.add(node.id);
          }
        });
      }
    });

    // Extract unique items from components
    const uniqueItems = new Set();
    useCases.forEach(useCase => {
      if (Array.isArray(useCase.components)) {
        // Old state structure
        useCase.components.forEach(category => {
          category.components.forEach(component => {
            component.items.forEach(item => uniqueItems.add(item));
          });
        });
      } else if (useCase.components && Array.isArray(useCase.components.value)) {
        // New state structure
        useCase.components.value.forEach(category => {
          category.components.forEach(component => {
            component.items.forEach(item => uniqueItems.add(item));
          });
        });
      }
    });

    // Extract unique scenarios
    const uniqueScenarios = new Set();
    useCases.forEach(useCase => {
      if (Array.isArray(useCase.actions)) {
        // Old state structure
        useCase.actions.forEach(action => {
          uniqueScenarios.add(action.name);
        });
      } else if (useCase.actions && Array.isArray(useCase.actions.value)) {
        // New state structure
        useCase.actions.value.forEach(action => {
          uniqueScenarios.add(action.name);
        });
      }
    });
    
    // Statistics
    const totalUseCases = useCases.length;
    const totalActors = uniqueActors.size; //useCases.reduce((acc, useCase) => acc + useCase.actors.nodes.length, 0);
    const totalComponents = uniqueItems.size; //useCases.reduce((acc, useCase) => acc + useCase.components.length, 0);
    const totalActions = uniqueScenarios.size; //useCases.reduce((acc, useCase) => acc + useCase.actions.length, 0);

    const stats = [
      { count: totalUseCases, caption: 'Use Cases Modeled' },
      { count: totalActions, caption: 'Described Scenarios' },
      { count: totalActors, caption: 'Identified Actors' },
      { count: totalComponents, caption: 'Modeled Components' },    
    ];

    return stats;
}

  export { StatisticsDashboard, StatisticsCard, computeEcoStats };