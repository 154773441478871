/**
 * Modal edit use case
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-09
 * 
 */

import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Papierboot from '../../assets/img/Papierboot.png';

const ModelModalEdit = ({ onClose, uc_name }) => {

    const navigate = useNavigate();
    const navigateRepository = () => {
        navigate('/Repository');
    };

    return (
        <Modal show={true} onHide={onClose} placement="end">
            <Modal.Header closeButton>
                <Modal.Title>Edit Use Case</Modal.Title> {/* Association Guide */}
            </Modal.Header>
            <Modal.Body>       
                <Row className="justify-content-center text-center">
                    <Col>
                        <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '50%', height: 'auto' }}/>        
                        <p><b>Edit the use case - {uc_name}.</b></p>
                        <p><b>Note:</b> the changes made will not take effect until you click Save in the model view (last step).</p>                      
                    </Col>
                </Row>                
            </Modal.Body>
            <Modal.Footer>                
                <Button variant="outline-secondary" onClick={navigateRepository}>
                    Back to Repository
                </Button>
                <Button variant="outline-primary" onClick={onClose}>
                    Edit Use Case
                </Button>
            </Modal.Footer>
        </Modal>
      );
};

export default ModelModalEdit;