/**
 * State to hold the interview data
 *
 * Description: Provides a state to hold the interview data.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 */

import { useState } from 'react';

import { questions } from '../study_interview/study_interview_qs';

/**
   * State to hold the interview data
   * The data object will be updated as the user progresses through the interview.
   * Data will be sent to the backend when the user completes the interview.
   */
export const useInterviewDataHook = () => {
    const [interviewData, setInterviewData] = useState({
        // no personal data is collected and stored 
        anonymous_id: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        mode: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        role: {
            value: 'user',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        consent: {
            value: false,
            source: 'study',
            modified: false,    
            anonymous_id: '',
        },
        consent_date: {
            value: '',
            source: 'study',
            modified: false,    
            anonymous_id: '',
        },
        place: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        experience: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        expert_field: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        yearOfBirth: {
            value: '',
            source: 'study',
            modified: false,            
            anonymous_id: '',
        },
        study_step: {
            value: 0,
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        study_step_start: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        study_step_end: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        study_step_duration: {
            value: '',
            source: 'study',
            modified: false,
            anonymous_id: '',
        },
        // Questions
        questions: questions,
    });
    return [interviewData, setInterviewData];
}