/**
 * Survey component
 *
 * Description: Provides a survey interface to collect user feedback.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-13
 */

import React, { useEffect } from 'react';

import { useState } from 'react';
import { Container, Row, Button, Form, ProgressBar } from 'react-bootstrap';

import { getCurrentDateTime } from '../components/utils/utils_date';
import { useSurveyDataHook } from '../components/study_handling/study_data_states/study_state_survey';

import { SurveyQSIntro } from '../components/study_handling/study_survey/survey_qs_intro';
import { SurveyQSValue } from '../components/study_handling/study_survey/survey_qs_value';
import { SurveyQSModel } from '../components/study_handling/study_survey/survey_qs_models';
//import { SurveyQSUI } from '../components/study_handling/study_survey/survey_qs_ui';
import { SurveyQSExperience } from '../components/study_handling/study_survey/survey_qs_experience';

import { saveSurveyData } from '../components/db_mgmt/db_handling_survey/db_survey_write';

function getTimeDifferenceInHHMMSS(dateString1, dateString2) {
    // Convert the date strings to Date objects
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    
    // Get the difference in time (milliseconds)
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    
    // Convert to seconds, minutes, and hours
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
    
    // Format the result as hh:mm:ss
    const formattedTime = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0'),
    ].join(':');

    return formattedTime;
}

// Function to render progress bar based on the current step
const renderProgressBar = ( isStepCompleted, total_question_sets ) => {

    const completedSteps = [...Array(total_question_sets).keys()]
        .filter(step => isStepCompleted(step + 1)) // Check if each step is complete
        .length;
    const progress = (completedSteps / total_question_sets) * 100;

    return (
        <ProgressBar
            now={progress}
            label={`${Math.floor(progress)}%`}
            style={{
            height: '20px',
            borderRadius: '5px',
            marginBottom: '20px',
            color: 'gray',
            textAlign: 'right',
            }}
            variant="success"
        />
    );
};  

export default function Survey( { studyExpertData, setShowModalStudy } ) {

    const [surveyData, setSurveyData] = useSurveyDataHook();

    const total_question_sets = 4;
    const [currentStep, setCurrentStep] = useState(1);

    // Call the function when `studyExpertData` changes
    useEffect(() => {

        const setGenericSurveyData = (data) => {
            setSurveyData((prev) => ({
                ...prev,
                anonymous_id: {
                    value: data?.anonymous_id || '',
                    anonymous_id: data?.anonymous_id || ''
                },
                mode: {
                    value: 'study',
                    anonymous_id: data?.anonymous_id || ''
                },
                role: {
                    value: data?.role || '',
                    anonymous_id: data?.anonymous_id || ''
                },
                consent: {
                    value: data?.consentGiven ?? false,
                    anonymous_id: data?.anonymous_id || ''
                },
                consent_date: {
                    value: getCurrentDateTime(),
                    anonymous_id: data?.anonymous_id || ''
                },
                place: {
                    value: data?.city || '',
                    anonymous_id: data?.anonymous_id || ''
                },
                experience: {
                    value: data?.experience || '',
                    anonymous_id: data?.anonymous_id || ''
                },
                expert_field: {
                    value: data?.expertField || '',
                    anonymous_id: data?.anonymous_id || ''
                },
                yearOfBirth: {
                    value: data?.yearOfBirth || '',
                    anonymous_id: data?.anonymous_id || ''
                },
                department: {
                    value: data?.department || '',
                    anonymous_id: data?.anonymous_id || ''
                }
            }));
        };

        if (studyExpertData) {
            setGenericSurveyData(studyExpertData);
        }
    }, [studyExpertData, setSurveyData]);

    // set study start timestamp
    useEffect(() => {
        surveyData.study_step_start = getCurrentDateTime();
    });

    // Update surveyData state when a field changes
    const handleChange = (e, aspect = null) => {

        const { name, value } = e.target;
    
        // Use a loop to traverse through surveyData to find the matching object by name
        for (let section in surveyData) {
            if (surveyData[section][name]) {
                // Handle the standard fields where name matches directly
                setSurveyData((prev) => ({
                    ...prev,
                    [section]: {
                        ...prev[section],
                        [name]: {
                            ...prev[section][name],
                            answer: value, // Update the answer field
                            anonymous_id: studyExpertData?.anonymous_id ?? '',
                            created_timestamp: getCurrentDateTime(),
                        },
                    },
                }));
                break; // Exit the loop once the match is found
            } 
            // Handle the nested structure for ui_overall_aspects or other similar cases
            else if (surveyData[section].ui_overall_aspects && aspect) {
                setSurveyData((prev) => ({
                    ...prev,
                    [section]: {
                        ...prev[section],
                        ui_overall_aspects: {
                            ...prev[section].ui_overall_aspects,
                            answer: {
                                ...prev[section].ui_overall_aspects.answer,
                                [aspect]: value, // Update specific aspect in the grid
                            },
                            anonymous_id: studyExpertData?.anonymous_id ?? '',
                            created_timestamp: getCurrentDateTime(),
                        }
                    },
                }));
                break; // Exit the loop once the match is found
            }
        }
    
        // Update progress immediately when all fields for a step are filled
        if (isStepCompleted(currentStep)) {
            renderProgressBar(isStepCompleted, total_question_sets);
        }
    };

    // Handle multi-select (checkbox inputs)
    const handleMultiSelectChange = (e) => {
        const { value } = e.target;

        setSurveyData((prevSurveyData) => {
            // Access the current array for model_agreed_aspects from modelUsage
            const currentSelection = prevSurveyData.modelUsage.model_agreed_aspects.answer || [];

            // Check if the clicked value already exists in the current selection
            let updatedSelection;
            if (currentSelection.includes(value)) {
                // If it exists, remove it (unchecking the checkbox)
                updatedSelection = currentSelection.filter((item) => item !== value);
            } else {
                // If it doesn't exist, add it (checking the checkbox)
                updatedSelection = [...currentSelection, value];
            }

            // Update the surveyData with the new selection
            return {
                ...prevSurveyData,
                modelUsage: {
                    ...prevSurveyData.modelUsage,
                    model_agreed_aspects: {
                        ...prevSurveyData.modelUsage.model_agreed_aspects,
                        answer: updatedSelection, // Update the answer field with the new selection
                        anonymous_id: studyExpertData?.anonymous_id ?? '',
                        created_timestamp: getCurrentDateTime(),
                    }
                }
            };
        });
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    // Save the survey data to the database
    const handleSubmit = () => {

        //console.log('study expert data', studyExpertData);
        
        try {
            // Set the study step to 4 (survey completed)
            setSurveyData((prev) => ({
                ...prev,
                study_step: {
                    value: '4',
                    anonymous_id: prev.anonymous_id.value
                },
                study_step_end: getCurrentDateTime(),
                study_step_duration: getTimeDifferenceInHHMMSS(prev.study_step_end, prev.study_step_start)
            }));

            //console.log('Survey data:', surveyData);
            saveSurveyData(surveyData);

        } catch (error) {
            console.error('Error saving survey data:', error);
        }

        // triggers the next study step (interview)
        setShowModalStudy(true);
    };

    // Function to check if all fields for the current step are filled
    /*
    const isStepCompleted = (step) => {
        switch (step) {
        case 1:
            return responses.feeling.expert_feeling !== '' 
                && responses.feeling.expert_work_level !== '' 
                && responses.feeling.expert_work_layer !== '' 
                && responses.feeling.expert_confidence > 0;
        case 2:
            return responses.addedValue.ucm_usefullness >= 0
                //&& responses.addedValue.ucm_novelty !== ''
                && responses.addedValue.ucm_effectiveness !== ''
                && responses.addedValue.ucm_most_useful_aspects.length > 0
                && responses.addedValue.ucm_method_clarity >= 0
                //&& Object.keys(responses.addedValue.ucm_ranked_aspects).length === 4;
        case 3:
            return responses.modelUsage.model_input > 0
                && responses.modelUsage.model_llm_integration !== ''
                && responses.modelUsage.model_semantic_clarity !== ''
                && responses.modelUsage.model_agreed_aspects.length > 0;
        case 4:
            return responses.uiDesign.ui_clarity > 0
                && responses.uiDesign.ui_simplicity !== ''
                && responses.uiDesign.ui_overall_aspects.clarity !== ''
                && responses.uiDesign.ui_overall_aspects.ease_of_use !== ''
                && responses.uiDesign.ui_overall_aspects.effectiveness !== ''
                && responses.uiDesign.ui_overall_aspects.usefulness !== '';
        case 5:
            return responses.experience.reproducibility !== ''
                && responses.experience.overall_experience !== ''
                && responses.experience.additional_comments !== '';            
        default:
            return false;
        }
    };
    */
      
    // Function to check if all fields for the current step are filled
    const isStepCompleted = (step) => {

        switch (step) {
            case 1:
                return surveyData.feeling.expert_feeling.answer !== '' 
                    && surveyData.feeling.expert_work_level.answer !== '' 
                    && surveyData.feeling.expert_work_layer.answer !== '' 
                    && surveyData.feeling.expert_confidence.answer >= 0;
            case 2:
                return surveyData.addedValue.ucm_usefullness.answer >= 0
                    && surveyData.addedValue.ucm_effectiveness.answer !== ''
                    && surveyData.addedValue.ucm_most_useful_aspects.answer.length > 0
                    && surveyData.addedValue.ucm_method_clarity.answer >= 0                    
                    && surveyData.addedValue.ucm_additional_comments.answer !== '';
            case 3:
                return surveyData.modelUsage.model_input.answer > 0
                    && surveyData.modelUsage.model_llm_integration.answer !== ''
                    && surveyData.modelUsage.model_semantic_clarity.answer !== ''
                    && surveyData.modelUsage.model_agreed_aspects.answer.length > 0
                    && surveyData.modelUsage.model_additional_comments.answer !== '';
            /*
            case 4:
                return surveyData.uiDesign.ui_clarity.answer > 0
                    && surveyData.uiDesign.ui_simplicity.answer !== ''
            */
            case 4:
                return surveyData.userExperience.reproducibility.answer !== ''
                    && surveyData.userExperience.overall_experience.answer !== ''
                    && surveyData.userExperience.additional_comments.answer !== '';            
            default:
                return false;
        }
    };

    return (
        <Container fluid>
            {/* Survey Introduction */}
            <Row align="center">
                <h1 style={{ marginTop: '50px', marginBottom: '30px' }}>
                <b>Survey</b>
                </h1>
                {/*
                <p style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '10px' }}>
                Please take about 5 minutes to complete this survey. Your feedback is essential for classifying and improving the use case modeling approach.
                </p>
                */}
            </Row>

            {/* Page indicator (e.g. 1/5) */}
            <Row align="center" style={{ marginTop: '30px' }}>
                <div>
                    <span>
                        <b>Question Set {currentStep} / {total_question_sets}</b>
                    </span>
                    {/* Navigation Buttons
                    {currentStep > 1 && (
                        <Button
                            className="btn btn-sm"
                            variant="secondary"
                            style={{ marginRight: '5px', marginLeft: '10px' }}
                            onClick={handlePrevious}
                        >
                        Previous
                        </Button>
                    )}
                    {currentStep < total_question_sets && (
                        <Button
                            className='btn btn-sm'
                            variant="primary"
                            //disabled={!isStepCompleted(currentStep)}
                            style={{ marginRight: '5px', marginLeft: '10px' }}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    )}
                    {currentStep === total_question_sets && (
                        <Button
                            className='btn btn-sm'
                            variant="success"
                            style={{ marginRight: '5px', marginLeft: '10px' }}
                            onClick={handleSubmit}
                            disabled={!isStepCompleted(currentStep)}
                        >
                        Submit
                        </Button>
                    )}
                    */}
                </div>
            </Row>

            {/* Render Progress Bar */}
            <Row align="center" style={{ maxWidth: '500px', margin: '0 auto', marginTop: '20px' }}>
                {renderProgressBar(isStepCompleted, total_question_sets)}
            </Row>

            <Form>
                {/* (1) Question Set Intro */}
                <SurveyQSIntro 
                    surveyData={surveyData} 
                    setSurveyData={setSurveyData} 
                    handleChange={handleChange} 
                    currentStep={currentStep} 
                    studyExpertData={studyExpertData}
                />

                {/* (2) Question Set Added Value of the tool */}
                <SurveyQSValue
                    surveyData={surveyData}
                    setSurveyData={setSurveyData}
                    handleChange={handleChange}
                    currentStep={currentStep}
                    studyExpertData={studyExpertData}
                />

                {/* (3) Question Set Models */}
                <SurveyQSModel
                    surveyData={surveyData}
                    setSurveyData={setSurveyData}
                    handleChange={handleChange}
                    handleMultiSelectChange={handleMultiSelectChange}
                    currentStep={currentStep}
                    studyExpertData={studyExpertData}
                />

                {/* (4) Question Set UI 
                <SurveyQSUI
                    surveyData={surveyData}
                    setSurveyData={setSurveyData}
                    handleChange={handleChange}
                    currentStep={currentStep}
                    studyExpertData={studyExpertData}
                />
                */}

                {/* (5) Question Set Experience */}
                <SurveyQSExperience
                    surveyData={surveyData}
                    //setResponses={setResponses}
                    handleChange={handleChange}
                    currentStep={currentStep}
                />

                {/* Navigation Buttons */}
                <Row align="center" style={{ marginTop: '30px' }}>
                    <div>
                        {currentStep > 1 && (
                            <Button
                            variant="secondary"
                            style={{ marginRight: '10px' }}
                            onClick={handlePrevious}
                            >
                            Previous
                            </Button>
                        )}
                        {currentStep < total_question_sets && (
                            <Button
                            variant="primary"
                            style={{ marginRight: '10px' }}
                            disabled={!isStepCompleted(currentStep)}
                            onClick={handleNext}
                            >
                            Next
                            </Button>
                        )}
                        {currentStep === total_question_sets && (
                            <Button
                            variant="success"
                            style={{ marginRight: '10px' }}
                            onClick={handleSubmit}
                            disabled={!isStepCompleted(currentStep)}
                            >
                                Submit
                            </Button>
                        )}
                    </div>
                </Row>

            </Form>
        </Container>
    );
}

export { default as Survey } from './Survey';