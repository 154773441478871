/**
 * 
 * @returns Toast component
 */

import { Toast, Button } from 'react-bootstrap';
import { getCurrentTime } from '../utils/utils_date';

export const ElemToast = (showToast, toggleToast, label) => {    

    return (
        <div
            style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                zIndex: 9999, // Ensure it's above other elements
                }}>
            <Toast show={showToast} onClose={toggleToast} className="bg-white">
                <Toast.Header>
                <strong className="me-auto">Participant Consent Form</strong>
                <small>{getCurrentTime()}</small>
                </Toast.Header>
                <Toast.Body>
                    {label}                    
                    <div className="d-flex justify-content-end">
                        <Button onClick={toggleToast} variant="outline-primary" style={{ margin: '10px' }}>
                            Noted
                        </Button>
                    </div>
                </Toast.Body>                
            </Toast>
        </div>
        );
}

export const ElemToastCopied = ({ showToast, onClose, label, delay, autohide }) => {
    return (
      <div
        style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          zIndex: 9999, // Ensure it's above other elements
        }}>
        <Toast show={showToast} onClose={onClose} delay={delay} autohide={autohide} className="bg-white">
          <Toast.Header>
            <strong className="me-auto">{label}</strong>
            <small>{new Date().toLocaleTimeString()}</small>
          </Toast.Header>
          <Toast.Body>
            {label}
            <div className="d-flex justify-content-end">
              <Button onClick={onClose} variant="outline-primary" style={{ margin: '10px' }}>
                Noted
              </Button>
            </div>
          </Toast.Body>
        </Toast>
      </div>
    );
};