/**
 * Survey: Question Set (QS) LLMs and Semantic Models
 *
 * Description: The QS Models focuses on the input knowledge support in the tool, specifically in the component diagram
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-13 (updated on Oct 15)
 */

import React from 'react';
import { Row, Form } from 'react-bootstrap';

import { getCurrentDateTime } from '../../utils/utils_date';

export const SurveyQSModel = ( { surveyData, setSurveyData, handleMultiSelectChange, currentStep, studyExpertData } ) => {

    return (
        <>
            {/* Question 1: Understanding of the Approach */}
            {currentStep === 3 && (
                <Row align="center">
                    <h3 style={{ marginTop: '30px', marginBottom: '20px' }}>Evaluation of the LLM and Semantic Model Integration</h3>
                    <p style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
                        How was the LLM and semantic model support throughout the use case modeling process?
                        Did this additional layer helped in the creation process?
                    </p>

                    {/* Star Rating for LLM + semantic model support */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.modelUsage.model_input.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <span key={star} style={{ fontSize: '2rem', cursor: 'pointer', color: surveyData.modelUsage.model_input.answer >= star ? '#ffc107' : '#e4e5e9' }} 
                                    onClick={() => setSurveyData((prev) => ({
                                        ...prev,
                                        modelUsage: {
                                            ...prev.modelUsage,
                                            model_input: {
                                                ...prev.modelUsage.model_input,
                                                answer: star,  // Assuming 'star' is the new value to be set
                                                anonymous_id: studyExpertData?.anonymous_id ?? '',
                                                created_timestamp: getCurrentDateTime(),
                                            }
                                        }
                                    }))}
                                >★</span>
                            ))}
                        </div>
                    </Form.Group>

                    {/* LLM Integration */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.modelUsage.model_llm_integration.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '700px', margin: '0 auto', marginTop:'30px' }}>
                            <Form.Check 
                                type="radio" 
                                label="Not useful" 
                                name="model_llm_integration" 
                                value="1" 
                                onChange={(e) => setSurveyData((prev) => ({
                                        ...prev,
                                        modelUsage: {
                                            ...prev.modelUsage,
                                            model_llm_integration: {
                                                ...prev.modelUsage.model_llm_integration,
                                                answer: e.target.value,
                                                anonymous_id: studyExpertData?.anonymous_id ?? '',
                                                reated_timestamp: getCurrentDateTime(),
                                            }
                                        }
                                }))}
                                checked={surveyData.modelUsage.model_llm_integration.answer === '1'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Neglectable" 
                                name="model_llm_integration" 
                                value="2" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_llm_integration: {
                                            ...prev.modelUsage.model_llm_integration,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_llm_integration.answer === '2'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Somewhat useful" 
                                name="model_llm_integration" 
                                value="3" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_llm_integration: {
                                            ...prev.modelUsage.model_llm_integration,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_llm_integration.answer === '3'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Useful" 
                                name="model_llm_integration" 
                                value="4" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_llm_integration: {
                                            ...prev.modelUsage.model_llm_integration,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_llm_integration.answer === '4'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Very useful" 
                                name="model_llm_integration" 
                                value="5" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_llm_integration: {
                                            ...prev.modelUsage.model_llm_integration,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_llm_integration.answer === '5'} 
                            />
                        </div>
                    </Form.Group>

                    {/* Semantic Model Clarity */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.modelUsage.model_semantic_clarity.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '700px', margin: '0 auto', marginTop:'30px' }}>
                            <Form.Check 
                                type="radio" 
                                label="Not clear" 
                                name="model_semantic_clarity" 
                                value="1" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_semantic_clarity: {
                                            ...prev.modelUsage.model_semantic_clarity,
                                            answer: e.target.value,
                                            anonymous_id: studyExpertData?.anonymous_id ?? '',
                                            created_timestamp: getCurrentDateTime(),
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_semantic_clarity.answer === '1'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Neglectable" 
                                name="model_semantic_clarity" 
                                value="2" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_semantic_clarity: {
                                            ...prev.modelUsage.model_semantic_clarity,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_semantic_clarity.answer === '2'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Somewhat clear" 
                                name="model_semantic_clarity" 
                                value="3" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_semantic_clarity: {
                                            ...prev.modelUsage.model_semantic_clarity,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_semantic_clarity.answer === '3'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Clear" 
                                name="model_semantic_clarity" 
                                value="4" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_semantic_clarity: {
                                            ...prev.modelUsage.model_semantic_clarity,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_semantic_clarity.answer === '4'} 
                            />
                            <Form.Check 
                                type="radio" 
                                label="Very clear" 
                                name="model_semantic_clarity" 
                                value="5" 
                                onChange={(e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_semantic_clarity: {
                                            ...prev.modelUsage.model_semantic_clarity,
                                            answer: e.target.value,
                                        }
                                    }
                                }))}
                                checked={surveyData.modelUsage.model_semantic_clarity.answer === '5'} 
                            />
                        </div>
                    </Form.Group>

                    {/* Main relevant aspects */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.modelUsage.model_agreed_aspects.question}</b></Form.Label>
                        <div style={{ margin: '0 auto', marginTop:'30px', maxWidth:'400px' }}>
                        <Form.Check
                            type="checkbox"
                            label="Relevance to the domain"
                            name="inputQuality"
                            value="Relevance to the domain"
                            checked={surveyData.modelUsage.model_agreed_aspects.answer.includes('Relevance to the domain')}
                            onChange={handleMultiSelectChange}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Clarity of the inputs"
                            name="inputQuality"
                            value="Clarity of the inputs"
                            checked={surveyData.modelUsage.model_agreed_aspects.answer.includes('Clarity of the inputs')}
                            onChange={handleMultiSelectChange}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Simplicity of the suggested elements"
                            name="inputQuality"
                            value="Simplicity of the suggested elements"
                            checked={surveyData.modelUsage.model_agreed_aspects.answer.includes('Simplicity of the suggested elements')}
                            onChange={handleMultiSelectChange}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Completeness of information"
                            name="inputQuality"
                            value="Completeness of information"
                            checked={surveyData.modelUsage.model_agreed_aspects.answer.includes('Completeness of information')}
                            onChange={handleMultiSelectChange}
                        />
                        </div>
                    </Form.Group>

                    {/* Additional Comments */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.modelUsage.model_additional_comments.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', maxWidth: '500px' }}>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            name="additional_comments"
                            value={surveyData.modelUsage.model_additional_comments.answer}
                            onChange={
                                (e) => setSurveyData((prev) => ({
                                    ...prev,
                                    modelUsage: {
                                        ...prev.modelUsage,
                                        model_additional_comments: {
                                            ...prev.modelUsage.model_additional_comments,
                                            answer: e.target.value,
                                        }
                                    }
                                }))
                            }
                            placeholder="Your feedback here..."
                        />
                        </div>
                    </Form.Group>

                </Row>
            )}
        </>
    );
}