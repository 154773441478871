/**
 * Describes the procedure for the user study
 *
 * Description: Provides the elements
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-15 (last updated 2024-10-02)
 * Notes: /
 */

import React, { useState } from 'react';

import { Button, Container, Row, Card, Col, Alert, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

import { UserInformation } from '../components/auth_mgmt/auth_handling';

import { Tasks } from '../components/study_handling/study_tasks';

export default function Instructions(props) {

    const [startStudy, setStartStudy] = useState(false);

    const location = useLocation();
    const { expertData } = location.state || {};

    //console.log(expertData);

    const user = UserInformation();

    const navigate = useNavigate();
    // creator, cockpit

    const handleStartStudy = () => {
        setStartStudy(!startStudy);
    };

    const navigateCreate = () => {
      navigate('/Create', { state: { mode: 'copilot', expertData } });
    };

    return (
        <Container fluid>
            {/* Instructions */}
            <Row align='center'>      
                <h1 style={ { marginTop: '50px', marginBottom: '30px' } }><b>Welcome to the Expert Study 'AM Use Case Modeling'</b></h1>
                <p style={ { maxWidth: '700px', margin: '0 auto', marginBottom: '10px' } }>
                  Look up the few hints below and you are good to go.
                </p>
            </Row>
            
            {/* Outline and Scope */}
            {!startStudy &&
            <Row align='center'>
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>
                    Study Outline and Tasks
                </h3>
                <div style={ { maxWidth: '800px', margin: '0 auto' } }>
                    <Tasks handleStartStudy={handleStartStudy} />                                   
                </div>  
            </Row>
            }

            {/* Start Study */}
            {startStudy &&
              <div style={ { Width:'500px' } }>
              <Row align='center' className="justify-content-center mb-4">
                <h3 style={{marginTop: '30px', marginBottom: '30px'}}>Let's go !</h3>
                <Col md={8}>
                  <Card className="shadow-lg p-4">

                    {/* Display user data and anonymization reassurance */}
                    <div className="text-center mb-4">
                      <Alert variant="info" style={{ fontSize: '1rem', maxWidth: '700px', margin: '0 auto' }}>
                        <i className="bi bi-shield-lock" style={{ marginRight: '8px' }}></i>
                        Please note that your identifying information will be completely <strong>anonymized</strong>. However, certain information (such as role, expertise, and birth year) will be used for analysis but will not be linked to your identity.
                      </Alert>
                    </div>

                    {/* Display user data and anonymization */}
                    <Table bordered hover responsive className="mb-4" style={{ maxWidth: '700px', margin: '0 auto' }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>Plain Data (not used)</th>
                          <th style={{ textAlign: 'center' }}>Data used for Analysis</th>
                          <th style={{ width:'30%', textAlign: 'center' }}>Anonymized Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ padding: '20px' }}>
                            <p><strong>Name:</strong> {expertData.participantName}</p>
                            <p><strong>Email:</strong> {user.email}</p>
                            <p><strong>Consent Given:</strong> {expertData.consentGiven ? 'Yes' : 'No'}</p>
                          </td>
                          <td style={{ padding: '20px' }}>
                            <p><strong>Year of Birth:</strong> {expertData.yearOfBirth}</p>
                            <p><strong>Role:</strong> {expertData.role}</p>
                            <p><strong>Country:</strong> {expertData.country}</p>
                            <p><strong>City:</strong> {expertData.city}</p>
                            <p><strong>Field of Expertise:</strong> {expertData.expertField}</p>
                            <p><strong>Years of Experience:</strong> {expertData.experience}</p>
                          </td>
                          <td style={{ padding: '20px', textAlign: 'center' }}>
                            <div className="anonymized-code-box p-1 bg-light border rounded" style={{ fontSize: '1.2rem' }}>
                              <span style={ { fontSize:'8px', margin:'0px', padding:'0px' } }>Participant Code</span>
                              <br />
                              <strong>{expertData.anonymous_id}</strong>
                            </div>

                            <div className="anonymized-code-box p-1 bg-light border rounded" style={{ fontSize: '1.2rem', marginTop:'15px' }}>
                              <span style={ { fontSize:'8px', margin:'0px', padding:'0px' } }>Date</span>
                              <br />
                              <strong>{expertData.date}</strong>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {/* Buttons for Starting Study */}
                    <div className="d-flex justify-content-center">
                      <Button variant="outline-secondary" style={{ marginRight: '5px' }} onClick={() => setStartStudy(false)}>
                        <i className="bi bi-arrow-left-circle"></i>
                      </Button>

                      <OverlayTrigger placement="top" overlay={<Tooltip>Start expert session.</Tooltip>}>
                        <Button variant="success" style={{ marginLeft: '10px' }} onClick={navigateCreate}>
                            <i className="bi bi-rocket" style={{ marginRight: '8px' }}></i>
                            Start Study
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Card>
                </Col>
              </Row>
              </div>
            }
        </Container>
    );
};

export { default as Instructions } from './Instructions';