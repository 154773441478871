/**
 * Render concepts for the sidebar based on the provided knowledge representation
 * 
 * Description: Serves as a selection of concepts to be used in the use case model
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-26
 * 
 */

import { Badge } from 'react-bootstrap';
import { useDrag } from 'react-dnd';
import { toggleCollapse } from '../onto_tree_handling/render_concepts';
import { DraggableItem } from './render_items';

// Recursive function to render concepts as badges and their immediate children
const renderConceptBadges = (isKnowledgeSite, concepts, sidebarItems, setSidebarItems, setSearchTerm) => {

    return concepts.map((concept, index) => (
        <div className="text-start" key={index}>
            <span
                style={{ cursor: 'pointer', marginTop: '10px', display: 'block' }}
                variant="outline-primary"
                className="text-start"
                onClick={() => {
                    toggleCollapse(concept, sidebarItems, setSidebarItems);
                    if (isKnowledgeSite) {
                        setSearchTerm(concept.title);
                    }
                }}
            >
                {/* Indicator for collapsed or expanded */}
                
                {concept.subconcepts && concept.subconcepts.length > 0 ? (concept.collapsed ? '▶' : '▼') : ''}
                <DraggableItem item={concept} useDrag={useDrag} Badge={Badge} />
            </span>
            {!concept.collapsed && concept.subconcepts && concept.subconcepts.length > 0 && (
                <ul className="list-unstyled" style={{ marginLeft: '20px' }}>
                    {renderConceptBadges(isKnowledgeSite, concept.subconcepts, sidebarItems, setSidebarItems, setSearchTerm)}
                </ul>
            )}
        </div>
    ));
};

export { renderConceptBadges };