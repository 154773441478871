/**
 * Ecosystem Analytics Adoption
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-21
 * 
 */

import { Bar } from 'react-chartjs-2';

/**
 * Aggregate the adoption and modification stats across all modeled use cases to give an overall trend.
 * @param {*} param0 
 * @returns 
 */
export const OverallUseCaseInteractionChart = ({ allUseCases }) => {
    const fields = [
        'title', 
        'acronym', 
        'maturity', 
        'application', 
        'status',
        'tags',
        'description', 
        'actions',
        'conditions',
        'actors',
        'components',
    ];

    let totalAdopted = 0;
    let totalModified = 0;

    allUseCases.forEach(useCaseData => {
        const firstHistory = useCaseData.history?.data?.[0] || {};
        const latestHistory = useCaseData.history?.data?.slice(-1)[0] || {};

        fields.forEach(field => {
            const firstSource = firstHistory[field]?.source;
            const latestSource = latestHistory[field]?.source;

            if (firstSource === 'ucm_copilot' && latestSource === 'ucm_copilot') {
                totalAdopted++;
            } else if (firstSource === 'ucm_copilot' && (latestSource === 'manually' || latestSource === 'manual')) {
                totalModified++;
            }
        });
    });

    const data = {
      labels: ['Adopted from UCM Copilot', 'Manually Modified'],
      datasets: [
        {
          data: [totalAdopted, totalModified],
          backgroundColor: ['#36A2EB', '#FF6384'],
        },
      ],
    };

    return <Bar data={data} />;
};