/**
 * Meta structure
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: (updated on 2024-10-09)
 * 
 */

import { useState } from 'react';

export const useCompLabels = () => {
    const [compArchLabels, setCompArchLabels] = useState({
    // Operations
    'Group_Operations': 'Operations: Systems, Services, and Tools',
    'Group_Operations_Management': 'Operations Management',
    'Group_Operations_Analytics': 'Operations Analytics',
    'Group_Operations_Platform': 'Operations Platform',
    // Producer
    'Group_Producer': 'Producer',
    'Group_Producer_Analytics': 'Producer Analytics',
    'Group_Producer_Management': 'Producer Management',
    'Group_Producer_Platform': 'Producer Platform',
    // Consumer
    'Group_Consumer': 'Consumer',
    'Group_Consumer_Analytics': 'Consumer Analytics',
    'Group_Consumer_Delivery': 'Consumer Delivery',
    'Group_Consumer_Platform': 'Consumer Platform',
    // Technical
    'Group_Technical': 'Technical Layer',
    // - initialized as per semantic model
    // Regulation
    'Group_Regulator': 'Regulator',
    'Group_Directives': 'Directives',
    'Group_Reference_Cases': 'Reference Cases',
    'Group_Standards': 'Standards',
    });

    return [compArchLabels, setCompArchLabels];
};

// Custom hook to manage droppedItems state
export const useDroppedItems = () => {
    const [droppedItems, setDroppedItems] = useState({
        // Operations
        'Operations: Systems, Services, and Tools': [],
        'Operations Management': [],
        'Operations Analytics': [],
        'Operations Platform': [],
        // Producer
        'Producer': [],
        'Producer Analytics': [],
        'Producer Management': [],
        'Producer Platform': [],
        // Consumer
        'Consumer': [],
        'Consumer Analytics': [],
        'Consumer Delivery': [],
        'Consumer Platform': [],
        // Technical layer
        'Applications / Interfaces (proprietary, third-party, partially distributed)': [],
        'Platform and Cloud (proprietary, third-party, partially distributed)': [],
        'ADS: Automated Driving System': [],
        'Hardware (Vehicle, Infrastructure)': [],
        'AI-Middleware and Edge (Devices)': [],
        // Regulator
        'Directives': [],
        'Reference Cases': [],
        'Standards': [],
    });

    return [droppedItems, setDroppedItems];
};