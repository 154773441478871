/**
 * Repository.jsx
 *
 * Description: Provides all modeled and stored use cases in the repository.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-09
 * 
 */

import React, { useState } from 'react';

import { Container } from 'react-bootstrap';

import RepositoryView from '../components/repository_handling/uc_repository';

export default function Repository(props) {

  const [showUserCases, setShowUserCases] = useState(false);

  return (
      <div align='center'>
        <Container fluid>
          <h4>Use Case Repository</h4>
          <RepositoryView showUserCases={showUserCases} setShowUserCases={setShowUserCases} />
        </Container>
      </div>
    );
};

export { default as Repository } from './Repository';