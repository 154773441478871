import React, { useState } from 'react';
import { Row, Col, Button, Form, OverlayTrigger, Tooltip, Accordion } from 'react-bootstrap';

import { getCurrentDate } from '../utils/utils_date';
import { UserInformation } from '../auth_mgmt/auth_handling';

import Papierboot from '../../assets/img/Papierboot.png';

const Action = ({ data, setData, useCaseDetails }) => {

  // State to manage scenario inputs
  const [scenarios, setScenarios] = useState(data.actions.value || []);
  // State to manage condition inputs
  const [conditions, setConditions] = useState(data.conditions.value || []);

  // Update the data object with the use case details
  /*
  useEffect(() => {
    
    if (!useCaseDetails || useCaseDetails.length === 0) return;

    // new scenarios based on UCM input
    const newScenarios = [
      {
        name: extractScenario(useCaseDetails[7]?.response || '')[0],
        description: extractScenario(useCaseDetails[7]?.response || '')[1]
      },
      {
        name: extractScenario(useCaseDetails[8]?.response || '')[0],
        description: extractScenario(useCaseDetails[8]?.response || '')[1]
      },
      {
        name: extractScenario(useCaseDetails[9]?.response || '')[0],
        description: extractScenario(useCaseDetails[9]?.response || '')[1]
      }
    ];

    // new conditions based on UCM input
    const newConditions = [
      {
        scenario: "Basic Scenario",
        preConditions: extractCondition(useCaseDetails[10]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[11]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[12]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[13]?.response || '')[1]
      },
      {
        scenario: "Advanced Scenario",
        preConditions: extractCondition(useCaseDetails[14]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[15]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[16]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[17]?.response || '')[1]
      },
      {
        scenario: "Cutting-edge Scenario",
        preConditions: extractCondition(useCaseDetails[18]?.response || '')[1],
        postConditions: extractCondition(useCaseDetails[19]?.response || '')[1],
        constraints: extractCondition(useCaseDetails[20]?.response || '')[1],
        assumptions: extractCondition(useCaseDetails[21]?.response || '')[1]
      }
    ];

    setScenarios(newScenarios);
    setConditions(newConditions);

    setData(prevData => ({
      ...prevData,
      actions: newScenarios,
      conditions: newConditions,
    }));
  
  }, [useCaseDetails, setData, isPrompt, setIsPrompt]);
  */

  // Function to handle adding a new scenario
  const handleAddScenario = () => {
    let scenarioName = '';
    let nameExists = true;

    const isNameExists = (name) => {
      return scenarios.some(scenario => scenario.name === name);
    };

    // Keep prompting until a unique name is provided or the user cancels
    while (nameExists) {
      scenarioName = window.prompt('Enter the name of the new scenario:', 'Manually created');
      if (!scenarioName) return;

      // Check if the name already exists
      nameExists = isNameExists(scenarioName);

      if (nameExists) {
        alert('A scenario with this name already exists. Please enter a different name.');
      }
    }

    const newScenarios = [
      ...scenarios,
      { name: scenarioName, description: '' }
    ];
    setScenarios(newScenarios);
  
    const newConditions = [
      ...conditions,
      { preConditions: '', postConditions: '', constraints: '', assumptions: '' }
    ];
    setConditions(newConditions);
  
    // Update data object with new scenarios and conditions
    /*
    setData(prevData => ({
      ...prevData,
      actions: newScenarios,
      conditions: newConditions,
    }));
    */

    // Update data object with new scenarios in the tracking object
    setData(prevData => ({
      ...prevData,
      actions: {
        ...prevData.description,
        value: newScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.description.version + 1 // Increment the version
      },
      conditions: {
        ...prevData.conditions,
        value: newConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };

  // Function to handle updating scenario description
  const handleScenarioChange = (index, description) => {
    const updatedScenarios = scenarios.map((scenario, i) =>
      i === index ? { ...scenario, description } : scenario
    );
    setScenarios(updatedScenarios);
  
    // Update data object with updated scenarios
    /*
    setData(prevData => ({
      ...prevData,
      actions: updatedScenarios,
    }));
    */

    setData(prevData => ({
      ...prevData,
      actions: {
        ...prevData.description,
        value: updatedScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.description.version + 1 // Increment the version
      }
    }));
  };

  // Function to remove a scenario
  const removeScenario = (index) => {
    const updatedScenarios = [...scenarios];
    updatedScenarios.splice(index, 1);
    setScenarios(updatedScenarios);

    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);    
  
    // Update data object after removing a scenario
    /*
    setData(prevData => ({
      ...prevData,
      actions: updatedScenarios,
    }));
    */
    setData(prevData => ({
      ...prevData,
      actions: {
        ...prevData.description,
        value: updatedScenarios, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.description.version + 1 // Increment the version
      },
    }));
  };

  const addCondition = (scenarioIndex) => {
    const newConditions = [...conditions];
    if (!newConditions[scenarioIndex]) {
      newConditions[scenarioIndex] = { preConditions: '', postConditions: '', constraints: '', assumptions: '' };
    }
  
    const newKey = `newCondition${Object.keys(newConditions[scenarioIndex]).length}`;
    newConditions[scenarioIndex][newKey] = '';
  
    setConditions(newConditions);
  
    // Update data object with new conditions
    /*
    setData(prevData => ({
      ...prevData,
      conditions: newConditions,
    }));
    */
    setData(prevData => ({
      ...prevData,
      conditions: {
        ...prevData.conditions,
        value: newConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };
  
  // Function to remove a condition
  const removeCondition = (scenarioIndex, key) => {
    const updatedConditions = [...conditions];
    delete updatedConditions[scenarioIndex][key];
    setConditions(updatedConditions);
  
    // Update data object after removing a condition
    /*
    setData(prevData => ({
      ...prevData,
      conditions: updatedConditions,
    }));
    */
    setData(prevData => ({
      ...prevData,
      conditions: {
        ...prevData.conditions,
        value: updatedConditions, // Update the value inside the corresponding object
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };

  // Function to handle updating condition type, description, and actor
  const handleConditionChange = (scenarioIndex, conditionKey, value) => {

    //console.log('scenarioIndex:', scenarioIndex);
    //console.log('conditionKey:', conditionKey);
    //console.log('value:', value);

    setConditions(prevConditions => {
      const newConditions = [...prevConditions];
      newConditions[scenarioIndex][conditionKey] = value;
      return newConditions;
    });
  
    // Update data object with updated conditions
    /*
    setData(prevData => ({
      ...prevData,
      conditions: prevData.conditions.map((scenario, i) =>
        i === scenarioIndex ? { ...scenario, [conditionKey]: value } : scenario
      ),
    }));
    */
    setData(prevData => ({
      ...prevData,
      conditions: {
        ...prevData.conditions,
        value: prevData.conditions.value.map((scenario, i) =>
          i === scenarioIndex ? { ...scenario, [conditionKey]: value } : scenario
        ),
        source: 'manual', // Record the source of the data
        modified: true, // Mark as modified
        updated_timestamp: getCurrentDate(), // Set the updated timestamp
        updated_by: UserInformation().displayName, // Record who updated it (assuming you have a CurrentUser function that returns the current user's name or ID)
        uid: UserInformation().uid, // Record the user ID
        version: prevData.conditions.version + 1 // Increment the version
      }
    }));
  };

  return (
    <div>
      <Row>
        {/* Headline and Toolbar */}
        <Col md={3}>
            <div>
                <h3 className="text-start">Define Scenarios</h3>
                <h6 className="text-start">Look up, adjust or add manually</h6>
            </div>
        </Col>
        {/* Toolbar */}
        <Col md={9} className="d-flex justify-content-end align-items-center">
            <OverlayTrigger placement="top" overlay={<Tooltip>Search scenario</Tooltip>}>
                <Form>
                    <Form.Group>
                        <Form.Control
                            style={{ maxWidth: '200px' }}
                            // size='sm'
                            type="text"
                            placeholder="Search scenario"
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    // handleSearch(e);
                                }
                            }}
                        />
                    </Form.Group>
                </Form>
            </OverlayTrigger>   
            <OverlayTrigger placement="top" overlay={<Tooltip>Filter scenario</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary">
                    <i className="bi bi-funnel"></i>
                </Button>
            </OverlayTrigger> 
            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Add a new scenario.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleAddScenario}>
                    <i className="bi bi-plus"></i>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Select a scenario first. Click this button to edit it.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                    <i className="bi bi-pencil"></i>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Select a scenario first. Click this button to delete it.</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                    <i className="bi bi-trash"></i>
                </Button>
            </OverlayTrigger>                                                       
            <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Download actor landscape</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                    <i className="bi bi-download"></i>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Upload actor landscape</Tooltip>}>
                <Button style={{ marginLeft: '10px' }} variant="outline-secondary" disabled>
                    <i className="bi bi-upload"></i>
                </Button>
            </OverlayTrigger>                                    
        </Col>
      </Row>
      <Row style={ { marginTop:'15px' } }>
        {/* Define use case scenarios */}
        <Col md={2}></Col>
        {(data.actions.value.length === 0) ? (
          <>
          <Row align='center' className="justify-content-center">
            <Col>
              <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '20%', height: 'auto' }}/> 
              <OverlayTrigger placement="top" overlay={<Tooltip>No scenario added. Add one manually.</Tooltip>}>
              <Button variant="primary" style={{ marginRight: '10px' }} onClick={handleAddScenario}>
                <i className="bi bi-add"></i> Add a scenario
              </Button>
              </ OverlayTrigger>
            </Col>
          </Row>
          </>
        ) : (
          <>
          <Col md={8} style={{ textAlign: 'left' }}>
          {/* Render scenario and condition inputs */}
          <Accordion id="accordionExample">
            {data.actions.value.map((scenario, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>
                  Scenario {index + 1}: {scenario.name}
                </Accordion.Header>
                <Accordion.Body>
                  <textarea
                    className="form-control mb-3"
                    value={scenario.description}
                    onChange={(e) => handleScenarioChange(index, e.target.value)}
                    rows={5}
                    placeholder={`Enter a scenario description...`}
                  />

                  <Button variant="primary" style={{ marginTop: '10px' }} onClick={() => addCondition(index)} disabled>
                    Add Condition, Constraint, or Assumption
                  </Button>

                  {/* Render conditions */}
                  <div className="row" style={{ marginTop: '10px' }}>
                    {data.conditions.value[index] && Object.entries(data.conditions.value[index]).map(([key, value], conIndex) => (
                      key !== 'scenario' && (
                        <div key={`${index}-${conIndex}`} className="col-md-6 mb-3">
                          <Form.Group className="d-flex justify-content-between">
                            <Form.Label>Condition {conIndex + 1}</Form.Label>
                            <Button variant="close" onClick={() => removeCondition(index, key)} disabled />
                          </Form.Group>
                          <Form.Group>
                            <Form.Select
                              disabled
                              name="type"
                              value={key}
                              //onChange={(e) => handleConditionChange(index, conIndex, e.target.value)}
                            >
                              <option value="preConditions">Pre-condition</option>
                              <option value="postConditions">Post-condition</option>
                              <option value="constraints">Constraint</option>
                              <option value="assumptions">Assumption</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name={key}
                            placeholder="Enter condition description..."
                            value={value}
                            onChange={(e) => handleConditionChange(index, key, e.target.value)}
                            className="mb-2"
                          />
                        </div>
                      )
                    ))}
                  </div>

                  <Button variant="danger" onClick={() => removeScenario(index)}>
                    Remove Scenario
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
          </>
        )}
        {/* Define use case conditions, constraints, and assumptions */}
        <Col md={2} style={{ textAlign: 'left' }}>
        </Col>
      </Row>
    </div>
  );
};

export default Action;
