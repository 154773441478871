/**
 * Knowledge Representation View
 * 
 * Description: This view provides a visualization of the knowledge representation
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-06
 * 
 */

import React from 'react';
import { Container } from 'react-bootstrap';
import KnwlDashboard from '../components/knowledge_handling/knwl_dashboard';

export default function KB() {

  return (
    <div align='center'>
      <Container fluid>
        <h4>Knowledge Representation</h4>
        <KnwlDashboard />
      </Container>
    </div>
  );
};

export { default as KB } from './KB';