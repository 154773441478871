/**
 * List countries
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-16
 * Notes: List of countries
 */

export const countryToContinentMapping = {
    // Europe
    AL: 'Europe', // Albania
    AD: 'Europe', // Andorra
    AT: 'Europe', // Austria
    BY: 'Europe', // Belarus
    BE: 'Europe', // Belgium
    BA: 'Europe', // Bosnia and Herzegovina
    BG: 'Europe', // Bulgaria
    HR: 'Europe', // Croatia
    CY: 'Europe', // Cyprus
    CZ: 'Europe', // Czech Republic
    DK: 'Europe', // Denmark
    EE: 'Europe', // Estonia
    FI: 'Europe', // Finland
    FR: 'Europe', // France
    DE: 'Europe', // Germany
    GR: 'Europe', // Greece
    HU: 'Europe', // Hungary
    IS: 'Europe', // Iceland
    IE: 'Europe', // Ireland
    IT: 'Europe', // Italy
    LV: 'Europe', // Latvia
    LI: 'Europe', // Liechtenstein
    LT: 'Europe', // Lithuania
    LU: 'Europe', // Luxembourg
    MT: 'Europe', // Malta
    MD: 'Europe', // Moldova
    MC: 'Europe', // Monaco
    ME: 'Europe', // Montenegro
    NL: 'Europe', // Netherlands
    MK: 'Europe', // North Macedonia
    NO: 'Europe', // Norway
    PL: 'Europe', // Poland
    PT: 'Europe', // Portugal
    RO: 'Europe', // Romania
    //RU: 'Europe', // Russia (partly in Asia)
    SM: 'Europe', // San Marino
    RS: 'Europe', // Serbia
    SK: 'Europe', // Slovakia
    SI: 'Europe', // Slovenia
    ES: 'Europe', // Spain
    SE: 'Europe', // Sweden
    CH: 'Europe', // Switzerland
    //TR: 'Europe', // Turkey (partly in Asia)
    UA: 'Europe', // Ukraine
    GB: 'Europe', // United Kingdom
    VA: 'Europe', // Vatican City

    // North America
    CA: 'North America', // Canada
    US: 'North America', // United States
    MX: 'North America', // Mexico

    // South America
    AR: 'South America', // Argentina
    BR: 'South America', // Brazil
    CL: 'South America', // Chile
    CO: 'South America', // Colombia
    PE: 'South America', // Peru
    VE: 'South America', // Venezuela

    // Africa
    DZ: 'Africa', // Algeria
    AO: 'Africa', // Angola
    EG: 'Africa', // Egypt
    ET: 'Africa', // Ethiopia
    GH: 'Africa', // Ghana
    KE: 'Africa', // Kenya
    MA: 'Africa', // Morocco
    NG: 'Africa', // Nigeria
    ZA: 'Africa', // South Africa
    TN: 'Africa', // Tunisia
    UG: 'Africa', // Uganda

    // Asia
    CN: 'Asia', // China
    IN: 'Asia', // India
    ID: 'Asia', // Indonesia
    IR: 'Asia', // Iran
    IQ: 'Asia', // Iraq
    IL: 'Asia', // Israel
    JP: 'Asia', // Japan
    KZ: 'Asia', // Kazakhstan (partly in Europe)
    KR: 'Asia', // South Korea
    KW: 'Asia', // Kuwait
    MY: 'Asia', // Malaysia
    PK: 'Asia', // Pakistan
    PH: 'Asia', // Philippines
    SA: 'Asia', // Saudi Arabia
    SG: 'Asia', // Singapore
    TH: 'Asia', // Thailand
    TR: 'Asia', // Turkey (partly in Europe)
    AE: 'Asia', // United Arab Emirates
    VN: 'Asia', // Vietnam

    // Oceania
    AU: 'Oceania', // Australia
    FJ: 'Oceania', // Fiji
    NZ: 'Oceania', // New Zealand
    PG: 'Oceania', // Papua New Guinea

    // Antarctica
    AQ: 'Antarctica', // Antarctica

    // Major World Countries (Others)
    RU: 'Asia', // Russia (partly in Europe)
    //TR: 'Asia', // Turkey (partly in Europe)
    //SA: 'Asia', // Saudi Arabia
    //IR: 'Asia', // Iran
    //EG: 'Africa', // Egypt
    //NG: 'Africa', // Nigeria
    //ZA: 'Africa', // South Africa
    //IL: 'Asia', // Israel
    //AE: 'Asia', // United Arab Emirates
    //SG: 'Asia', // Singapore
};
