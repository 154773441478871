/**
 * Contains the static chat prompts that are used to interact with the OpenAI API to retrieve use case details.
 *
 * Description: Provides the static chat prompts for the use case creator.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-13 (last updated: 2024-06-23)
 * Notes: /
 */

// Expected format messages
const msg_comma_separated = "Comma-separated words only"; //"Provide the terms in a comma-separated way only.";
const msg_few_words = "Single word or a few words";
const msg_acronym = "Single acronym";
const msg_single_word_from_list = "Single word from the given list";
const msg_short_text = "Short text";
// Maximum items message
const msg_max_phrases = "Provide a maximum of 10 phrases.";
const msg_one_word = "Provide a single word.";
const msg_max_words = "Provide a maximum of 3 words.";
const msg_max_items = "Provide a maximum of 5 items.";
// Semantic model enrichment
const msg_instruction = "Retrieve the corresponding terms from the semantic data, if applicable. Include the following concepts if relevant: {semantic_terms}";

// Define the system message
export const systemMessage = `
    Please now provide some context what the use case is about?
`;
// I support you creating a use case. At first, please provide some context what the use case is about?

// STEP 1 DESCRIPTION: Define the prompts to retrieve the use case details
export const prompts_info0 = [    
    "Just provide the name of your use case; just the relevant word(s), no phrase around",
    "Just provide the plain acronym of your use case; just the relevant word(s), no phrase around.",
    "Just provide the maturity level of your use case. You choose from Initial, Managed, Defined, Quantitative, Optimized. Just provide the word in the answer.",
    "Just provide the application area of your use case; Select one term from: Industry, Administration, Research, Other. Just provide the relevant word, no phrase around.",
    "Just provide the status of your use case; Select one term from: Draft, Created, Validated, Applied. Just provide the relevant word, no phrase around.",
    "Just provide the tags/keywords related to your use case. Just provide the relevant word(s), no phrase around." + msg_comma_separated,
    "Just provide the textual description of your use case; just the relevant description. Keep it short and concise.",
];

// STEP 1 DESCRIPTION: Define the prompts to retrieve the use case details
export const prompts_info = [    
    {
        prompt: "Just provide the name of your use case; just the relevant word(s), no phrase around.",
        context: "To retrieve the use case name",
        expected_format: msg_few_words,
        items: msg_max_words,
        example: "Smart Automated Parking"
    },
    {
        prompt: "Just provide the plain acronym of your use case; just the relevant word(s), no phrase around.",
        context: "To retrieve the use case acronym",
        expected_format: msg_acronym,
        items: msg_one_word,
        example: "SP"
    },
    {
        prompt: "Just provide the maturity level of your use case. You choose from Initial, Managed, Defined, Quantitative, Optimized. Just provide the word in the answer.",
        context: "To determine the maturity level",
        expected_format: msg_single_word_from_list,
        items: msg_one_word,
        example: "Defined"
    },
    {
        prompt: "Just provide the application area of your use case; Select one term from: Industry, Administration, Research, Other. Just provide the relevant word, no phrase around.",
        context: "To identify the application area",
        expected_format: msg_single_word_from_list,
        items: msg_one_word,
        example: "Industry"
    },
    {
        prompt: "Just provide the status of your use case; Select one term from: Draft, Created, Validated, Applied. Just provide the relevant word, no phrase around.",
        context: "To know the current status",
        expected_format: msg_single_word_from_list,
        items: msg_one_word,
        example: "Created"
    },
    {
        prompt: "Just provide the tags/keywords related to your use case. Just provide the relevant word(s), no phrase around. Provide the terms in a comma-separated way only.",
        context: "To gather related tags/keywords",
        expected_format: msg_comma_separated,
        items: msg_max_items,
        example: "Smart, Parking, Urban, Platform"
    },
    {
        prompt: "Just provide the textual description of your use case; just the relevant description. Keep it short and concise.",
        context: "To get a brief description",
        expected_format: msg_short_text,
        items: msg_max_phrases,
        example: "A smart parking solution that provides real-time data on available parking spaces."
    },
];

// STEP 4.1 ACTIONS: Define the prompts to retrieve the actions of the use case
export const prompts_actions0 = [
    "Just provide the basic scenario for the involved use case. Provide just the short brief scenario name, starting with Scenario:, followed by Description: and then the description of the scenario.",
    "Just provide the advanced scenario for the involved use case. Provide just the short brief scenario name, starting with Scenario:, followed by Description: and then the description of the scenario.",
    "Just provide the cutting-edge scenario for the involved use case. Provide just the short brief scenario name, starting with Scenario:, followed by Description: and then the description of the scenario.",
];

export const prompts_actions = [
    {
      prompt: "Just provide the basic scenario for the involved use case. Provide just the short brief scenario name, starting with Scenario:, followed by Description: and then the description of the scenario.",
      context: "To retrieve the basic scenario for the use case",
      expected_format: "Scenario: <scenario_name> Description: <Description>",
      items: "short brief scenario name and description",
      example: "Scenario: Basic Parking, Description: Driver finds a parking space using the app."
    },
    {
      prompt: "Just provide the advanced scenario for the involved use case. Provide just the short brief scenario name, starting with Scenario:, followed by Description: and then the description of the scenario.",
      context: "To retrieve the advanced scenario for the use case",
      expected_format: "Scenario: <scenario_name> Description: <Description>",
      items: "short brief scenario name and description",
      example: "Scenario: Advanced Parking, Description: App provides real-time parking space availability and reservation."
    },
    {
      prompt: "Just provide the cutting-edge scenario for the involved use case. Provide just the short brief scenario name, starting with Scenario:, followed by Description: and then the description of the scenario.",
      context: "To retrieve the cutting-edge scenario for the use case",
      expected_format: "Scenario: <scenario_name> Description: <Description>",
      items: "short brief scenario name and description",
      example: "Scenario: Cutting-edge Parking, Description: Autonomous vehicle finds and navigates to a parking space using the app."
    }
];
  
// STEP 4.2 CONDITIONS: Define the prompts to retrieve the conditions of the use case
export const prompts_conditions0 = [
    "Just provide the pre-condition for the basic scenario use case. For this precondition, only provide the the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the post-condition for the basic scenario use case. For this postcondition, only provide the the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the constraints for the basic scenariovuse case. For these constraints, only provide the the word constraint, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the assumptions for the basic scenario use case. For these assumptions, only provide the the word assumption, followed by a comma, then the relevant information in a short sentence.",

    "Just provide the pre-condition for the advanced scenario use case. For this precondition, only provide the the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the post-condition for the advanced scenario use case. For this postcondition, only provide the the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the constraints for the advanced scenariovuse case. For these constraints, only provide the the word constraint, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the assumptions for the advanced scenario use case. For these assumptions, only provide the the word assumption, followed by a comma, then the relevant information in a short sentence.",

    "Just provide the pre-condition for the cutting-edge scenario use case. For this precondition, only provide the the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the post-condition for the cutting-edge scenario use case. For this postcondition, only provide the the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the constraints for the cutting-edge scenariovuse case. For these constraints, only provide the the word constraint, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the assumptions for the cutting-edge scenario use case. For these assumptions, only provide the the word assumption, followed by a comma, then the relevant information in a short sentence.",
];

export const prompts_conditions = [
    {
      prompt: "Just provide the pre-condition for the basic scenario use case. For this precondition, only provide the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the pre-condition for the basic scenario",
      expected_format: "pre-condition, <relevant information>",
      items: "short sentence",
      example: "pre-condition, The system must have parking space data."
    },
    {
      prompt: "Just provide the post-condition for the basic scenario use case. For this postcondition, only provide the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the post-condition for the basic scenario",
      expected_format: "post-condition, <relevant information>",
      items: "short sentence",
      example: "post-condition, The parking space must be reserved."
    },
    {
      prompt: "Just provide the constraints for the basic scenario use case. For these constraints, only provide the word constraint, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the constraints for the basic scenario",
      expected_format: "constraint, <relevant information>",
      items: "short sentence",
      example: "constraint, The system must handle up to 1000 parking spaces."
    },
    {
      prompt: "Just provide the assumptions for the basic scenario use case. For these assumptions, only provide the word assumption, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the assumptions for the basic scenario",
      expected_format: "assumption, <relevant information>",
      items: "short sentence",
      example: "assumption, The user has a smartphone with internet access."
    },
    {
      prompt: "Just provide the pre-condition for the advanced scenario use case. For this precondition, only provide the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the pre-condition for the advanced scenario",
      expected_format: "pre-condition, <relevant information>",
      items: "short sentence",
      example: "pre-condition, The system must have real-time parking data."
    },
    {
      prompt: "Just provide the post-condition for the advanced scenario use case. For this postcondition, only provide the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the post-condition for the advanced scenario",
      expected_format: "post-condition, <relevant information>",
      items: "short sentence",
      example: "post-condition, The parking space must be confirmed in real-time."
    },
    {
      prompt: "Just provide the constraints for the advanced scenario use case. For these constraints, only provide the word constraint, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the constraints for the advanced scenario",
      expected_format: "constraint, <relevant information>",
      items: "short sentence",
      example: "constraint, The system must handle real-time updates."
    },
    {
      prompt: "Just provide the assumptions for the advanced scenario use case. For these assumptions, only provide the word assumption, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the assumptions for the advanced scenario",
      expected_format: "assumption, <relevant information>",
      items: "short sentence",
      example: "assumption, The user has a GPS-enabled device."
    },
    {
      prompt: "Just provide the pre-condition for the cutting-edge scenario use case. For this precondition, only provide the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the pre-condition for the cutting-edge scenario",
      expected_format: "pre-condition, <relevant information>",
      items: "short sentence",
      example: "pre-condition, The system must support autonomous vehicles."
    },
    {
      prompt: "Just provide the post-condition for the cutting-edge scenario use case. For this postcondition, only provide the word pre-condition or post-condition, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the post-condition for the cutting-edge scenario",
      expected_format: "post-condition, <relevant information>",
      items: "short sentence",
      example: "post-condition, The autonomous vehicle is parked."
    },
    {
      prompt: "Just provide the constraints for the cutting-edge scenario use case. For these constraints, only provide the word constraint, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the constraints for the cutting-edge scenario",
      expected_format: "constraint, <relevant information>",
      items: "short sentence",
      example: "constraint, The system must integrate with autonomous vehicle systems."
    },
    {
      prompt: "Just provide the assumptions for the cutting-edge scenario use case. For these assumptions, only provide the word assumption, followed by a comma, then the relevant information in a short sentence.",
      context: "To retrieve the assumptions for the cutting-edge scenario",
      expected_format: "assumption, <relevant information>",
      items: "short sentence",
      example: "assumption, The autonomous vehicle has the necessary sensors."
    }
];
  
// STEP 3 ACTORS: Define the prompts to retrieve the actors of the use case
export const prompts_actor0 = [
    // Consumer
    "Just provide the actors/stakeholder involved in your use case, which embody the role of being the CONSUMER/RECIPIENT." + 
    "Such actors could be: Service Consumer, Driver, Passenger, etc." +
    "Only give the names of the actors in a comma-separated list.",
    // Producer
    "Just provide the actors/stakeholder involved in your use case, which embody the role of being the PRODUCER/SUPPLIER." + 
    "Such actors could be: Data Provider, Service Provider, Driver, etc." +
    "Only give the names of the actors in a comma-separated list.",
    // Platform Provider
    "Just provide the actors/stakeholder involved in your use case, which embody the role of being the PLATFORM PRODIVER. A producer could be a platform provider, too. Only give the names of the actors in a comma-separated list." +
    "Such actors could be: Platform Provider, Data Provider, Service Provider, etc." +
    "Only give the names of the actors in a comma-separated list.",
    // Technical Enabler
    "Just provide the actors/stakeholder involved in your use case, which embody the role of being the TECHNICAL ENABLER. Only give the names of the actors in a comma-separated list." +
    "Such actors could be: Software-Stack Provider, Systems, ERP, etc." +
    "Only give the names of the actors in a comma-separated list.",
    // Operator
    "Just provide the actors/stakeholder involved in your use case, which embody the role of being the OPERATOR. Only give the names of the actors in a comma-separated list." +
    "Such actors could be: Operator, Tele-Operator, Driver, Distributor, Warehouse Manager, Maintenance, etc." +
    "Only give the names of the actors in a comma-separated list.",
    // Regulator
    "Just provide the actors/stakeholder involved in your use case, which embody the role of being the REGULATOR. Only give the names of the actors in a comma-separated list." +
    "Such actors could be: Regulator, Government, State Authority, etc." +
    "Only give the names of the actors in a comma-separated list.",
];

export const prompts_actor = [
    {
      prompt: "Just provide the actors/stakeholders involved in your use case, which embody the role of being the CONSUMER/RECIPIENT. Only give the names of the actors in a comma-separated list.",
      context: "To identify the actors who are consumers or recipients in the use case.",
      expected_format: "comma-separated list",
      items: "actors",
      example: "Service Consumer, Driver, Passenger"
    },
    {
      prompt: "Just provide the actors/stakeholders involved in your use case, which embody the role of being the PRODUCER/SUPPLIER. Only give the names of the actors in a comma-separated list.",
      context: "To identify the actors who are producers or suppliers in the use case.",
      expected_format: "comma-separated list",
      items: "actors",
      example: "Data Provider, Service Provider, Driver"
    },
    {
      prompt: "Just provide the actors/stakeholders involved in your use case, which embody the role of being the PLATFORM PROVIDER. Only give the names of the actors in a comma-separated list.",
      context: "To identify the actors who are platform providers in the use case.",
      expected_format: "comma-separated list",
      items: "actors",
      example: "Platform Provider, Data Provider, Service Provider"
    },
    {
      prompt: "Just provide the actors/stakeholders involved in your use case, which embody the role of being the TECHNICAL ENABLER. Only give the names of the actors in a comma-separated list.",
      context: "To identify the actors who are technical enablers in the use case.",
      expected_format: "comma-separated list",
      items: "actors",
      example: "Software-Stack Provider, Systems, ERP"
    },
    {
      prompt: "Just provide the actors/stakeholders involved in your use case, which embody the role of being the OPERATOR. Only give the names of the actors in a comma-separated list.",
      context: "To identify the actors who are operators in the use case.",
      expected_format: "comma-separated list",
      items: "actors",
      example: "Operator, Tele-Operator, Driver, Distributor, Warehouse Manager, Maintenance"
    },
    {
      prompt: "Just provide the actors/stakeholders involved in your use case, which embody the role of being the REGULATOR. Only give the names of the actors in a comma-separated list.",
      context: "To identify the actors who are regulators in the use case.",
      expected_format: "comma-separated list",
      items: "actors",
      example: "Regulator, Government, State Authority"
    }
];
  
// STEP 5 MODEL: Define the prompts to retrieve the model of the use case
//
// Note: the model is built up based on the initial prompts and the parsed RDF data from the ontology
// The template for the model is as follows:
// Main components:
//    - Operations: Systems, Services, and Tools
//        - Operations_Management
//        - Operations_Analytics
//        - Operations_Platform
//    - Sender
//        - Sender_Analytics
//        - Sender_Management
//        - Sender_Platform
//    - Recipient
//        - Recipient_Analytics
//        - Recipient_Delivery
//        - Recipient_Platform
//   - Technical Layer
//        - Applications / Interfaces (proprietary, third-party, partially distributed)
//        - Platform and Cloud (proprietary, third-party, partially distributed)
//        - ADS: Automated Driving System
//        - Hardware (Vehicle, Infrastructure)
export const prompts_components0 = [
    // Operations Management
    "Considering the OPERATIONS, provide components for the use case's Operations_Management." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Operations Analytics
    "Considering the OPERATIONS, provide components for the use case's Operations_Analytics." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Operations Platform
    "Considering the OPERATIONS, provide components for the use case's Operations_Platform." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Producer / Sender Analytics
    "Considering the PRODUCER, provide components for the use case's Sender_Analytics." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Producer / Sender Management
    "Considering the PRODUCER, provide components for the use case's Sender_Management." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Producer / Sender Platform
    "Considering the PRODUCER, provide components for the use case's Sender_Platform." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Consumer / Recipient Analytics
    "Considering the RECIPIENT, provide components for the use case's Recipient_Analytics." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Consumer / Recipient Delivery
    "Considering the RECIPIENT, provide components for the use case's Recipient_Delivery." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Consumer / Recipient Platform
    "Considering the RECIPIENT, provide components for the use case's Recipient_Platform." +
    msg_comma_separated + msg_max_items + msg_instruction,

    // Technical Layer
    
    // Applications / Interfaces
    "Considering the TECHNICAL LAYER, provide about 5 components for the use case's Applications / Interfaces (proprietary, third-party, partially distributed)." +
    msg_comma_separated +
    msg_instruction,

    // Platform and Cloud
    "Considering the TECHNICAL LAYER, provide about 5 components for the use case's Platform and Cloud (proprietary, third-party, partially distributed)." +
    msg_comma_separated +
    msg_instruction,

    // ADS
    "Considering the TECHNICAL LAYER, provide about 5 components for the use case's ADS: Automated Driving System." +
    msg_comma_separated +
    msg_instruction,

    // AI-Middleware and Edge
    "Considering the TECHNICAL LAYER, provide about 5 components for the use case's AI-Middleware and Edge (Devices)." +
    msg_comma_separated +
    msg_instruction,

    // Hardware
    "Considering the TECHNICAL LAYER, provide about 5 components for the use case's Hardware (Vehicle, Infrastructure)." +
    msg_comma_separated +
    msg_instruction,
];

export const prompts_components = [
  {
    prompt: "Considering the OPERATIONS, provide components for the use case's Operations_Management.",
    context: "To gather components related to Operations Management.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the OPERATIONS, provide components for the use case's Operations_Analytics.",
    context: "To gather components related to Operations Analytics.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the OPERATIONS, provide components for the use case's Operations_Platform.",
    context: "To gather components related to Operations Platform.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the PRODUCER, provide components for the use case's Sender_Analytics.",
    context: "To gather components related to Sender Analytics.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the PRODUCER, provide components for the use case's Sender_Management.",
    context: "To gather components related to Sender Management.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the PRODUCER, provide components for the use case's Sender_Platform.",
    context: "To gather components related to Sender Platform.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the RECIPIENT, provide components for the use case's Recipient_Analytics.",
    context: "To gather components related to Recipient Analytics.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the RECIPIENT, provide components for the use case's Recipient_Delivery.",
    context: "To gather components related to Recipient Delivery.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the RECIPIENT, provide components for the use case's Recipient_Platform.",
    context: "To gather components related to Recipient Platform.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the TECHNICAL LAYER, provide about 5 components for the use case's Applications / Interfaces (proprietary, third-party, partially distributed).",
    context: "To gather components related to Applications/Interfaces in the Technical Layer.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the TECHNICAL LAYER, provide about 5 components for the use case's Platform and Cloud (proprietary, third-party, partially distributed).",
    context: "To gather components related to Platform and Cloud in the Technical Layer.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the TECHNICAL LAYER, provide about 5 components for the use case's Control System.",
    context: "To gather components related to Control Systems in the Technical Layer.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the TECHNICAL LAYER, provide about 5 components for the use case's AI-Middleware and Edge (Devices).",
    context: "To gather components related to AI-Middleware and Edge (Devices) in the Technical Layer.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  {
    prompt: "Considering the TECHNICAL LAYER, provide about 5 components for the use case's Hardware (Infrastructure, Computing).",
    context: "To gather components related to Hardware (Infrastructure, Computing) in the Technical Layer.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  // Regulator: Directives
  {
    prompt: "Considering the REGULATOR, provide components for the use case's Directives.",
    context: "To gather components related to Directives in the Regulator domain.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  // Regulator: Legal Reference Cases
  {
    prompt: "Considering the REGULATOR, provide components for the use case's Legal Reference Cases.",
    context: "To gather components related to Legal Reference Cases in the Regulator domain.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
  // Regulator: Standards
  {
    prompt: "Considering the REGULATOR, provide components for the use case's Standards.",
    context: "To gather components related to Standards in the Regulator domain.",
    expected_format: msg_comma_separated + msg_max_items,
    items: "components",
    example: msg_instruction
  },
];

// STEP 6 MODEL: Define the prompts to retrieve the Model of the use case
export const prompts_model = [
    "Just provide the domain-specific components for the use case. For these components, only provide the the word domain-specific components, followed by a comma, then the relevant information in a short sentence.",
    "Just provide the generic components for the use case. For these components, only provide the the word generic components, followed by a comma, then the relevant information in a short sentence.",
];

// CONTEXT provided to the chat
export const init_context = `
    You are here to help to create a use case. A user provides some details about the type of use case to be created. 

    You help the user identifying use cases in a certain domain. The domain includes concepts such as different stakeholders,
    resources, architecture types, deployments, etc.    

    You are placed in the use case creator. Let's create a use case together. 
    You provide some information about the stakeholders, resources, architecture types, deployments, billing strategies, etc. that are relevant to this use case.

    To create a use case, we need to gather some information.
`;