/**
 * Knowledge Representation Component Step Enrichment Structure
 * 
 * Description: Sets the structure for the knowledge representation component step enrichment
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-20; adopted 2024-09-21
 * 
 */


// GUCO
export const comp_semantic_enrichment_guco = {
    // Information
    'Use Case Information': 'Description',
    'Actions': 'Actions',
    'Conditions': 'Conditions',
    'Actors': 'Actors',
}

// Baseline mapping
export const comp_semantic_enrichment_baseline = {
    // Operations
    'Operations Management': '',
    'Operations Analytics': '',
    'Operations Platform': '',
    // Producer
    'Producer Analytics': '',
    'Producer Management': '',
    'Producer Platform': '',
    // Consumer
    'Consumer Analytics': '',
    'Consumer Delivery': '',
    'Consumer Platform': '',
    // Technical Layer
    'Applications': '',
    'Platform': '',
    'Control': '',
    'AI-Middleware': '',
    'Hardware': ''
}

// Mapping for PEAMONTO
export const comp_semantic_enrichment_peamonto = {
    // Operations
    'Operations Management': 'Operations',
    'Operations Analytics': 'Analytics (Global)',
    'Operations Platform': 'Concepts',
    // Producer
    'Producer Analytics': 'Analytics (Sender)',
    'Producer Management': 'Management',
    'Producer Platform': 'Shop (App)',
    // Consumer
    'Consumer Analytics': 'Manage',
    'Consumer Delivery': 'Delivery',
    'Consumer Platform': 'Shop Recipient (App)',
    // Technical Layer
    'Applications': 'Application',
    'Platform': 'Platform',
    'ADS': 'ADS',
    'AI-Middleware': 'Middleware',
    'Hardware': 'Hardware (Assets)'
}