/**
 * Modal Edit Node
 *
 * Description: Modal to edit an edge between two nodes
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-16
 * 
 */

import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditNodeModal = ({ show, onHide, node, onEditNode }) => {
    
    const [newNodeLabel, setNewNodeLabel] = useState(node ? node.label : '');

    useEffect(() => {
        if (node) {
            setNewNodeLabel(node.label);
        }
    }, [node]);

    const handleEditNode = () => {
        if (newNodeLabel) {
            onEditNode(newNodeLabel);
            onHide();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            handleEditNode();
        }
    };

    if (!node) {
        return null; // Render nothing if node is undefined
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Actor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formNewNodeLabel">
                        <Form.Label>Actor Label</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter node label"
                            value={newNodeLabel}
                            onChange={(e) => setNewNodeLabel(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleEditNode}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditNodeModal;