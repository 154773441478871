

import React from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import '../../App.css';
import Papierboot_Footer from '../../assets/img/Papierboot_Footer.JPG';
import Papierboot from '../../assets/img/Papierboot.png';

export default function Footer(props) {

  const navigate = useNavigate();
  const navigateParticipate = () => {
    navigate('/Participate');
  };

  return (
    <footer className="footer text-center">
      <div style={{ marginBottom: '15px', marginTop:'50px' }}>
        <hr />
        <img src={Papierboot_Footer} className="img-fluid" alt="Responsive" />
        <Row style={{ marginTop: '30px', marginLeft: 0, marginRight: 0 }}>
          <Col md={2}></Col>
          <Col md={4}>
            <Row>
              <Col md={2}>
                <img src={Papierboot} className="img-fluid" alt="Responsive" />
              </Col>
              <Col md={10} style={{ marginTop: '15px' }} align="left">
                <p><small>{props.name}</small></p>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={8}></Col>
              <Col md={2} align="right">            
                <Button to="/Participate" variant="dark" className="btn-sm" onClick={navigateParticipate}>Participate</Button>
              </Col>
              <Col md={2}></Col>
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
        <Row style={{ marginLeft: 0, marginRight: 0, marginTop: '50px', overflowY:'0px' }}>
          <Col md={2}></Col>
          <Col md={6} align='left'>
          <small>&copy; 2020-2024 @ MGA, partially part of the author's PhD research at TU Berlin.</small>
          </Col>
          <Col md={2} align='right'>
            <Link className="text-muted" disabled>Impressum</Link>
            <Link to="/About" className="text-muted" style={{ marginLeft: '10px' }}>About</Link>
            <Link className="text-muted" style={{ marginLeft: '10px' }} disabled>Feedback</Link>
          </Col>
          <Col md={2}></Col>
        </Row>     
      </div>
    </footer>
  );
};

export { default as Footer } from './Footer';