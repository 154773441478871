/**
 * Prepares data for ecosystem analytics visualization
 *
 * Description: Provides the necessary functions to prepare the data for comparison and visualization.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-15
 * 
 */

/** Function to generate analytics data
 * @param {*} data
 * @returns 
 */
export const generateAnalyticsData = (data) => {
  const totalUseCases = data.length;
  
  const application = data.reduce((acc, useCase) => {
    acc[useCase.application.value] = (acc[useCase.application.value] || 0) + 1;
    return acc;
  }, {});

  const maturityLevels = data.reduce((acc, useCase) => {
    acc[useCase.maturity.value] = (acc[useCase.maturity.value] || 0) + 1;
    return acc;
  }, {});

  const status = data.reduce((acc, useCase) => {
    acc[useCase.status.value] = (acc[useCase.status.value] || 0) + 1;
    return acc;
  }, {});

  const totalActors = data.reduce((acc, useCase) => acc + useCase.actors.value.nodes.value.length, 0);
  const totalComponents = data.reduce((acc, useCase) => acc + useCase.components.value.length, 0);
  const totalConditions = data.reduce((acc, useCase) => acc + useCase.conditions.value.length, 0);

  // Prepare time series data for component use
  const timeSeries = data.map((useCase) => {
    return {
      date: useCase.created.value,
      componentUsage: useCase.components.value.length,
    };
  });

  // Generate tag frequency data
  const tagFrequency = generateTagFrequency(data);
  //console.log('tagFrequency', tagFrequency);

  return {
    totalUseCases,
    application,
    maturityLevels,
    status,
    totalActors,
    totalComponents,
    totalConditions,
    timeSeries,
    tagFrequency
  };
};
    
/** Function to prepare chart data
 * @param {*} data
 * @param {*} label
 * @returns 
 */
export const prepareChartData = (data = {}, label) => {
    const colors = [
      'rgba(75,192,192,0.4)', 'rgba(75,192,192,0.6)', 'rgba(75,192,192,0.8)', 'rgba(75,192,192,1)',
      'rgba(153,102,255,0.4)', 'rgba(153,102,255,0.6)', 'rgba(153,102,255,0.8)', 'rgba(153,102,255,1)',
      'rgba(255,159,64,0.4)', 'rgba(255,159,64,0.6)', 'rgba(255,159,64,0.8)', 'rgba(255,159,64,1)'
    ];
    
    return {
      labels: Object.keys(data),
      datasets: [{
        label: label,
        data: Object.values(data),
        backgroundColor: colors,
        borderColor: colors.map(color => color.replace('0.4', '1').replace('0.6', '1').replace('0.8', '1')),
        borderWidth: 1,
      }]
    };
};

/**
 * Prepares data for general ecosystem statistics: total actors, components, etc.
 * @param {*} data 
 * @returns 
 */
export const aggregateData = (data) => {

    const standardActors = new Set([
      'Actors',
      'Consumer / Recipient',
      'Platform Provider',
      'Producer / Supplier',
      'Regulator',
      'Operator',
      'Technical Enabler'
    ]);
  
    const actorCategories = {};
    const componentCategories = {};
    const uniqueActors = new Set();
    const uniqueComponents = new Set();
    let totalRelations = 0;
  
    data.forEach((useCase) => {
      if (useCase.actors.value && useCase.actors.value.list) {
        Object.keys(useCase.actors.value.list).forEach((category) => {
          actorCategories[category] = (actorCategories[category] || 0) + useCase.actors.value.list[category].value.length;
        });
      }
      
      if (useCase.actors.value && useCase.actors.value.nodes) {
        useCase.actors.value.nodes.value.forEach(node => {
          if (!standardActors.has(node.label)) {
            uniqueActors.add(node.id);
          }
        });
        totalRelations += useCase.actors.value.edges.value.length;
      }
  
      if (useCase.components.value) {
        useCase.components.value.forEach((componentCategory) => {
          const category = componentCategory.category || 'Unknown';
          componentCategories[category] = (componentCategories[category] || 0) + componentCategory.components.length;
          componentCategory.components.forEach((component) => {
            component.items.forEach((item) => uniqueComponents.add(item));
          });
        });
      }
    });
  
    return {
      actorCategories,
      totalActors: uniqueActors.size,
      totalRelations,
      totalActions: data.reduce((acc, useCase) => acc + useCase.actions.value.length, 0),
      componentCategories,
      totalComponents: uniqueComponents.size
    };
};

/**
 * Calculate the frequency of tags in the use cases
 * @param {*} text
 * @returns 
 */
const generateTagFrequency = (useCases) => {
  const tagCount = {};

  useCases.forEach(useCase => {
    useCase.tags.value.forEach(tag => {
      if (tagCount[tag]) {
        tagCount[tag] += 1;
      } else {
        tagCount[tag] = 1;
      }
    });
  });

  return Object.keys(tagCount).map(tag => ({ text: tag, value: tagCount[tag] }));
};