import React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom'; // Navigate

import { NavBar } from './NavBar';

import { About } from '../../views/About';
import { UCM } from '../../views/UCM';
import { Approach } from '../../views/Approach';
import { KB } from '../../views/KB';
import { Create } from '../../views/Create';
import { Participate } from '../../views/Participate';
import { Instructions } from '../../views/Instructions';
import { Analytics } from '../../views/Analytics';
import { Repository } from '../../views/Repository';
import { Dashboard } from '../../views/Dashboard';
import { Prompts } from '../../views/Prompts';
import { Account } from '../../views/Account';
import { Survey } from '../../views/Survey';
import { Interview } from '../../views/Interview';

export const CallRoutes = () => {

  return (
    <div>
      <NavBar />
      <Routes>  
      <Route exact path="/Start" element={<UCM/>} />
        <Route exact path="/" element={ <Navigate to="/UCM" /> }></Route>
        <Route exact path="/UCM" element={<UCM/>} />
        <Route exact path="/About/*" element={<About/>} />
        <Route exact path="/Approach" element={<Approach/>} />
        <Route exact path="/KB" element={<KB/>} />
        <Route exact path="/Create" element={<Create/>} />
        <Route exact path="/Participate" element={<Participate/>} />
        <Route exact path="/Instructions" element={<Instructions/>} />
        <Route exact path="/Analytics" element={<Analytics/>} />
        <Route exact path="/Repository" element={<Repository/>} />
        <Route exact path="/Dashboard" element={<Dashboard/>} />
        <Route exact path="/Prompts" element={<Prompts/>} />
        <Route exact path="/Account" element={<Account/>} />
        <Route exact path="/Survey" element={<Survey/>} />
        <Route exact path="/Interview" element={<Interview/>} />
      </Routes>
    </div>
  );
};
