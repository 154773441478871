/**
 * Handles initial authentication and routing to the appropriate component based on user authentication status.
 *
 * Description: Used just to check if the user is signed in and direct to the Start component if true, otherwise direct to the SignIn component
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-04-13
 * Updated: 2024-05-01; last updated 2024-07-07
 */

import React, { useEffect, useState } from 'react';

import './App.css';

import { SignIn } from './components/auth_mgmt/auth_handling';
import { onAuthStateChanged, getAuth } from "firebase/auth";

import Loading from './components/elems/elem_load_login';
import Start from './start.js';
import KeyEntry from './components/auth_mgmt/auth_key';

/**
 * Checks if the user is signed in and directs to the Start component if true, otherwise directs to the SignIn component#
 */
function App() {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [keyVerified, setKeyVerified] = useState(false);

  // Check if user is signed in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Once authentication state is checked, set loading to false
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />; // Show loading indicator while checking authentication state
  }

  if (user) {
    return <Start />;
  }

  if (!keyVerified) {
    return <KeyEntry onKeyVerified={() => setKeyVerified(true)} />;
  }

  return <SignIn />;

  //return user ? <Start /> : <SignIn />;

}

export default App;
