/**
 * Modal
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-17
 * 
 */

import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Papierboot from '../../assets/img/Papierboot.png';

const MetaDataComponent = ({ metaData, handleShowMetaInfo, handleInputChange, data, showMetaInfo }) => {
    return (
      <>
        <hr />
        {metaData && (
          <div className="mb-3" align='left' style={{ fontSize: '12px' }}>
            <label className="form-label"><b>Use case visibility and usage:</b></label>
            <div style={{ float: 'right' }}>
              <Button variant="outline-secondary" size="sm" onClick={() => handleShowMetaInfo()}>
                <i className="bi bi-info-circle"></i>
              </Button>
            </div>
            <div className="form-check form-switch" style={{ marginBottom: '15px' }}>
              <input
                className="form-check-input"
                type="checkbox"
                name="visibility"
                id="visibility"
                onChange={handleInputChange}
                checked={data.visibility.value || true} // true is default
                disabled={true}
              />
              <OverlayTrigger placement="top" overlay={<Tooltip>A use case is per default made available in the repository</Tooltip>}>
                <label className="form-check-label" htmlFor="visibility">
                  <b>Visibility*</b><br /><small>use case is made available in the repository (*per default selected in the research stage for data collection and analysis)</small>
                </label>
              </OverlayTrigger>
            </div>
            <div className="form-check form-switch" style={{ marginBottom: '15px' }}>
              <input
                className="form-check-input"
                type="checkbox"
                name="adoption"
                id="adoption"
                onChange={handleInputChange}
                checked={data.adoption.value || false}
              />
              <OverlayTrigger placement="top" overlay={<Tooltip>Allow community to use the created use case as a template for modeling new use cases</Tooltip>}>
                <label className="form-check-label" htmlFor="adoption">
                  <b>Adoption</b><br /><small>allows community to use the created use case as a template. This helps building synergies of use case elements across the ecosystem</small>
                </label>
              </OverlayTrigger>
            </div>
            <div className="form-check form-switch" style={{ marginBottom: '15px' }}>
              <input
                className="form-check-input"
                type="checkbox"
                name="share"
                id="share"
                onChange={handleInputChange}
                checked={data.share.value || false}
              />
              <OverlayTrigger placement="top" overlay={<Tooltip>Allow the community to use manually added custom domain models</Tooltip>}>
                <label className="form-check-label" htmlFor="share">
                  <b>Share</b><br /><small>allows usage of manually added linked data in the community</small>
                </label>
              </OverlayTrigger>
            </div>
            {showMetaInfo && (
              <div align='left'>
                <p>
                  <small>
                    <i className="bi bi-info-circle"></i> Meta data properties allow you to decide the visibility and usage of the use case in the repository.
                    You and your peers may benefit from a shared use case repository, where you can find and reuse existing use cases.
                  </small>
                </p>
              </div>
            )}
            <hr />
          </div>
        )}
      </>
    );
};

const ModelModalSave = ({ data, setData, onClose, uc_name, isEdit, handleSubmitSave, handleSubmitEdit }) => {

    //const [selectedBoxes, setSelectedBoxes] = useState(data.domain_model.value || []);
    const [metaData, setMetaData] = useState(false);
    const [showMetaInfo, setShowMetaInfo] = useState(false);
    const [ucIsStored, setUCIsStored] = useState(false);

    useEffect(() => {
      setUCIsStored(isEdit)
    }, [isEdit]);

    const navigate = useNavigate();

    const navigateUCM = () => {
        navigate('/UCM');
    };

    /**
     * Update the data object with the input field value.
     * @param {*} e 
     */
    const handleInputChange = (e) => {
      const { name, type, checked, value } = e.target;
    
      // For checkboxes (adoption, share, etc.)
      if (type === 'checkbox') {
        setData(prevData => ({
          ...prevData,
          [name]: { 
            value: checked,
          },
        }));
      } else {
        // For other inputs, e.g., domain_model selection
        setData(prevData => ({
          ...prevData,
          [name]: { 
            value: value,
          },
          domain_model: {
            value: prevData.domain_model.value.includes(value)
              ? prevData.domain_model.value.filter(item => item !== value)
              : [...prevData.domain_model.value, value],
          },
        }));
      }
    
      // Update selectedBoxes (if relevant to the current input)
      /*
      if (name === 'domain_model') {
        setSelectedBoxes(prevSelectedBoxes => {
          return prevSelectedBoxes.includes(value)
            ? prevSelectedBoxes.filter(item => item !== value)
            : [...prevSelectedBoxes, value];
        });
      }
      */

        /*
        setData(prevData => ({
        ...prevData,
        [name]: value,
        visibility: true, // name === 'visibility' ? e.target.checked : prevData.visibility,
        adoption: name === 'adoption' ? e.target.checked : prevData.adoption,
        share: name === 'share' ? e.target.checked : prevData.share,
        semantic_model: true,
        //domain_model: name === 'domain_model' ? value : prevData.domain_model,
        domain_model: selectedBoxes.includes(value) 
            ? selectedBoxes.filter(item => item !== value)
            : [...selectedBoxes, value],
        }));
        */
    };

    const handleMetaData = () => {
        setMetaData(!metaData);
    }

    const handleShowMetaInfo = () => {
        setShowMetaInfo(!showMetaInfo)
    }

    return (
        <Modal show={true} onHide={onClose} placement="end">
            <Modal.Header closeButton>
                <Modal.Title>Save Use Case to Repository</Modal.Title> {/* Association Guide */}
            </Modal.Header>
            <Modal.Body>       
                <Row className="justify-content-center text-center">
                    <Col>
                        <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '50%', height: 'auto' }}/>        
                        {isEdit || ucIsStored ? 
                        <>
                            <p style={ { marginBottom:'30px'} }><b>Awesome - you have now edited {uc_name?.trim ? uc_name : 'the use case'} </b></p> 
                            
                            <Button
                                variant="outline-primary"
                                onClick={() => {
                                    handleMetaData();
                                }}
                            >
                                <i className="bi bi-pen"></i> Update meta data
                            </Button>

                            <MetaDataComponent 
                                metaData={metaData}
                                handleShowMetaInfo={handleShowMetaInfo}
                                handleInputChange={handleInputChange}
                                data={data}
                                showMetaInfo={showMetaInfo}
                            />

                            <OverlayTrigger placement="top" overlay={<Tooltip>Save the use case in the repository.</Tooltip>}>
                                <Button 
                                    variant="success" 
                                    onClick={() => {
                                        onClose();
                                        handleSubmitEdit();
                                    }}
                                    >
                                    <i className="bi bi-save"></i> Save Update
                                </Button>
                            </OverlayTrigger>
                        </>
                            : 
                        <>
                            <p style={ { marginBottom:'30px'} }><b>Awesome - you have now created {uc_name?.trim() ? uc_name : 'a new use case'} </b></p>

                            <MetaDataComponent 
                                metaData={true}
                                handleShowMetaInfo={handleShowMetaInfo}
                                handleInputChange={handleInputChange}
                                data={data}
                                showMetaInfo={showMetaInfo}
                            />
                            
                            <OverlayTrigger placement="top" overlay={<Tooltip>Save the use case in the repository.</Tooltip>}>
                                <Button 
                                    style={ { minHeight:'50px', minWidth:'50px' } }
                                    variant="success" 
                                    onClick={() => {
                                        onClose();
                                        handleSubmitSave();
                                        setUCIsStored(true);
                                    }}
                                    >
                                    <i className="bi bi-floppy"></i> Save Use Case
                                </Button>
                            </OverlayTrigger>
                        </>
                        }
                    </Col>
                </Row> 
            </Modal.Body>
            <Modal.Footer>
                <OverlayTrigger placement="top" overlay={<Tooltip>Continue modeling without saving</Tooltip>}>
                    <Button variant="outline-secondary" onClick={onClose} style={ { marginLeft:'15px'} }>
                        <i className="bi bi-arrow-left"></i> Go Back
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>Close the Creator without saving.</Tooltip>}>
                <Button 
                    variant="outline-danger" 
                    onClick={() => {
                        navigateUCM();
                        onClose();
                    }}
                >
                    <i className="bi bi-trash"></i>
                </Button>
                </OverlayTrigger>
            </Modal.Footer>
        </Modal>
    );
};

export default ModelModalSave;