/**
 * Survey: Question Set (QS) Intro
 *
 * Description: The QS Intro provides initial questions for the expert to answer
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-13 (updated on Oct 15)
 */

import React from 'react';
import { Row, Form } from 'react-bootstrap';

import { getCurrentDateTime } from '../../utils/utils_date';

export const SurveyQSIntro = ( { surveyData, setSurveyData, handleChange, currentStep, studyExpertData } ) => {
    
    const getConfidenceLabel = (value) => {
        switch (value) {
            case '0':
                return 'Not confident';
            case '1':
                return 'Not really confident';
            case '2':
                return 'Somewhat confident';
            case '3':
                return 'Confident';
            case '4':
                return 'Very confident';
            default:
                return 'Somewhat confident';
        }
    };
    
    return (
        <>
            {/* Question 1: Understanding of the Approach */}
            {currentStep === 1 && (
                <Row align="center">

                    <h3 style={{ marginTop: '30px', marginBottom: '20px' }}>About You and this Research Study</h3>
                    <p style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
                        What's your overall individual perception about the tool and study? And: 
                        How useful do you find the proposed use case modeling approach for your field or expertise?
                    </p>

                    {/* Feeling: Emoji Rating */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.feeling.expert_feeling.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px', margin: '0 auto', marginTop:'30px' }}>
                            <span
                            role="img"
                            aria-label="angry"
                            style={{
                                fontSize: '2rem',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '30%',
                                border: surveyData.feeling.expert_feeling.answer === 'angry' ? '2px solid #000' : '2px solid transparent',
                                transition: 'border-color 0.2s ease',
                            }}
                            onClick={() => setSurveyData((prev) => ({ 
                                ...prev, 
                                feeling: { 
                                  ...prev.feeling, 
                                  expert_feeling: { 
                                    ...prev.feeling.expert_feeling, 
                                    answer: 'angry',
                                    anonymous_id: studyExpertData?.anonymous_id ?? '',
                                    created_timestamp: getCurrentDateTime(),
                                  } 
                                } 
                            }))}
                            >
                            😡
                            </span>
                            <span
                            role="img"
                            aria-label="sad"
                            style={{
                                fontSize: '2rem',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '30%',
                                border: surveyData.feeling.expert_feeling.answer === 'sad' ? '2px solid #000' : '2px solid transparent',
                                transition: 'border-color 0.2s ease',
                            }}
                            onClick={() => setSurveyData((prev) => ({
                                ...prev,
                                feeling: {
                                    ...prev.feeling,
                                    expert_feeling: {
                                        ...prev.feeling.expert_feeling,
                                        answer: 'sad',
                                        anonymous_id: studyExpertData?.anonymous_id ?? '',
                                        created_timestamp: getCurrentDateTime(),
                                    }
                                }
                            }))}
                            >
                            😕
                            </span>
                            <span
                            role="img"
                            aria-label="neutral"
                            style={{
                                fontSize: '2rem',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '30%',
                                border: surveyData.feeling.expert_feeling.answer === 'neutral' ? '2px solid #000' : '2px solid transparent',
                                transition: 'border-color 0.2s ease',
                            }}
                            onClick={() => setSurveyData((prev) => ({
                                ...prev,
                                feeling: {
                                    ...prev.feeling,
                                    expert_feeling: {
                                        ...prev.feeling.expert_feeling,
                                        answer: 'neutral',
                                        anonymous_id: studyExpertData?.anonymous_id ?? '',
                                        created_timestamp: getCurrentDateTime(),
                                    }
                                }
                            }))}
                            >
                            😐
                            </span>
                            <span
                            role="img"
                            aria-label="happy"
                            style={{
                                fontSize: '2rem',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '30%',
                                border: surveyData.feeling.expert_feeling.answer === 'happy' ? '2px solid #000' : '2px solid transparent',
                                transition: 'border-color 0.2s ease',
                            }}
                            onClick={() => setSurveyData((prev) => ({
                                ...prev,
                                feeling: {
                                    ...prev.feeling,
                                    expert_feeling: {
                                        ...prev.feeling.expert_feeling,
                                        answer: 'happy',
                                        anonymous_id: studyExpertData?.anonymous_id ?? '',
                                        created_timestamp: getCurrentDateTime(),
                                    }
                                }
                            }))}
                            >
                            🙂
                            </span>
                            <span
                            role="img"
                            aria-label="very happy"
                            style={{
                                fontSize: '2rem',
                                cursor: 'pointer',
                                padding: '10px',
                                borderRadius: '30%',
                                border: surveyData.feeling.expert_feeling.answer === 'very happy' ? '2px solid #000' : '2px solid transparent',
                                transition: 'border-color 0.2s ease',
                            }}
                            onClick={() => setSurveyData((prev) => ({
                                ...prev,
                                feeling: {
                                    ...prev.feeling,
                                    expert_feeling: {
                                        ...prev.feeling.expert_feeling,
                                        answer: 'very happy',
                                        anonymous_id: studyExpertData?.anonymous_id ?? '',
                                        created_timestamp: getCurrentDateTime(),
                                    }
                                }
                            }))}
                            >
                            😀
                            </span>
                        </div>
                    </Form.Group>

                    {/* Expertise Level 
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.feeling.expert_work_level.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px', margin: '0 auto', marginTop:'30px' }}>
                        <Form.Control 
                            as="select" 
                            name="expert_work_level" 
                            value={surveyData.feeling.expert_work_level.answer}
                            onChange={handleChange}
                        >
                            <option value="">Select</option>
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Expert">Advanced</option>
                        </Form.Control>
                        </div>
                    </Form.Group>
                    */}

                    {/* Role abstraction level (conceptual, strategic, operational, ...) 
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.feeling.expert_work_layer.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px', margin: '0 auto', marginTop:'30px' }}>
                        <Form.Control 
                            as="select" 
                            name="expert_work_layer" 
                            value={surveyData.feeling.expert_work_layer.answer} 
                            onChange={handleChange}
                        >
                            <option value="">Select</option>
                            <option value="Beginner">Conceptional</option>
                            <option value="Intermediate">Strategic</option>
                            <option value="Making">Execution</option>
                            <option value="Expert">Operational</option>
                        </Form.Control>
                        </div>
                    </Form.Group>
                    */}

                    {/* Expertise Level */}
                    <Form.Group style={{ marginTop: '40px', marginBottom: '40px' }}>
                        <Form.Label><b>{surveyData.feeling.expert_work_level.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '700px', margin: '0 auto', marginTop:'30px' }}>
                            {['Beginner', 'Intermediate', 'Advanced'].map((level) => (
                                <Form.Check 
                                    type="radio"
                                    label={level}
                                    name="expert_work_level"
                                    value={level}
                                    key={level}
                                    checked={surveyData.feeling.expert_work_level.answer === level}
                                    onChange={handleChange}
                                    style={{ marginBottom: '10px' }}
                                />
                            ))}
                        </div>
                    </Form.Group>

                    {/* Role Abstraction Level (conceptual, strategic, operational, ...) */}
                    <Form.Group style={{ marginTop: '40px', marginBottom: '40px' }}>
                        <Form.Label><b>{surveyData.feeling.expert_work_layer.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '700px', margin: '0 auto', marginTop:'30px' }}>
                            {['Conceptional', 'Strategic', 'Execution', 'Operational'].map((layer) => (
                                <Form.Check 
                                    type="radio"
                                    label={layer}
                                    name="expert_work_layer"
                                    value={layer}
                                    key={layer}
                                    checked={surveyData.feeling.expert_work_layer.answer === layer}
                                    onChange={handleChange}
                                    style={{ marginBottom: '10px' }}
                                />
                            ))}
                        </div>
                    </Form.Group>


                    {/* Slider for Importance/Impact */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.feeling.expert_confidence.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', maxWidth: '500px' }}>
                            <span style={{ marginRight: '10px', fontSize: '12px', color: '#ff4d4d' }}>Not confident</span>
                            <Form.Control 
                                type="range" 
                                name="expert_confidence" 
                                min="0" 
                                max="4"
                                step="1"
                                value={surveyData.feeling.expert_confidence.answer ?? 2}
                                onChange={handleChange} 
                                style={{ 
                                    background: 'linear-gradient(90deg, #ff4d4d, #28a745)',
                                    borderRadius: '10px',
                                    width: '100%',
                                }}
                            />
                            <span style={{ marginLeft: '10px', fontSize: '12px', color: '#28a745' }}>Very confident</span>
                        </div>

                        {/* Display current slider value dynamically below the slider */}
                        <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', fontWeight: 'bold' }}>
                            {getConfidenceLabel(surveyData.feeling.expert_confidence.answer)}
                        </div>
                    </Form.Group>

                </Row>
                )}
    </>
    );
}