/**
 * Delete Element
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: (updated on 2024-10-09)
 * 
 */

import { getCurrentDate } from '../utils/utils_date';
import { UserInformation } from '../auth_mgmt/auth_handling';

export const handleDeleteElement = (sectionName, itemTitle, setData, setDroppedItems) => {

    // Update the droppedItems state
    setDroppedItems(prev => {
        // Ensure the sectionName exists in prev
        if (!prev[sectionName]) {
            console.error(`Section ${sectionName} does not exist in state.`);
            return prev;
        }
        return {
            ...prev,
            [sectionName]: prev[sectionName].filter(title => title !== itemTitle),
        };
    });

    // update the data object as well
    /*
    setData({
        ...data,
        components: data.components.value.map(component => {
            return {
                ...component,
                components: component.components.map(subComponent => {
                    return {
                        ...subComponent,
                        items: subComponent.items.filter(item => item !== itemTitle),
                    };
                }),
            };
        }),
    });
    */

    // Update the data object
    setData(prevData => {
        // Find and update the corresponding component category and subcategory
        const updatedComponents = prevData.components.value.map(componentCategory => {
            return {
                ...componentCategory,
                components: componentCategory.components.map(subComponent => {
                    if (subComponent.description === sectionName) {
                        return {
                            ...subComponent,
                            items: subComponent.items.filter(item => item !== itemTitle),
                        };
                    }
                    return subComponent;
                }),
            };
        });

        return {
            ...prevData,
            components: {
                ...prevData.components,
                value: updatedComponents,
                modified: true,
                updated_timestamp: getCurrentDate(),
                updated_by: UserInformation().displayName,
                uid: UserInformation().uid,
                version: prevData.components.version + 1,  // Increment the version
            }
        };
    });

};