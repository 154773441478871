/**
 * Modal
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-17
 * 
 */

import React from 'react';
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Papierboot from '../../assets/img/Papierboot.png';

const InfoModal = ({ onClose, info }) => {

    const navigate = useNavigate();
    const navigateApproach = () => {
        navigate('/Approach');
    };

    const navigateAbout = () => {
        navigate('/About');
    }

    return (
        <Modal show={true} onHide={onClose} placement="end">
            <Modal.Header closeButton>
                <Modal.Title>Explanation</Modal.Title> {/* Association Guide */}
            </Modal.Header>
            <Modal.Body>       
                <Row className="justify-content-center text-center">
                    <Col>
                        <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '20%', height: 'auto' }}/>        
                        <p>{info}</p>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Get to know more about this tool's purpose.</Tooltip>}>
                        <Button variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={navigateAbout}>
                            <i className="bi bi-clipboard"></i> About
                        </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Learn more about this research and the underlying approach.</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={navigateApproach}>
                            <i className="bi bi-bullseye"></i>  Approach
                        </Button>
                        </OverlayTrigger>        
                    </Col>
                </Row>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onClose}>
                    Go back
                </Button>
            </Modal.Footer>
        </Modal>
      );
    };

export default InfoModal;