/**
 * Load bar component for the login page
 * 
 * Description: Used for instance when waiting for a response from the server
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-07
 * 
 */

import React from 'react';
import { Spinner, Container, Row, Col } from 'react-bootstrap';

const Loading = () => {
  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <Row>
        <Col xs={12} className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
};

export default Loading;
