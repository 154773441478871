/**
 * Prompt page
 *
 * Description: This module contains functions to generate prompts for the chat.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-23
 * 
 */

import React from 'react';
import { Container } from 'react-bootstrap';
import PromptGenerator from '../components/guide/prompt_generator';

export default function Prompts(props) {

  return (
    <div align='center'>
      <Container fluid>
        <h4>UCM Prompt Generator</h4>
        <PromptGenerator />
      </Container>
    </div>
  );
};

export { default as Prompts } from './Prompts';