/**
 * Modal Add Component
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: (updated on 2024-10-09)
 * 
 */

import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddComponentModal = ({ show, onHide, onAddComponent }) => {

    const [newComponentLabel, setNewComponentLabel] = useState('');

    const handleAddComponent = () => {
        if (newComponentLabel) {
            onAddComponent(newComponentLabel);
            setNewComponentLabel('');
            onHide();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission
          handleAddComponent();
        }
    };
  
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Component Element</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewNodeLabel">
                <div style={ { marginBottom: '10px' } }> 
                    <Form.Label>Component Label</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter node label"
                        value={newComponentLabel}
                        onChange={(e) => setNewComponentLabel(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </Form.Group>            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddComponent}>
            Add Element
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default AddComponentModal;