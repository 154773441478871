
import Container from 'react-bootstrap/Container';

import UseCaseForm from '../components/uc_handling/uc_form';

export default function Create () {

    return (
      <div align='center'>
        <Container fluid>
          <UseCaseForm />          
        </Container>
      </div>
    )
}

export { default as Create } from './Create';