/**
 * ...
 *
 * Description: ...
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-16
 * 
 */

import EcoAnalytics from '../components/eco_analytics_handling/eco_analytics';

import React from 'react';
import { Container } from 'react-bootstrap';

export default function Analytics () {

  return (
    <div align='center'>
      <Container fluid>
        <h4>Analytics - Ecosystem Mapper</h4>
        <EcoAnalytics />
      </Container>
    </div>
  );
};

export { default as Analytics } from './Analytics';