/**
 * Network Analysis Component
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-06
 * 
 */

import React, { useEffect, useRef, useState } from 'react';
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import * as vis from 'vis-network/standalone';

const NetworkAnalysis = ({ useCases }) => {
    const networkRef = useRef(null);
    const [network, setNetwork] = useState(null);
    const [loading, setLoading] = useState(false);
    const [originalNodes, setOriginalNodes] = useState([]);
    const [originalEdges, setOriginalEdges] = useState([]);

    const resetNetwork = (nodes, edges) => {
        if (network) {
            setLoading(true);
            const data = { nodes, edges };
            network.setData(data);
            setLoading(false);
        }
    };

    useEffect(() => {
        const createNetwork = () => {
            setLoading(true);

            const nodes = [];
            const edges = [];
            const aggregatedActors = {};
            const aggregatedComponents = {};

            // Aggregate Actors and Components across Use Cases
            useCases.forEach((useCase) => {
                const actorCategories = useCase.actors?.value?.list;
                if (actorCategories) {
                    Object.keys(actorCategories).forEach((category) => {
                        const actorsInCategory = actorCategories[category]?.value || [];
                        actorsInCategory.forEach((actor) => {
                            if (aggregatedActors[category]) {
                                aggregatedActors[category].count += 1;
                            } else {
                                aggregatedActors[category] = { label: category, count: 1 };
                            }
                        });
                    });
                }

                const componentsData = useCase.components?.value || [];
                componentsData.forEach((componentCategory) => {
                    const category = componentCategory.category;
                    if (aggregatedComponents[category]) {
                        aggregatedComponents[category].count += componentCategory.components.length;
                    } else {
                        aggregatedComponents[category] = { label: category, count: componentCategory.components.length };
                    }
                });
            });

            // Create aggregated nodes for actor categories
            Object.keys(aggregatedActors).forEach((category, index) => {
                nodes.push({
                    id: `actor_category_${index}`,
                    label: `${category} (${aggregatedActors[category].count})`,
                    group: 'actor',
                    title: `Category: ${category}\nOccurrences: ${aggregatedActors[category].count}`,
                    shape: 'circle',
                    color: 'rgba(75,192,192,0.6)',
                });
            });

            // Create aggregated nodes for component categories
            Object.keys(aggregatedComponents).forEach((category, index) => {
                nodes.push({
                    id: `component_category_${index}`,
                    label: `${category} (${aggregatedComponents[category].count})`,
                    group: 'component',
                    title: `Category: ${category}\nOccurrences: ${aggregatedComponents[category].count}`,
                    color: 'rgba(255,99,132,0.6)',
                    shape: 'box',
                });
            });

            setOriginalNodes(nodes);
            setOriginalEdges(edges);

            const container = networkRef.current;
            const data = { nodes, edges };
            const options = {
                nodes: {
                    borderWidth: 2,
                    size: 20,
                    font: { size: 14 },
                },
                interaction: { hover: true, zoomView: true },
                physics: {
                    stabilization: true,
                    barnesHut: {
                        gravitationalConstant: -30000,
                        centralGravity: 0.3,
                        springLength: 95,
                        springConstant: 0.04,
                    },
                },
                layout: { improvedLayout: false },
            };

            const newNetwork = new vis.Network(container, data, options);
            setNetwork(newNetwork);

            // Expand cluster on click
            newNetwork.on("doubleClick", function (params) {
                if (params.nodes.length > 0) {
                    const nodeId = params.nodes[0];
                    if (newNetwork.isCluster(nodeId)) {
                        newNetwork.openCluster(nodeId);
                    }
                }
            });

            newNetwork.on('stabilized', () => {
                setLoading(false);
            });

            return () => {
                newNetwork.destroy(); // Cleanup on component unmount
            };
        };

        createNetwork();
    }, [useCases]);

    // Cluster actors based on occurrence count
    const handleClusterByCount = () => {
        if (network) {
            resetNetwork(originalNodes, originalEdges);
            setLoading(true);

            const nodes = network.body.data.nodes.get();
            const clusterCounts = {};

            nodes.forEach((node) => {
                if (node.group === 'actor' && node.label.includes('(')) {
                    const count = parseInt(node.label.match(/\((\d+)\)/)[1], 10);
                    if (count > 1) {
                        if (!clusterCounts[count]) {
                            clusterCounts[count] = [];
                        }
                        clusterCounts[count].push(node.id);
                    }
                }
            });

            Object.keys(clusterCounts).forEach((count) => {
                const clusterOptionsByData = {
                    joinCondition: function (nodeOptions) {
                        return clusterCounts[count].includes(nodeOptions.id);
                    },
                    processProperties: function (clusterOptions, childNodes) {
                        const totalActors = childNodes.length;
                        clusterOptions.label = `Cluster: ${totalActors} Actors (${count} Times)`;
                        clusterOptions.title = `Cluster of ${totalActors} actors appearing ${count} times`;
                        return clusterOptions;
                    },
                    clusterNodeProperties: {
                        borderWidth: 3,
                        shape: 'circle',
                        font: { size: 16, bold: true },
                    },
                };
                network.cluster(clusterOptionsByData);
            });

            setLoading(false);
        }
    };

    // Highlight the actor with the most connections
    const handleShowMostConnectedActor = () => {
        if (network) {
            resetNetwork(originalNodes, originalEdges);
            setLoading(true);

            network.once('stabilizationIterationsDone', () => {
                const nodes = network.body.data.nodes.get();
                let mostConnections = 0;
                let mostConnectedNode = null;

                nodes.forEach((node) => {
                    const connectedEdges = network.getConnectedEdges(node.id);
                    if (connectedEdges.length > mostConnections) {
                        mostConnections = connectedEdges.length;
                        mostConnectedNode = node.id;
                    }
                });

                if (mostConnectedNode) {
                    network.selectNodes([mostConnectedNode]);
                    network.focus(mostConnectedNode, {
                        scale: 1.5,
                        animation: {
                            duration: 1000,
                            easingFunction: 'easeInOutQuad',
                        },
                    });
                }

                setLoading(false);
            });

            network.stabilize();
        }
    };

    // Show the aggregated overview of actors and components
    const handleShowAggregatedOverview = () => {
        resetNetwork(originalNodes, originalEdges);
    };

    // Search functionality
    const handleSearch = (searchTerm) => {
        const nodeId = network.body.data.nodes.getIds().find((id) => {
            const node = network.body.data.nodes.get(id);
            return node.label.toLowerCase().includes(searchTerm.toLowerCase());
        });

        if (nodeId) {
            network.focus(nodeId, { scale: 1.5, animation: { duration: 1000 } });
            network.selectNodes([nodeId]);
        }
    };

    // Zoom controls
    const handleZoomIn = () => {
        const currentScale = network.getScale();
        network.moveTo({ scale: currentScale * 1.2 });
    };

    const handleZoomOut = () => {
        const currentScale = network.getScale();
        network.moveTo({ scale: currentScale * 0.8 });
    };

    // Fit network
    const handleFitNetwork = () => {
        network.fit({ animation: true });
    };

    return (
        <div className="network-wrapper">
            <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '15px' }}>
                {/* Cluster Controls */}
                <div>
                    <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleClusterByCount}>
                        Cluster Reoccurring Actors
                    </Button>
                    <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleShowMostConnectedActor}>
                        Show Most Connected Actor
                    </Button>
                    <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleShowAggregatedOverview}>
                        Show Aggregated Overview
                    </Button>
                </div>
                {/* Search and Zoom Controls */}
                <div>
                    {/* Search Box */}
                    <input
                        type="text"
                        placeholder="Search actors or components..."
                        onChange={(e) => handleSearch(e.target.value)}
                        style={{ padding: '5px', width: '300px', borderRadius: '5px', border: '1px solid #ddd', marginRight: '10px' }}
                    />
                    {/* Zoom Controls */}
                    <OverlayTrigger placement="top" overlay={<Tooltip>Zoom In</Tooltip>}>
                        <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleZoomIn}>
                            <i className="bi bi-zoom-in"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Zoom Out</Tooltip>}>
                        <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleZoomOut}>
                            <i className="bi bi-zoom-out"></i>
                        </Button>
                    </OverlayTrigger>
                    {/* Fit Network */}
                    <OverlayTrigger placement="top" overlay={<Tooltip>Fit to Screen</Tooltip>}>
                        <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleFitNetwork}>
                        <i className="bi bi-arrows-fullscreen"></i>
                        </Button>
                    </OverlayTrigger>
                    {/* Reset Network */}
                    <OverlayTrigger placement="top" overlay={<Tooltip>Reset Network</Tooltip>}>
                    <Button variant="outline-secondary" className="btn-sm me-2" onClick={handleShowAggregatedOverview}>
                        <i className="bi bi-arrow-counterclockwise"></i>
                    </Button>
                    </OverlayTrigger>
                </div>
            </div>

            {/* Network Container with Loading Spinner */}
            <div style={{ position: 'relative', height: '600px' }}>
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1000,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            padding: '20px',
                            borderRadius: '50%',
                        }}
                    >
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )}
                <div
                    ref={networkRef}
                    className="network-container"
                    style={{ height: '100%', border: '2px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}
                />
            </div>

            {/* Legend */}
            <div className="legend mt-3" align='right'>
                <h5 align='right'>Legend:</h5>
                <div align='right'>
                    <span
                        style={{
                            backgroundColor: 'rgba(75,192,192,0.6)',
                            padding: '5px',
                            borderRadius: '5px',
                            display: 'inline-block',
                            width: '20px',
                            marginRight: '10px',
                        }}
                    /> Actors (Count in brackets)
                </div>
                <div align='right'>
                    <span
                        style={{
                            backgroundColor: 'rgba(255,99,132,0.6)',
                            padding: '5px',
                            borderRadius: '5px',
                            display: 'inline-block',
                            width: '20px',
                            marginRight: '10px',
                        }}
                    /> Components
                </div>
                <div>
                    <span
                        style={{
                            backgroundColor: 'rgba(153,102,255,0.8)',
                            padding: '5px',
                            borderRadius: '5px',
                            display: 'inline-block',
                            width: '20px',
                            marginRight: '10px',
                        }}
                    /> Connections
                </div>
            </div>
        </div>
    );
};


export default NetworkAnalysis;
