/**
 * Database Management - Read interview data
 *
 * Description: This module contains functions to read data from the Firestore database.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-20
 * 
 */

import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../auth_mgmt/auth_config';

//import { doc, getDoc } from 'firebase/firestore';

const db_name = 'beta_interview';

/**
 * Reads the use case data
 * @returns 
 */
const fetchInterviewData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, db_name));
      const interviews = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched interview data: ', interviews);
      return interviews;
    } catch (error) {
      console.error('Error fetching interview data: ', error);
    }
  };

export { fetchInterviewData };