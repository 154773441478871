import React from 'react';

const JourneyBar = ({ steps, currentStep, onStepClick, copilotSteps }) => {
    const stepWidth = 100 / steps.length;

    return (
        <div className="journey-bar" style={{ display: 'flex' }}>
            {steps.map((step, index) => (
                <div
                    key={index}
                    className={`journey-bar-step ${index === currentStep ? 'active' : ''}`}
                    onClick={() => onStepClick(index)}
                    style={{
                        width: `${stepWidth}%`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        padding: '10px 20px',
                        boxSizing: 'border-box', // Ensure padding is included in width calculation
                    }}
                >
                    <span style={{ zIndex: 1, whiteSpace: 'nowrap' }}>{index + 1}. {step}</span>
                    {copilotSteps.includes(index) && (
                        <div style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: 'purple',
                            border: '0.5px solid #ffffff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            zIndex: 2,
                        }}>
                            <i className="bi bi-activity"></i>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};



export default JourneyBar;