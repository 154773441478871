/**
 * Auth Key Handling Module
 *
 * Description: Enter a key to access the system.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-07
 * 
 */

// KeyEntry.js
import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './auth_config';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

const KeyEntry = ( { onKeyVerified } ) => {

  const [key, setKey] = useState('');
  const [error, setError] = useState('');
  //const navigate = useNavigate();

  const handleKeySubmit = async (e) => {
    e.preventDefault();
    try {
        const querySnapshot = await getDocs(collection(db, 'auth_keys'));

        // Check if any document contains the entered key
        let isValidKey = false;
        querySnapshot.forEach((doc) => {
            if (doc.data().key === key) {
                isValidKey = true;
            }
        });
        
        if (isValidKey) {
            onKeyVerified();
            //console.log('Key verified');
        } else {
            setError('Invalid key.');
        }
        } catch (err) {
        setError('Error checking key.');
        console.error(err);
        }
  };

return (
  <Container className="d-flex align-items-center justify-content-center min-vh-100">
    <Row>
      <Col xs={12}>
        <div className="d-flex flex-column align-items-center">
          <h4 className="mb-4">Enter Authentication Key</h4>
          <Form onSubmit={handleKeySubmit} style={{ maxWidth: '300px', width: '100%' }}>
            <Form.Group controlId="formBasicKey" className="d-flex">
              <Form.Control
                type="text"
                placeholder="Enter Key"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                required
                className="me-2"
              />
            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form.Group>
            {error && (
                <Alert variant="danger" className="mt-3" style={{ padding: '5px 10px', fontSize: '14px' }}>
                    {error}
                </Alert>
            )}
          </Form>
        </div>
      </Col>
    </Row>
  </Container>
);
};

export default KeyEntry;
