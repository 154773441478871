



import React from 'react';
import { Document, Page, Text, View, PDFViewer, Image } from '@react-pdf/renderer';
import { styles } from './uc_pdf_styles';

import Papierboot from '../../assets/img/Papierboot.png';
import { getCurrentDate } from '../utils/utils_date';

// Utility function to format timestamp
const formatTimestamp = (timestamp) => {
  if (!timestamp || !timestamp.seconds) return '';
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const ActorListTablePDF = ({ list }) => {

  const categories = Object.keys(list);

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>Category</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>Actors</Text>
        </View>
      </View>
      {categories.map((category, index) => (
        <View style={styles.tableRow} key={index}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{category}</Text>
          </View>
          <View style={styles.tableCol}>
            {list[category].value.map((actor, i) => (
              <Text key={i} style={styles.tableCell}>
                {actor}
              </Text>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
};

/*
const ComponentListTablePDF = ({ components }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>Category</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>Component</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>Items</Text>
        </View>
      </View>
      {components.map((componentCategory, index) => (
        <React.Fragment key={index}>
          {componentCategory.components.map((component, i) => (
            <View style={styles.tableRow} key={`${index}-${i}`}>
              {i === 0 && (
                <View style={[styles.tableCol, { rowSpan: componentCategory.components.length }]}>
                  <Text style={styles.tableCell}>{componentCategory.category}</Text>
                </View>
              )}
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{component.description || component.id}</Text>
              </View>
              <View style={styles.tableCol}>
                {component.items && component.items.map((item, j) => (
                  <Text key={j} style={styles.tableCell}>
                    {item}
                  </Text>
                ))}
              </View>
            </View>
          ))}
        </React.Fragment>
      ))}
    </View>
  );
};
*/

export const ReportPDF = ({ selectedUseCase, comparisonData, stats, matchingScore, handleChartHover }) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>

        {/* Header */}
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{selectedUseCase.title.value}</Text>
            <Text style={styles.subtitle}>Use Case Report</Text>
          </View>
          <Image src={Papierboot} style={styles.logo} />
        </View>

        <View style={styles.section}>
          <Text style={styles.text_sm}>Generated by UCM on {getCurrentDate()}</Text>
          <Text style={styles.text_sm}>UCM can make mistakes. Verify important information.</Text>
        </View>
        <View style={styles.line}></View>

        {/* Use Case Insights - General Statistics */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Use Case Score</Text>
          <View style={styles.columns}>
            <View style={styles.column}>
              <View style={styles.statisticCard}>
                <Text style={styles.number_lg}>{matchingScore}</Text>
                <Text style={styles.text}>Matching Score</Text>
              </View>
            </View>
            <View style={styles.column}>
              <Text style={styles.text_sm}>What is the Matching Score?</Text>
              <Text style={styles.text_sm}>
                The matching score indicates how well the selected use case matches with the ecosystem. The score is calculated based on the similarity of actors and components.</Text>
            </View>
            <View style={styles.column}>
              <View style={styles.statisticCard}>
                <Text style={styles.number_lg}>{comparisonData.length}</Text>
                <Text style={styles.text}>Total Use Cases</Text>
              </View>          
            </View>
          </View>            
          <View style={styles.columns}>
            <View style={styles.column}>
              <View style={styles.statisticCard}>
                <Text style={styles.number_lg}>{stats.similar}</Text>
                <Text style={styles.text}>Similar Use Cases</Text>                       
              </View>
            </View>
            <View style={styles.column}>
                <View style={styles.statisticCard}>
                  <Text style={styles.number_lg}>{stats.distinct}</Text>
                  <Text style={styles.text}>Distinct Use Cases</Text>
                </View>
            </View>
            <View style={styles.column}>
                <View style={styles.statisticCard}>                  
                  <Text style={styles.number_lg}>{stats.overlapping}</Text>
                  <Text style={styles.text}>Overlapping Use Cases</Text>
                </View>
            </View>
          </View>
    
        </View>

        {/* Use Case Insights - General Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Use Case Details</Text>    

          <View style={styles.columns}>
            <View style={styles.column}>
              <View style={styles.statisticCard}>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Acronym:</Text> {selectedUseCase.acronym.value}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Application:</Text> {selectedUseCase.application.value}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Status:</Text> {selectedUseCase.status.value}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Maturity:</Text> {selectedUseCase.maturity.value}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}></Text>---</Text>
              </View>
            </View>
            <View style={styles.column}>
              <View style={styles.statisticCard}>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Created by:</Text> {selectedUseCase.created_by.value}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Date Created:</Text> {formatTimestamp(selectedUseCase.created.value)}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Date Updated:</Text> {formatTimestamp(selectedUseCase.updated.value)}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>UID:</Text> {selectedUseCase.ucid.value}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}></Text>---</Text>
              </View>
            </View>
            <View style={styles.column}>
              <View style={styles.statisticCard}>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Visibility:</Text> {selectedUseCase.visibility.value ? 'Yes' : 'No'}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Adoption:</Text> {selectedUseCase.adoption.value ? 'Yes' : 'No'}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Share:</Text> {selectedUseCase.share.value ? 'Yes' : 'No'}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Semantic model:</Text> {selectedUseCase.semantic_model.value ? 'Yes' : 'No'}</Text>
                <Text style={styles.text_sm}><Text style={{ fontWeight: 'bold' }}>Domain model:</Text> {selectedUseCase.domain_model.value ? 'Yes' : 'No'}</Text>
              </View>
            </View>
          </View>

          <Text style={styles.text_sm}>---</Text>
          <Text style={styles.text}>Use Case Description</Text>
          <Text style={styles.text_sm}>{selectedUseCase.description.value}</Text>
          <Text style={styles.text}>---</Text>
          <Text style={styles.text}>Tags</Text>
          <Text style={styles.text_sm}>{selectedUseCase.tags.value.join(', ')}</Text>
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <View style={styles.titleContainer}>
              <Text style={styles.text_sm}>Copyright 2024 MGA (partially part of the author's PhD resarch at TU Berlin)</Text>
              <Text style={styles.text_sm}>Use Case Report</Text>
            </View>             
            <Text style={styles.text_sm}>Page 1</Text>
          </View>
        </View>

      </Page>

      {/* Page 2 */}
      <Page size="A4" style={styles.page}>

        {/* Header */}
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{selectedUseCase.title.value}</Text>
            <Text style={styles.subtitle}>Use Case Report</Text>
          </View>
          <Image src={Papierboot} style={styles.logo} />
        </View>

        {/* Use Case Insights - Classification */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Use Case Classification</Text>
          <Text style={styles.text}>Below charts show how the {selectedUseCase.title.value} classifies across all modeled use cases.</Text>
          
        </View>

        {/* Use Case Insights - Actors */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Actors</Text>
          <Text style={styles.text}>The modeled actors are assigned categories shown in the bar chart. Subsequent table provides further insights into how the actors distribute across modeled categories.</Text>
          <ActorListTablePDF list={selectedUseCase.actors.value.list} />
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <View style={styles.titleContainer}>
              <Text style={styles.text_sm}>Copyright 2024 MGA (partially part of the author's PhD resarch at TU Berlin)</Text>
              <Text style={styles.text_sm}>Use Case Report</Text>
            </View>             
            <Text style={styles.text_sm}>Page 2</Text>
          </View>
        </View>

      </Page>

      {/* Page 3 */}
      <Page size="A4" style={styles.page}>

        {/* Header */}
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{selectedUseCase.title.value}</Text>
            <Text style={styles.subtitle}>Use Case Report</Text>
          </View>
          <Image src={Papierboot} style={styles.logo} />
        </View>        

        {/* Use Case Insights - Components */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Components</Text>
          <Text style={styles.text}>The modeled components are assigned categories shown in the bar chart. Subsequent table provides further insights into how the components distribute across modeled categories.</Text>
          {/*<ComponentListTablePDF components={selectedUseCase.components} />*/}
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <View style={styles.titleContainer}>
              <Text style={styles.text_sm}>Copyright 2024 MGA (partially part of the author's PhD resarch at TU Berlin)</Text>
              <Text style={styles.text_sm}>Use Case Report</Text>
            </View>             
            <Text style={styles.text_sm}>Page 3</Text>
          </View>
        </View>

      </Page>

      {/* Page 4 */}
      <Page size="A4" style={styles.page}>

        {/* Header */}
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{selectedUseCase.title.value}</Text>
            <Text style={styles.subtitle}>Use Case Report</Text>
          </View>
          <Image src={Papierboot} style={styles.logo} />
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Use Case Comparison</Text>
          <Text style={styles.text}>Compare the selected use case against the ecosystem.</Text>
          {/* Add comparison details */}
        </View>

        {/* Footer */}
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <View style={styles.titleContainer}>
              <Text style={styles.text_sm}>Copyright 2024 MGA (partially part of the author's PhD resarch at TU Berlin)</Text>
              <Text style={styles.text_sm}>Use Case Report</Text>
            </View>             
            <Text style={styles.text_sm}>Page 4</Text>
          </View>
        </View>

      </Page>
    </Document>
  );
}


export const ReportPDFViewer = ({ selectedUseCase, comparisonData, stats, matchingScore, handleChartHover }) => (

    <PDFViewer style={styles.viewer}>
      <ReportPDF selectedUseCase={selectedUseCase} comparisonData={comparisonData} stats={stats} matchingScore={matchingScore} handleChartHover={handleChartHover} />
    </PDFViewer>
);