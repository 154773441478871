/**
 * Outlier Analysis
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-17
 * 
 */

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

export const UCOutlier = ({ data }) => {

    return (
        <>
        <Row style={ { marginTop:'20px'} }>
            <Col md={12}>
                <Row style={ { marginBottom:'20px'} }>
                    Outlier Analysis
                    <Col className="d-flex justify-content-end">
                    <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                        <i className="bi bi-info-circle"></i>
                    </Button>
                    </Col>
                </Row>     
            </Col>
        </Row>    
        {/* Actor relation analysis */}
        <Row style={ { marginBottom:'20px'} }>
            <Col md={12}>
                {/* Outlier analysis */}

            </Col>
        </Row>
        </>
    );
}