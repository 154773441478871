

import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        padding: 20,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    section: {
        marginTop: 20,
        marginBottom: 20,
    },
    sectionTitle: {
        fontSize: 18,
        borderBottom: '1px solid #ccc',
        paddingBottom: 5,
        marginBottom: 10,
        color: 'darkgray',
    },
    line: {
        width: '20%',
        borderBottom: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 30,
    },
    text: {
        marginBottom: 5,
        fontSize: 10,
    },
    text_sm: {
        marginBottom: 5,
        fontSize: 8,
    },
    number_lg: {
        marginBottom: 5,
        fontSize: 20,
    },
    statistics: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    statisticCard: {
        width: '100%',
        padding: 10,
        border: '1px solid #ccc',
        borderRadius: 5,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableColHeader: {
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        backgroundColor: '#f0f0f0',
        padding: 5,
    },
    tableCol: {
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        padding: 5,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 10,
        fontWeight: 'bold',
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
    },
    title: {
        fontSize: 24,
        width: '80%',
    },
    subtitle: {
        fontSize: 12,
        marginTop: 5,
    },
    logo: {
        width: 50,
        height: 'auto',
    },
    viewer: {
        width: '100%',
        height: '750px',
        border: '1px solid #ccc',
    },
    footer: {
        width: '100%',
        position: 'absolute',
        bottom: 20,
        left: 0,
        paddingHorizontal: 20, // Add padding to the sides
    },
    
    footerContent: {
        width: '100%',
        borderTop: '1px solid #ccc',
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    columns: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    column: {
        width: '30%',
    },
  });