/**
 * Study Modal Complete
 *
 * Description: Study completion modal
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-12
 */

import { Modal, Button } from 'react-bootstrap';
import ReactConfetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';


export const StudyModalComplete = ({ showModal, setShowModal }) => {

    const navigate = useNavigate();

    return (
        <>
        {/* Completion Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body className="text-center">
            <ReactConfetti 
                width={window.innerWidth} 
                height={window.innerHeight} 
                numberOfPieces={200} 
                recycle={false}
                gravity={0.1}
            />
            <h2>🎉 Thank You for Participating! 🎉</h2>
            <p>Your contribution is highly appreciated and will help advance research in this area.</p>
            <Button variant="success" 
                onClick={() => {
                    setShowModal(false)
                    navigate('/');
                }}>
                Exit study mode
            </Button>
        </Modal.Body>
        </Modal>
        </>
    );
};