/**
 * Search for a concept in the sidebar items array and expand all parent concepts towards it
 * Applied in the Ontology Tree visualization
 * Used for Component modeling
 * 
 * Description: This form provides the fields representing the use case information
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-12
 * 
 */

// Function to expand tree towards a searched concept
const expandTreeTowardsConcept = (searchedConcept, sidebarItems, setSidebarItems) => {
    
    // Find the concept in the sidebarItems array
    const foundConcept = findConceptInSidebarItems(searchedConcept, sidebarItems);
    //console.log('Found concept:', foundConcept);

    if (foundConcept) {
        // Expand all parent concepts towards the searched concept 
        const updatedSidebarItems = expandParentConcepts(foundConcept, sidebarItems);
        setSidebarItems(updatedSidebarItems);

        return true;
    } else {
        console.log(`Concept "${searchedConcept}" not found.`);
        return false;
    }
};
  
// Function to find a concept in the sidebarItems array
const findConceptInSidebarItems = (searchedConcept, sidebarItems) => {
    let foundConcept = null;

    // Recursive function to search for the concept
    const searchConcept = (concepts) => {
        for (const concept of concepts) {
        if (concept.title === searchedConcept) {
            foundConcept = concept;
            break;
        }
        if (concept.subconcepts) {
            searchConcept(concept.subconcepts);
        }
        }
    };

    searchConcept(sidebarItems);

    return foundConcept;
};

// Function to expand all parent concepts towards the searched concept
const expandParentConcepts = (searchedConcept, sidebarItems) => {

    const updateParentConcepts = (concepts, pathToSearchedConcept) => {
        return concepts.map((concept) => {
        const updatedConcept = { ...concept };
        // If the current concept matches the searched concept or is in the path to the searched concept, expand it
        if (concept === searchedConcept || pathToSearchedConcept.includes(concept)) {
            updatedConcept.collapsed = false;
        }
        // If the current concept has subconcepts, recursively update them and add the current concept to the path if it matches the searched concept
        if (concept.subconcepts) {
            const updatedSubconcepts = updateParentConcepts(concept.subconcepts, pathToSearchedConcept.concat(concept));
            updatedConcept.subconcepts = updatedSubconcepts;
        }
        return updatedConcept;
        });
    };

    // Find the path to the searched concept
    const findPathToSearchedConcept = (concepts, path = []) => {
        for (const concept of concepts) {
        if (concept === searchedConcept) {
            return path;
        }
        if (concept.subconcepts) {
            const foundPath = findPathToSearchedConcept(concept.subconcepts, path.concat(concept));
            if (foundPath) {
            return foundPath;
            }
        }
        }
        return null;
    };

    // Get the path to the searched concept
    const pathToSearchedConcept = findPathToSearchedConcept(sidebarItems);
    // If the searched concept is found, update the parent concepts
    if (pathToSearchedConcept) {
        return updateParentConcepts(sidebarItems, pathToSearchedConcept);
    }
    // If the searched concept is not found, return the original sidebar items
    return sidebarItems;
};

export { expandTreeTowardsConcept, findConceptInSidebarItems };