/**
 * Knowledge Representation View
 * 
 * Description: This view provides a visualization of the knowledge representation
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-20; adopted 2024-07-06
 * 
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OWLVisualization } from '../onto_handling/onto_vis';
import MatchingModule from '../onto_handling/onto_matching';

import { useNavigate } from 'react-router-dom';

import { OWLFileParser } from '../onto_handling/onto_parse';
import { PEAMONTOontologyURL, GUCOontologyURL } from '../onto_handling/onto_config';
import { buildSidebarItems } from '../onto_tree_handling/parse_items';
import { expandAllItems, collapseAllItems } from '../onto_tree_handling/expansion';
import { renderConceptBadges } from '../component_handling/render_concepts';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ModalCreateMode from '../create_handling/create_modal_mode';

import { comp_semantic_enrichment_guco, comp_semantic_enrichment_baseline, comp_semantic_enrichment_peamonto } from '../knowledge_handling/knwl_enrichment_structure';

export default function KnwlDashboard () {

    const isKnowledgeSite = true;
    const networkRef = useRef(null);
    const [currentLayout, setCurrentLayout] = useState('circle');

    // Define the state variable for the selected model
    const [sem_model, setSemModel] = useState('GUCO');
    const [searchTerm, setSearchTerm] = useState('Use Case');
    const [retrieveOWL, setRetrieveOWL] = useState(false);
    const [match, setMatch] = useState(false);

    // Handle change event
    const handleModelChange = (event) => {
        setSemModel(event.target.value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const layoutOptions = useMemo(() => ({
        normal: {
            physics: {
                enabled: true,
                solver: 'forceAtlas2Based',
                forceAtlas2Based: {
                    gravitationalConstant: -50,
                    centralGravity: 0.01,
                    springLength: 100,
                    springConstant: 0.08,
                    damping: 0.4,
                    avoidOverlap: 1,
                },
                stabilization: {
                    iterations: 200,
                },
            },
        },
        hierarchical: {
            layout: {
                hierarchical: {
                    enabled: true,
                    levelSeparation: 150,
                    nodeSpacing: 100,
                    treeSpacing: 200,
                    blockShifting: true,
                    edgeMinimization: true,
                    parentCentralization: true,
                    direction: 'UD', // UD, DU, LR, RL
                    sortMethod: 'hubsize', // hubsize, directed
                    shakeTowards: 'leaves' // roots, leaves
                },
            },
            physics: {
                enabled: false,
            },
        },
        grid: {
            layout: {
                improvedLayout: false,
            },
            physics: {
                enabled: false,
            },
        },
        circular: {
            layout: {
                improvedLayout: false,
                hierarchical: false,
            },
            physics: {
                enabled: false,
            },
        },
    }), []);

    const changeLayout = (layout) => {
        setCurrentLayout(layout);
        networkRef.current.setOptions(layoutOptions[layout]);
    };

    const handleZoomIn = () => {
        if (networkRef.current) {
            const scale = networkRef.current.getScale();
            networkRef.current.moveTo({
                scale: scale * 1.2, // Zoom in
            });
        }
    };

    const handleZoomOut = () => {
        if (networkRef.current) {
            const scale = networkRef.current.getScale();
            networkRef.current.moveTo({
                scale: scale * 0.8, // Zoom out
            });
        }
    };

    const handleRetrieveOWL = () => {
        setRetrieveOWL(!retrieveOWL);
    }

    const handleMatch = () => {
        setMatch(!match);
    }

    // sidebar items
    const [isExpanded, setIsExpanded] = useState(false);
    // RDF handling and sidebar items
    const [sidebarItems, setSidebarItems] = useState([]); 

    const [isChangeModel, setIsChangeModel] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [ontologyURL, setOntologyURL] = useState(GUCOontologyURL);
    const [enrichment, setEnrichment] = useState();

    const [showModalCreateMode, setShowModalCreateMode] = useState(false);

    // init enrichment
    useEffect(() => {

        switch (sem_model) {
            case 'GUCO':      
                setOntologyURL(GUCOontologyURL);
                setEnrichment(comp_semantic_enrichment_guco);
                break;
            case 'UCB':
                setOntologyURL(null);
                setEnrichment(comp_semantic_enrichment_baseline);
                break;
            case 'PEAMONTO':
                setOntologyURL(PEAMONTOontologyURL);
                setEnrichment(comp_semantic_enrichment_peamonto);
                break;
            default:
                setOntologyURL(GUCOontologyURL);
        }

    }, [sem_model]);
    
    const rdfData = OWLFileParser(ontologyURL, useState, useEffect);

    // parse the RDF data and build the sidebar items
    // used in the component modeling step and the chat to enhance the data by semantic information
    useEffect(() => {
        if (rdfData !== null) {
            const items = buildSidebarItems(rdfData);
            if (items.length > 0) {
                items[0].collapsed = false;
                setSemModel(items[0].title);
            }
            setSidebarItems(items);
        }
    }, [rdfData, setSemModel]);
  
    const navigate = useNavigate();
  
    // repository, dashboard
    const navigateRepository = () => {
      navigate('/Repository');
    };
    
    // Sidebar items
    const expandAll = () => {
        const updatedSidebarItems = expandAllItems(sidebarItems);
        setSidebarItems(updatedSidebarItems);
        setIsExpanded(true);
    };

    const collapseAll = () => {
        const updatedSidebarItems = collapseAllItems(sidebarItems);
        updatedSidebarItems[0].collapsed = false;
        setSidebarItems(updatedSidebarItems);
        setIsExpanded(false);
    }

    const handleCreateMode = () => {
        setShowModalCreateMode(true);
    };

    return (
        <div className="text-center">          
            <DndProvider backend={HTML5Backend}>            
                <Row style={{ marginBottom:'15px'}}>
                    <Col md={1}></Col>
                    {/* Headline and Toolbar */}
                    <Col md={3}>
                        <div>
                            <h3 className="text-start">Semantic Model Details</h3>
                            <h6 className="text-start">Look up model</h6>                      
                        </div>
                    </Col>
                    {/* Toolbar */}
                    <Col md={7} className="d-flex justify-content-end align-items-center">
                        {/* Semantic Model Selection */}
                        <OverlayTrigger placement="top" overlay={<Tooltip><i className="bi bi-lightbulb"></i> Select a semantic model</Tooltip>}>
                            <Form.Select 
                                aria-label="Default select example" 
                                value={sem_model} 
                                onChange={handleModelChange} 
                                style={ { maxWidth: '150px' }}>
                            <option value="GUCO">GUCO</option>
                            <option value="UCB">UC Baseline</option>
                            <option value="PEAMONTO">PEAMONTO</option>
                            </Form.Select>
                        </OverlayTrigger>
                        {/* Search */}
                        {!match && (
                        <OverlayTrigger placement="top" overlay={<Tooltip><i className="bi bi-search"></i> Enter a class name or concept to be retrieved from the semantic model</Tooltip>}>
                        <Form>
                            <Form.Group>
                                <Form.Control 
                                    type="text"
                                    placeholder="Search concept/class" 
                                    style={{ maxWidth: '150px', marginLeft: '10px'  }}
                                    value={searchTerm} 
                                    onChange={handleSearchTermChange} />
                            </Form.Group>
                        </Form>
                        </OverlayTrigger>
                        )}
                        {!match && (
                        <>                  
                        {/* Match Semantic Model with Component Architecture */}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Match the semantic model with the component architecture</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleMatch}>
                                <i className="bi bi-magic"></i> Match
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Retrieve the respective underlying model data</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleRetrieveOWL}>
                                <i className="bi bi-code"></i> OWL
                            </Button>
                        </OverlayTrigger>
                        </>
                        )}
                        {match && (
                        <>
                        {/* Visualize Semantic Model */}
                        <OverlayTrigger placement="top" overlay={<Tooltip>Visualize the selected semantic model</Tooltip>}>
                            <Button style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleMatch}>
                                <i className="bi bi-diagram-3"></i> Model
                            </Button>
                        </OverlayTrigger>
                        </>
                        )}
                        {/* Retrieve OWL data */}
                        
                        
                        {!match && (
                        <>
                        <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>
                        <Button onClick={() => handleZoomIn()} style={{ marginLeft: '10px' }} variant="outline-secondary">
                                <i className="bi bi-zoom-in"></i>
                        </Button>
                        <Button onClick={() => handleZoomOut()} style={{ marginLeft: '10px' }} variant="outline-secondary">
                            <i className="bi bi-zoom-out"></i>
                        </Button>
                        <div style={{ borderLeft: '1px solid #dee2e6', height: '40px', marginLeft: '10px' }}></div>                                      
                        {/* Layout buttons */}
                        <div>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Circle View</Tooltip>}>
                                <Button 
                                    variant={currentLayout === 'circle' ? "primary" : "outline-secondary"} 
                                    style={{ marginLeft: '10px' }} 
                                    onClick={() => changeLayout('circle')}
                                >
                                    <i className="bi bi-circle"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Hierarchy View</Tooltip>}>
                                <Button 
                                    style={{ marginLeft: '10px' }} 
                                    variant={currentLayout === 'hierarchical' ? "primary" : "outline-secondary"} 
                                    onClick={() => changeLayout('hierarchical')}
                                >
                                    <i className="bi bi-diagram-3"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Star View</Tooltip>}>
                                <Button 
                                    style={{ marginLeft: '10px' }} 
                                    variant={currentLayout === 'normal' ? "primary" : "outline-secondary"} 
                                    onClick={() => changeLayout('normal')}
                                >
                                    <i className="bi bi-asterisk"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>        
                        </>
                        )}                                          
                    </Col>
                    <Col md={1}></Col>
                </Row>
                <Row>
                    <Col md={1}></Col>
                    {/* Sidebar */}
                    <Col md={3} style={{ paddingRight: '30px', backgroundBlendMode: 'gray' }}> {/* borderRight: '0.5px solid #ccc', */}
                        <hr />
                        <div className="sidebar">
                            {/* Toolbar */}
                            <Row>
                                <Col md={12}>
                                {/* Select semantic model */}                  
                                <div className="sidebar">
                                    {rdfData ? (
                                    <>
                                    <Row style={ {marginBottom:'15px'}}>
                                        <h6 className="text-start">{sem_model} Elements | Classes & Concepts</h6>
                                        <div className="text-start">
                                            {isExpanded ? (
                                                <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={collapseAll}>
                                                    <i className="bi bi-arrows-collapse"></i> Collapse All
                                                </Button>                                    
                                            ) : (
                                                <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={expandAll}>
                                                <i className="bi bi-arrows-expand"></i> Expand All
                                                </Button>
                                            )}
                                            <OverlayTrigger placement="top" overlay={<Tooltip><i className="bi bi-lightbulb"></i> Change semantic model</Tooltip>}>
                                            <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={() => setIsChangeModel(!isChangeModel)}>
                                                <i className="bi bi-database"></i> Change
                                            </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip><i className="bi bi-search"></i> Search class/concept</Tooltip>}>
                                            <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={() => setIsSearch(!isSearch)}>
                                                <i className="bi bi-search"></i> Search
                                            </Button>
                                            </OverlayTrigger>
                                        </div>
                                        {/* Select semantic model */}
                                        {isChangeModel && (
                                        <div style={ { marginTop:'15px' } }>
                                            <h6 className="text-start"><i className="bi bi-lightbulb"></i> Select Semantic Model</h6>
                                            <Form.Select aria-label="Default select example" value={sem_model} onChange={handleModelChange} style={ {marginBottom:'15px'}}>
                                                <option>-- Select a model --</option>
                                                <option value="GUCO">GUCO</option>
                                                <option value="PEAMONTO">PEAMONTO</option>
                                            </Form.Select>
                                        </div>
                                        )}
                                        {/* Search specific term; auto completion */}
                                        {isSearch && (
                                        <div style={ { marginTop:'15px' } }>
                                            <h6 className="text-start"><i className="bi bi-search"></i> Search Term</h6>
                                            <Form.Control type="text" placeholder="Search concept/class" value={searchTerm} onChange={handleSearchTermChange} />
                                        </div>
                                        )}
                                    </Row>
                                    {/* Concepts */}
                                    <Row className="sidebar" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                        <h6 className="text-start">Hierarchy</h6>
                                        {renderConceptBadges(isKnowledgeSite, sidebarItems, sidebarItems, setSidebarItems, setSearchTerm)}
                                    </Row>
                                    <hr />  
                                    </>
                                    ) : (
                                    <Row className="text-start" style={ {marginBottom:'15px'}}>
                                        <h6>No ontology data available for {sem_model}</h6>
                                    </Row>
                                    )}                          
                                </div>      
                                {/* Move on */}
                                <div className="text-start" style={ {marginBottom:'15px'}}>                                   
                                    <Button variant="outline-primary" size="sm" style={{ marginRight: '10px' }} onClick={handleCreateMode}>
                                    <i className="bi bi-plus"></i> Model Use Case
                                    </Button>
                                    <Button variant="outline-primary" size="sm" onClick={navigateRepository}>
                                    <i className="bi bi-database"></i> Repository
                                    </Button>                              
                                </div>           
                                </Col>                                                     
                            </Row>                         
                        </div>
                    </Col>
                    <Col md={7}>
                    {/* Network Visualization and Code; and Matching Module */}
                    {!match ? (
                        <OWLVisualization 
                            rdfData={rdfData}
                            // Retrieve OWL data
                            retrieveOWL={retrieveOWL}
                            setRetrieveOWL={setRetrieveOWL}
                            // used to propagate whether to use the onClick in this page; different use in actor page
                            isKnowledgeSite={isKnowledgeSite}
                            currentLayout={currentLayout}
                            layoutOptions={layoutOptions}
                            sem_model={sem_model} 
                            setSemModel={setSemModel} 
                            handleModelChange={handleModelChange} 
                            searchTerm={searchTerm} 
                            setSearchTerm={setSearchTerm} 
                            handleSearchTermChange={handleSearchTermChange} 
                            networkRef={networkRef}
                        />
                    ) : (
                        <MatchingModule 
                            enrichment={enrichment}
                        />
                    )}
                    </Col>
                    <Col md={1}></Col>
                    </Row>
            </DndProvider>

            {/* Modal for Create Mode */}
            {showModalCreateMode && (
                <ModalCreateMode 
                show={showModalCreateMode} 
                onHide={() => setShowModalCreateMode(false)}         
                />  
            )}
        </div>
    );
}

export { default as KnwlDashboard } from './knwl_dashboard';