/**
 * Offcanvas component for the chat modal
 * Currently not in use
 */

import React, { useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ConceptModal = ({ inputConcept, onClose, addNew, newConcept, hasLinkage }) => {

    const [showModal, setShowModal] = useState(true);

    const closeModal = () => {
        setShowModal(false);
        onClose();
    };

    return (
        <Modal show={showModal} onHide={closeModal} placement="end">
            <Form className="text-start" onSubmit={(e) => addNew(e)}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Concept</Modal.Title> {/* Association Guide */}
            </Modal.Header>
            <Modal.Body>                
                <Form.Group className="mb-3">
                    <Form.Label style={{ fontSize: '0.8em' }}>Add New Concept</Form.Label>
                    <Form.Control name="newConcept" type="text" placeholder={inputConcept} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label style={{ fontSize: '0.8em' }}>Assign to parent concept</Form.Label>
                    <Form.Control name="parentConcept" type="text" placeholder="Linkage" />
                </Form.Group>
                {(newConcept === '' && !hasLinkage) && (
                    <div style={{ marginBottom: '5px' }}>
                        <Form.Text style={{ fontSize: '0.8em' }} className="text-danger">Please enter a name.</Form.Text>
                    </div>
                )} 
                {(newConcept !== '' && !hasLinkage) && (
                    <div style={{ marginBottom: '5px' }}>
                        <Form.Text style={{ fontSize: '0.8em' }} className="text-danger">Linkage not found. Add linked concept manually if needed.</Form.Text>
                    </div>
                )}                            
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button variant="outline-primary" type="submit" >
                    Add Concept
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
      );
    };

export default ConceptModal;