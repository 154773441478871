/**
 * Modal Delete Use Case
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-10
 * 
 */

import React from 'react';
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import Papierboot from '../../assets/img/Papierboot.png';

const UCModalDelete = ({ onClose, uc_name, handleDelete }) => {

    return (
        <Modal show={true} onHide={onClose} placement="end">
            <Modal.Header closeButton>
                <Modal.Title>Delete Use Case from Repository</Modal.Title> {/* Association Guide */}
            </Modal.Header>
            <Modal.Body>       
                <Row className="justify-content-center text-center">
                    <Col>
                        <img src={Papierboot} className="img-fluid" alt="Responsive" style={{ width: '50%', height: 'auto' }}/>        
                        <p><b>The use case {uc_name} will be deleted permanently.</b></p>                        
                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete permanently</Tooltip>}>
                        <Button style={{ marginLeft: '10px' }} variant="danger" onClick={handleDelete}>
                            <i className="bi bi-trash"></i> Delete {uc_name}
                        </Button>
                        </OverlayTrigger>                                
                    </Col>
                </Row>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onClose}>
                    <i className="bi bi-arrow-left"></i> Cancel
                </Button>
            </Modal.Footer>
        </Modal>
      );
    };

export default UCModalDelete;