/**
 * Survey evaluation component
 *
 * Description: Provides a visualization of the study survey
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-27
 */



import React, { useState } from 'react';
import { Row, Col, Card, Button, Table, Modal, Form } from 'react-bootstrap';
import { Pie, Bar } from 'react-chartjs-2';

import { StatisticsCard } from '../../eco_analytics_handling/eco_stats';

// Aggregate survey data for chart preparation and calculations
const aggregateSurveyData = (surveyData) => {

    const feelingData = {
        expert_feeling: { angry: 0, sad: 0, neutral: 0, happy: 0, very_happy: 0 },
        expert_work_level: { Beginner: 0, Intermediate: 0, Advanced: 0 },
        expert_work_layer: { Conceptional: 0, Strategic: 0, Execution: 0, Operational: 0 },
        expert_confidence: { 'Not confident': 0, 'Not really confident': 0, 'Somewhat confident': 0, 'Confident': 0, 'Very confident': 0 }
    };
    const addedValueData = {
        ucm_usefullness: { 'Not useful': 0, 'Neglectable': 0, 'Somewhat useful': 0, 'Useful': 0, 'Very useful': 0 },
        ucm_effectiveness: { 'Strongly disagree': 0, 'Disagree': 0, 'Neutral': 0, 'Agree': 0, 'Strongly agree': 0 },
        ucm_most_useful_aspects: { 'Four-step approach': 0, 'Info View': 0, 'Scenario Model': 0, 'Actor Map': 0, 'Component Diagram': 0, 'LLM Layer (UCM Co-Pilot)': 0, 'Domain-Specific Semantic Models': 0, 'UI Design': 0 },
        ucm_method_clarity: { 'Not clear': 0, 'Not so clear': 0, 'Somewhat clear': 0, 'Clear': 0, 'Very clear': 0 },
        ucm_ranked_aspects: { 'Four-step approach': 0, 'Info View': 0, 'Scenario Model': 0, 'Actor Map': 0, 'Component Diagram': 0, 'LLM Layer (UCM Co-Pilot)': 0, 'Domain-Specific Semantic Models': 0, 'UI Design': 0 },
    };
    const modelUsageData = {
        model_input: { 'Not useful': 0, 'Neglectable': 0, 'Somewhat useful': 0, 'Useful': 0, 'Very useful': 0 },
        model_llm_integration: { 'Not useful': 0, 'Neglectable': 0, 'Somewhat useful': 0, 'Useful': 0, 'Very useful': 0 },
        model_semantic_clarity: { 'Not clear': 0, 'Neglectable': 0, 'Somewhat clear': 0, 'Clear': 0, 'Very clear': 0 },
        model_agreed_aspects: { 'Relevance to the domain': 0, 'Clarity of the inputs': 0, 'Simplicity of the suggested elememts': 0, 'Completeness of the information': 0 },
    };
    const uiDesignData = {
        ui_clarity: { 'Not clear': 0, 'Neglectable': 0, 'Somewhat clear': 0, 'Clear': 0, 'Very clear': 0 },
        ui_simplicity: { 'Very complicated': 0, 'Complicated': 0, 'Somewhat simple': 0, 'Simple': 0, 'Very simple': 0 },
        ui_overall_aspects: [
            'Navigation',
            'Information layout',
            'Content clarity',
            'Interactivity',
            'Visual design',
            'Consistency',
            'Ease of use',
            'Effectiveness',
            'Usefulness of semantic models',                    
        ],
    };
    const userExperience = {
        reproducibility: [ 'Highly reproducible', 'Reproducible', 'Somewhat reproducible', 'Not really reproducible', 'Not reproducible' ],
        overall_experience: [ 'Excellent', 'Very good', 'Average', 'Fair', 'Poor'],
        additional_comments: [],
    };
    // General demographics
    let totalParticipants = surveyData.length;
    const experienceData = {};
    const roleData = {};
    const placeData = {};

    surveyData.forEach((data) => {

        // Aggregate `feeling` responses
        Object.entries(data.feeling || {}).forEach(([key, { answer }]) => {
            
            if (key !== 'expert_confidence') {
                feelingData[key] = feelingData[key] || {};
                feelingData[key][answer] = (feelingData[key][answer] || 0) + 1;
            } else {
                const expertConfidence = ['Not confident', 'Not really confident', 'Somewhat confident', 'Confident', 'Very confident'];                
                feelingData[key][expertConfidence[answer]]++;
            }
        });

        // Aggregate `addedValue` responses with type handling for `answer`
        Object.entries(data.addedValue || {}).forEach(([key, questionData]) => {
            const { answer, answer_set } = questionData;
            
            // For single-choice answers (numeric or string, like effectiveness)            
            if (typeof answer === 'number' || typeof answer === 'string') {                        
                const answerIndex = parseInt(answer, 10);
                const answerLabel = answer_set?.[answerIndex];
                if (addedValueData[key] && addedValueData[key][answerLabel] !== undefined) {
                    addedValueData[key][answerLabel]++;
                }
            }

            if (key === 'ucm_effectiveness') {
                if (addedValueData[key] && addedValueData[key][answer] !== undefined) {
                    addedValueData[key][answer]++;
                }
            }

            // For multi-select answers (array of strings, like most useful aspects)
            else if (Array.isArray(answer)) {
                answer.forEach((option) => {
                    if (addedValueData[key][option] !== undefined) {
                        addedValueData[key][option]++;
                    }
                });
            }

            // For ranking-style answers (object, like ranked aspects)
            else if (typeof answer === 'object' && answer !== null) {
                Object.entries(answer).forEach(([aspect, rank]) => {
                    if (addedValueData[key][aspect] !== undefined) {
                        addedValueData[key][aspect] += 1; // Optionally use rank as a weight if needed
                    }
                });
            }
        });
        
        // Aggregate `modelUsage` responses
        Object.entries(data.modelUsage || {}).forEach(([key, { answer }]) => {

            if (key === 'model_semantic_clarity') {
                const model_semantic_clarity = ['Not clear', 'Neglectable', 'Somewhat clear', 'Clear', 'Very clear'];                
                modelUsageData[key][model_semantic_clarity[answer-1]]++;
            } else if (key === 'model_input' || key === 'model_llm_integration') {
                const model_llm_integration = ['Not useful', 'Neglectable', 'Somewhat useful', 'Useful', 'Very useful'];                
                modelUsageData[key][model_llm_integration[answer]]++;
            } else if (key === 'model_agreed_aspects') {
                const modelAgreedAspectsOptions = [
                    'Relevance to the domain', 
                    'Clarity of the inputs', 
                    'Simplicity of the suggested elements', 
                    'Completeness of the information'
                ];
            
                // Ensure the structure for model_agreed_aspects exists
                modelUsageData[key] = modelUsageData[key] || modelAgreedAspectsOptions.reduce((acc, aspect) => {
                    acc[aspect] = 0;
                    return acc;
                }, {});
            
                // Check if answer is an array and iterate over it
                if (Array.isArray(answer)) {
                    answer.forEach((selectedAspect) => {
                        if (modelUsageData[key][selectedAspect] !== undefined) {
                            modelUsageData[key][selectedAspect]++;
                        }
                    });
                }             
            } else {               
                modelUsageData[key] = modelUsageData[key] || {};
                modelUsageData[key][answer] = (modelUsageData[key][answer] || 0) + 1;
            }
        });

        // Aggregate `uiDesign` responses
        Object.entries(data.uiDesign || {}).forEach(([key, { answer }]) => {

            if (key === 'ui_clarity') {
                const ui_clarity = ['Not clear', 'Neglectable', 'Somewhat clear', 'Clear', 'Very clear'];
                uiDesignData[key][ui_clarity[answer-1]]++;
            } else if (key === 'ui_simplicity') {
                if (uiDesignData[key] && uiDesignData[key][answer] !== undefined) {
                    uiDesignData[key][answer]++;
                }
            }
            else if (key === 'ui_overall_aspects') {                

                const uiAspectCategories = [
                    'Navigation', 'Information layout', 'Content clarity', 'Interactivity',
                    'Visual design', 'Consistency', 'Ease of use', 'Effectiveness', 'Usefulness of semantic models'
                ];

                uiDesignData[key] = {};  // Make sure ui_overall_aspects is an object
            
                uiAspectCategories.forEach((aspect) => {
                    uiDesignData[key][aspect] = {
                        "Very Poor": 0,
                        "Poor": 0,
                        "Neutral": 0,
                        "Good": 0,
                        "Very Good": 0
                    };
                });
                
                // Iterate over each aspect and increment the corresponding rating
                Object.entries(answer).forEach(([aspect, rating]) => {
                    if (uiDesignData[key][aspect] && uiDesignData[key][aspect][rating] !== undefined) {
                        uiDesignData[key][aspect][rating]++;
                    }
                });
        
            } else {
                uiDesignData[key] = uiDesignData[key] || {};
                uiDesignData[key][answer] = (uiDesignData[key][answer] || 0) + 1;
            }
        });

        // Aggregate `userExperience` responses
        Object.entries(data.userExperience || {}).forEach(([key, { answer }]) => {

            if (key === 'reproducibility' || key === 'overall_experience') {
                userExperience[key] = userExperience[key] || {};
                userExperience[key][answer] = (userExperience[key][answer] || 0) + 1;
            } else if (key === 'additional_comments') {
                userExperience[key] = userExperience[key] || [];
                userExperience[key].push(answer);
            }
        });

        // Aggregate years of work experience for each participant
        experienceData[data.experience?.value] = (experienceData[data.experience?.value] || 0) + 1;
    
        // Aggregate `role` responses
        roleData[data.role?.value] = (roleData[data.role?.value] || 0) + 1;

        // Aggregate `place` responses
        placeData[data.place?.value] = (placeData[data.place?.value] || 0) + 1;
    });

    return { 
        feelingData, 
        addedValueData, 
        modelUsageData, 
        uiDesignData, 
        userExperience,
        // General demographics
        roleData, 
        placeData, 
        experienceData, 
        totalParticipants, 
    };
};

// Prepare data for charts
const prepareChartData = (dataObj = {}, label) => {
    const colors = [
        'rgba(75,192,192,0.4)', 'rgba(75,192,192,0.6)', 'rgba(75,192,192,0.8)', 'rgba(75,192,192,1)',
        'rgba(153,102,255,0.4)', 'rgba(153,102,255,0.6)', 'rgba(153,102,255,0.8)', 'rgba(153,102,255,1)',
        'rgba(255,159,64,0.4)', 'rgba(255,159,64,0.6)', 'rgba(255,159,64,0.8)', 'rgba(255,159,64,1)'
    ];

    return {
        labels: Object.keys(dataObj),
        datasets: [
            {
                label,
                data: Object.values(dataObj),
                backgroundColor: colors.slice(0, Object.keys(dataObj).length),
                borderColor: colors.slice(0, Object.keys(dataObj).length).map(color => color.replace(/0\.\d+/, '1')),
                borderWidth: 1,
            },
        ],
    };
};

/*
const prepareStackedChartData = (dataObj, label) => {
    const categories = Object.keys(dataObj); // E.g., ['Navigation', 'Content clarity']
    const ratings = ["Very Poor", "Poor", "Neutral", "Good", "Very Good"]; // Consistent rating categories

    return {
        labels: categories,
        datasets: ratings.map((rating, index) => ({
            label: rating,
            data: categories.map(category => dataObj[category][rating] || 0),
            backgroundColor: `rgba(${75 + index * 30}, ${192 - index * 20}, ${192 - index * 40}, 0.6)`, // Different color for each rating
            borderWidth: 1
        }))
    };
};
*/

export const SurveyAnalysis = ({ surveyData, handleClear }) => {

    const {
        feelingData, 
        addedValueData, 
        modelUsageData, 
        //uiDesignData,
        userExperience,
        // General demographics
        roleData, 
        placeData, 
        experienceData, 
        totalParticipants } = aggregateSurveyData(surveyData);

    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    // Handle selecting individual survey response
    const handleParticipantSelect = (event) => {
        const selectedId = event.target.value;
        const participantData = surveyData.find((data) => data.anonymous_id.value === selectedId);
        setSelectedParticipant(participantData || null);
    };

    // Handle selecting participants by role
    const handleParticipantsSelectByRole = (event) => {
        const selectedRole = event.target.value;
        const participantData = surveyData.filter((data) => data.role?.value === selectedRole);
        //console.log('Selected role:', selectedRole, participantData);
        setSelectedRole(participantData || null);
    };

    // Chart click and hover handlers
    const handleChartClick = (evt, elems, chartData, topic) => {
        if (elems.length > 0) {
            const clickedIndex = elems[0].index;
            const clickedCategory = chartData.labels[clickedIndex];
            const clickedData = {
                topic,
                category: clickedCategory,
                res: surveyData.filter((data) => data.feeling[topic]?.answer === clickedCategory),
            };
            setModalData(clickedData);
            setShowModal(true);
        }
    };

    const handleChartHover = (evt, elems) => {
        evt.native.target.style.cursor = elems.length > 0 ? 'pointer' : 'default';
    };

    const chartContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '300px'
    };

    let totalExperience = 0;
    Object.entries(experienceData).forEach(([years, count]) => {
        totalExperience += years * count;
    });

    return (
        <Row>
            <Col md={12} style={{ textAlign: 'left' }} id="content">
                {/* Title */}
                <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Col>
                        <span>Expert Demographics</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-primary" className="btn-sm" onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                    </Col>
                </Row>

                {/* General Demographics */}
                <Row style={ { marginBottom:'30px'} }>
                    <StatisticsCard targetCount={totalParticipants} caption="Total Participants" />
                    <StatisticsCard targetCount={Object.keys(roleData).length} caption="Roles" />
                    <StatisticsCard targetCount={Object.keys(placeData).length} caption="Locations" />
                    <StatisticsCard targetCount={totalExperience} caption="Years of Experience" />
                </Row>

                {/* Intro and selection */}
                <Row style={ { marginBottom:'30px'} }>                    
                    {/* Individual Participant or Role Selection */}
                    <Col md={3}>
                        <Card className="h-100">
                        <Card.Header>Participant Selection</Card.Header>
                        <Card.Body>
                            <Form.Group controlId="participantSelect">       
                                {/* Select by Anonymized ID */}                       
                                <Form.Label className='small'>Select by Anonymized ID:</Form.Label>  
                                <Form.Control as="select" onChange={handleParticipantSelect}>
                                    <option value="">All Participants</option>
                                    {surveyData.map((data, idx) => (
                                        <option key={idx} value={data.anonymous_id.value}>
                                            {data.anonymous_id.value || `Participant ${idx + 1}`}
                                        </option>
                                    ))}
                                </Form.Control>
                                
                                {/* Select by Role */}
                                <Form.Label className='small' style={{marginTop:'15px'}}>Select by Role:</Form.Label>
                                <Form.Control as="select" onChange={handleParticipantsSelectByRole}>
                                    <option value="">All Roles</option>
                                    {Object.entries(roleData).map(([role, count]) => (
                                        <option key={role} value={role}>
                                            {role} ({count})
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Text className="text-muted small">Select a role to filter participants</Form.Text>
                            </Form.Group>
                            <div className="mt-3">
                                <span className="text-muted small">Select a participant to view detailed responses.</span>
                                <br />
                                {/* Warning box that all data is anonymized */}
                                <span className="text-muted small">All data is anonymized.</span>                              
                            </div>
                        </Card.Body>
                        </Card>
                    </Col>
                    {/* Introduction to data collected in this survey */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Survey Data</Card.Header>
                            <Card.Body>
                                <p className='small'>
                                    This survey collected data from {totalParticipants} participants.
                                    The data includes responses to questions divided into the following categories:                                    
                                </p>          
                                <Table bordered className='small'>
                                    <tbody>
                                        <tr><th>#1</th><th>Feeling</th><td>About You and this Research Study</td></tr>
                                        <tr><th>#2</th><th>Added Value</th><td>Added Value of the UCM method</td></tr>
                                        <tr><th>#3</th><th>Model Usage</th><td>Model usage ratings</td></tr>
                                        <tr><th>#4</th><th>Experience</th><td>Overall experience about the tool and study</td></tr>
                                    </tbody>
                                </Table>                     
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Disclaimer and context of this survey */}
                    <Col md={3}>
                        <Card
                            className="h-100"
                            style={{
                                backgroundColor: '#f1f8e9',
                                border: '1px solid #81c784',
                                color: '#2e7d32',
                                //borderRadius: '10px',
                                //padding: '15px',
                            }}
                        >
                            <Card.Header style={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#1b5e20', backgroundColor: '#a5d6a7' }}>
                                Survey Context
                            </Card.Header>
                            <Card.Body>
                                <p className="small" style={{ marginBottom: 0 }}>
                                    This survey is conducted as part of PhD research, aiming to propose a PEAM model.
                                    <br /><br />
                                    All participants are experts in fields such as engineering, urban planning, administration, computing, and AI,
                                    each approaching autonomous mobility and transportation from unique roles and experiences.
                                    <br /><br />
                                    The data is anonymized and used solely for research purposes.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>            
            
                {/* Section 1: About You and this Research Study */}
                <Row>
                    <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                        Survey Section One: `About You and this Research Study´
                    </h4>
                    {/* Expert feeling */}
                    <Col md={3}>            
                        <Card className="h-100">
                            <Card.Header>Expert Feeling</Card.Header>
                            <Card.Body>
                                <Pie
                                    data={prepareChartData(feelingData.expert_feeling, 'Expert Feeling')}
                                    options={{
                                        onClick: (evt, elems) =>
                                            handleChartClick(evt, elems, prepareChartData(feelingData.expert_feeling, 'Expert Feeling'), 'expert_feeling'),
                                        onHover: handleChartHover,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            </Card.Body>
                        </Card>                        
                    </Col>
                    {/* Expert Work Level */}
                    <Col md={3}>
                        <Card className="h-100">
                            <Card.Header>Expert Work Level</Card.Header>
                            <Card.Body>
                                <Pie
                                    data={prepareChartData(feelingData.expert_work_level, 'Expert Work Level')}
                                    options={{
                                        onClick: (evt, elems) =>
                                            handleChartClick(evt, elems, prepareChartData(feelingData.expert_work_level, 'Expert Work Level'), 'expert_work_level'),
                                        onHover: handleChartHover,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Expert Work Layer */}
                    <Col md={3}>
                        <Card className="h-100">
                            <Card.Header>Expert Work Layer</Card.Header>
                            <Card.Body>
                                <Pie
                                    data={prepareChartData(feelingData.expert_work_layer, 'Expert Work Layer')}
                                    options={{
                                        onClick: (evt, elems) =>
                                            handleChartClick(evt, elems, prepareChartData(feelingData.expert_work_layer, 'Expert Work Layer'), 'expert_work_layer'),
                                        onHover: handleChartHover,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Expert Confidence */}
                    <Col md={3}>
                        <Card className="h-100">
                            <Card.Header>Expert Confidence</Card.Header>
                            <Card.Body>
                                <Pie
                                    data={prepareChartData(feelingData.expert_confidence, 'Expert Confidence')}
                                    options={{
                                        onClick: (evt, elems) =>
                                            handleChartClick(evt, elems, prepareChartData(feelingData.expert_confidence, 'Expert Confidence'), 'expert_confidence'),
                                        onHover: handleChartHover,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            {/* Section 2: Value of UCM Method */}
            <Row>
                <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                    Survey Section Two: `Added Value of UCM Method´
                </h4>
                <Row>
                    {/* UCM usefullness */}
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>Value of UCM Method</Card.Header>
                            <Card.Body>
                            {addedValueData.ucm_usefullness ? (
                                <Bar
                                    data={prepareChartData(addedValueData.ucm_usefullness, 'UCM Usefulness')}
                                    options={{
                                        onClick: (evt, elems) =>
                                            handleChartClick(evt, elems, prepareChartData(addedValueData.ucm_usefullness, 'UCM Usefulness'), 'ucm_usefullness'),
                                        onHover: handleChartHover,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            ) : (
                                <p>No data available for UCM Usefulness</p>
                            )}
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* UCM effectiveness */}
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>UCM Effectiveness</Card.Header>
                            <Card.Body>
                                {addedValueData.ucm_effectiveness ? (
                                    <Bar
                                        data={prepareChartData(addedValueData.ucm_effectiveness, 'UCM Effectiveness')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(addedValueData.ucm_effectiveness, 'UCM Effectiveness'), 'ucm_effectiveness'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for UCM Effectiveness</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* UCM Most Useful Aspects */}
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>UCM Most Useful Aspects</Card.Header>
                            <Card.Body>
                                {addedValueData.ucm_most_useful_aspects ? (
                                    <Bar
                                        data={prepareChartData(addedValueData.ucm_most_useful_aspects, 'UCM Most Useful Aspects')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(addedValueData.ucm_most_useful_aspects, 'UCM Most Useful Aspects'), 'ucm_most_useful_aspects'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for UCM Most Useful Aspects</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {/* UCM method clarity */}
                    <Col md={6} style={{ marginTop:'30px'}}>
                        <Card className="h-100">
                            <Card.Header>UCM Method Clarity</Card.Header>
                            <Card.Body>
                                {addedValueData.ucm_method_clarity ? (
                                    <Bar
                                        data={prepareChartData(addedValueData.ucm_method_clarity, 'UCM Method Clarity')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(addedValueData.ucm_method_clarity, 'UCM Method Clarity'), 'ucm_method_clarity'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for UCM Method Clarity</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* UCM Ranked Aspects */}
                    <Col md={6} style={{ marginTop:'30px'}}>
                        <Card className="h-100">
                            <Card.Header>UCM Ranked Aspects</Card.Header>
                            <Card.Body>
                                {addedValueData.ucm_ranked_aspects ? (
                                    <Bar
                                        data={prepareChartData(addedValueData.ucm_ranked_aspects, 'UCM Ranked Aspects')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(addedValueData.ucm_ranked_aspects, 'UCM Ranked Aspects'), 'ucm_ranked_aspects'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for UCM Ranked Aspects</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Row>

            {/* Section 3: Model Usage */}
            <Row>
                <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                    Survey Section Three: `Model Usage´
                </h4>
                <Row>
                    {/* Model input */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Model Input</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {modelUsageData.model_input ? (
                                    <Bar
                                        data={prepareChartData(modelUsageData.model_input, 'Model Input')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(modelUsageData.model_input, 'Model Input'), 'model_input'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for Model Input</p>
                                )}
                                </div> 
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Model LLM integration */}
                    <Col md={6}>
                        <Card className="h-100">
                            <Card.Header>Model LLM integration</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {modelUsageData.model_llm_integration ? (
                                    <Bar
                                        data={prepareChartData(modelUsageData.model_llm_integration, 'Model LLM integration')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(modelUsageData.model_llm_integration, 'Model LLM Integration'), 'model_llm_integration'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for Model LLM integration</p>
                                )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {/* Model semantic clarity */}
                    <Col md={6} style={{ marginTop:'30px'}}>
                        <Card className="h-100">
                            <Card.Header>Model Semantic Clarity</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {modelUsageData.model_semantic_clarity ? (
                                    <Bar
                                        data={prepareChartData(modelUsageData.model_semantic_clarity, 'Model Semantic Clarity')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(modelUsageData.model_semantic_clarity, 'Model Semantic Clarity'), 'model_semantic_clarity'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for Model Semantic Clarity</p>
                                )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Model agreed aspects */}
                    <Col md={6} style={{ marginTop:'30px'}}>
                        <Card className="h-100">
                            <Card.Header>Model Agreed Aspects</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {modelUsageData.model_agreed_aspects ? (
                                    <Bar
                                        data={prepareChartData(modelUsageData.model_agreed_aspects, 'Model Agreed Aspects')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(modelUsageData.model_agreed_aspects, 'Model Agreed Aspects'), 'model_agreed_aspects'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for Model Agreed Aspects</p>
                                )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                    
            </Row>

            {/* Section 4: UI Design and Experience        
            <Row>
                <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                    Survey Section Four: `UI Design and Experience´
                </h4>
                <Row>
                    {/* UI design clarity
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>UI Design Clarity</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {uiDesignData.ui_clarity ? (
                                    <Bar
                                        data={prepareChartData(uiDesignData.ui_clarity, 'UI Clarity')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(uiDesignData.ui_clarity, 'UI Clarity'), 'ui_clarity'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for UI Design Clarity</p>
                                )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* UI simplicity
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>UI Simplicity</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {uiDesignData.ui_simplicity ? (
                                    <Bar
                                        data={prepareChartData(uiDesignData.ui_simplicity, 'UI Simplicity')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(uiDesignData.ui_simplicity, 'UI Simplicity'), 'ui_simplicity'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for UI Simplicity</p>
                                )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* UI overall aspects
                    <Col md={4}>
                    <Card className="h-100">
                        <Card.Header>UI Overall Aspects</Card.Header>
                        <Card.Body>
                            <div style={chartContainerStyle}>
                                {uiDesignData.ui_overall_aspects ? (
                                    <Bar
                                        data={prepareStackedChartData(uiDesignData.ui_overall_aspects, 'UI Overall Aspects')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(uiDesignData.ui_overall_aspects, 'UI Overall Aspects'), 'ui_overall_aspects'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            scales: {
                                                x: { stacked: true, title: { display: true, text: 'Aspects' } },
                                                y: { stacked: true, title: { display: true, text: 'Number of Responses' } }
                                            }
                                        }}
                                    />
                                ) : (
                                    <p>No data available for UI Overall Aspects</p>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Row>
            */}

            {/* Section 4: User Experience */}
            <Row>
                <h4 style={{ fontWeight: 'bold', marginTop:'30px', marginBottom:'60px'}}>
                    Survey Section Four: `User Experience´
                </h4>
                <Row>
                    {/* Reproducibiliry */}
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>Reproducibility</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {userExperience.reproducibility ? (
                                    <Bar
                                        data={prepareChartData(userExperience.reproducibility, 'Reproducibility')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(userExperience.reproducibility, 'Reproducibility'), 'reproducibility'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for Reproducibility</p>
                                )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Overall Experience */}
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>Overall Experience</Card.Header>
                            <Card.Body>
                                <div style={chartContainerStyle}>
                                {userExperience.overall_experience ? (
                                    <Bar
                                        data={prepareChartData(userExperience.overall_experience, 'Overall Experience')}
                                        options={{
                                            onClick: (evt, elems) =>
                                                handleChartClick(evt, elems, prepareChartData(userExperience.overall_experience, 'Overall Experience'), 'overall_experience'),
                                            onHover: handleChartHover,
                                            maintainAspectRatio: false,
                                        }}
                                    />
                                ) : (
                                    <p>No data available for overall experience</p>
                                )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* Additional comments */}
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Header>Additional comments</Card.Header>
                            <Card.Body>
                                {userExperience.additional_comments && userExperience.additional_comments.length > 0 ? (
                                    <div style={{ maxHeight: '200px', overflowY: 'auto', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                                        {userExperience.additional_comments.map((comment, index) => (
                                            <p key={index} style={{ fontSize: '0.9rem', marginBottom: '10px' }}> 
                                                <i>"{comment}"</i>
                                            </p>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No additional comments available</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Row>


            {/* Individual Participant's Detailed Responses in Modal */}
            <Modal show={!!selectedParticipant} onHide={() => setSelectedParticipant(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Participant's Responses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedParticipant && (
                        <Table bordered>
                            <tbody>
                                <tr><th>Anonymous ID</th><td>{selectedParticipant.anonymous_id?.value}</td></tr>
                                <tr><th>Role</th><td>{selectedParticipant.role?.value || 'N/A'}</td></tr>
                                <tr><th>Place</th><td>{selectedParticipant.place?.value || 'N/A'}</td></tr>
                                <tr><th>Year of Birth</th><td>{selectedParticipant.yearOfBirth?.value || 'N/A'}</td></tr>
                                {/* Dynamically render responses for each question set */}
                                {Object.entries(selectedParticipant).map(([section, responses]) =>
                                    (typeof responses === 'object' && responses.setLabel) ? (
                                        <React.Fragment key={section}>
                                            <tr><th colSpan="2">{responses.setLabel}</th></tr>
                                            {Object.entries(responses).map(([key, question]) => (
                                                question?.question ? (
                                                    <tr key={key}>
                                                        <th>{question.question}</th>
                                                        <td>{Array.isArray(question.answer) ? question.answer.join(', ') : question.answer}</td>
                                                    </tr>
                                                ) : null
                                            ))}
                                        </React.Fragment>
                                    ) : null
                                )}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSelectedParticipant(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Participants by Role Modal */}
            <Modal show={!!selectedRole} onHide={() => setSelectedRole(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Participants by Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRole && (
                        <Table bordered>
                            <tbody>
                                {selectedRole.map((participant, idx) => (
                                    <tr key={idx}>
                                        <td>{participant.anonymous_id?.value}</td>
                                        <td>{participant.role?.value || 'N/A'}</td>
                                        <td>{participant.place?.value || 'N/A'}</td>
                                        <td>{participant.yearOfBirth?.value || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSelectedRole(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Detail Modal */}
            {modalData && (
                <DetailModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} />
            )}

            </Col>
        </Row>
    );
};

const DetailModal = ({ show, handleClose, data }) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{data.topic}: {data.category}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h6>List of Participants in "{data.category}"</h6>
            <ul>
                {data.res.map((item, index) => (
                    <li key={index}>{item.anonymous_id?.value || 'N/A'}</li>
                ))}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);