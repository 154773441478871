/**
 * Use Case Repository: stacked view
 *
 * Description: Renders the use case repository in a stacked view.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-09
 * 
 */

import React from 'react';
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, Accordion } from 'react-bootstrap';

const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }
    return 'N/A';
};

const parseDescription = (description) => {
    const lines = description.split('\n');
    return lines.map((line, index) => {
      if (line.startsWith('### ')) {
        return <h6 key={index}>{line.slice(4)}</h6>;
      } else if (line.startsWith('#### ')) {
        return <p key={index}>{line.slice(5)}</p>;
      } else if (line.startsWith('- **')) {
        return <span key={index} style={{ listStyleType: 'none' }}>{parseBoldText(line)}</span>;
      } else if (line.match(/^\d+\.\s\*\*/)) {
        return <span key={index}>{parseBoldText(line, true)}</span>;
      } else {
        return <p key={index}>{parseBoldText(line)}</p>;
      }
    });
};

const parseBoldText = (text, isEnumeration = false) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = text.split(boldRegex).filter(Boolean);
    return parts.map((part, index) => {
      if (boldRegex.test(part)) {
        return <strong key={index}>{part.replace(/\*\*/g, '')}</strong>;
      } else if (isEnumeration && index === 0) {
        // Ensure spacing after enumeration number
        return ' ' + part + ' ';
      } else {
        return part;
      }
    });
  };

const renderAccordionView = ( currentItems, selectedUseCases, handleCheckboxChange, handleInspectUseCase, handleUseAsTemplate, getUserID, handleDeleteUseCase ) => (
    //{/* Render use cases in the repository */}   
    // defaultActiveKey="0"     
    <Accordion>
        {currentItems.map((useCase, index) => (
            <Accordion.Item 
                eventKey={`${index}`} 
                key={useCase.id} 
                style={{ 
                    marginBottom: '15px',
                    borderTop: '1px solid #dee2e6'
                }}
                >
                <Accordion.Header>
                    <div className="d-flex align-items-center">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Select to include in Ecosystem Analysis</Tooltip>}
                        >
                        <Form.Check
                            type="checkbox"
                            checked={selectedUseCases.includes(useCase.id)}
                            onChange={() => handleCheckboxChange(useCase.id)}
                            className="me-2"                 
                        />
                        </OverlayTrigger>
                        <div>                                
                            <h4>
                                <i className="bi bi-box"> </i>
                                {useCase.acronym.value}: {useCase.title.value}
                            </h4>
                            {/* meta data*/}
                            <div style={{ fontSize: '12px' }}>
                                <span className="badge badge-pill bg-light text-dark" style={{ marginRight: '10px' }}>
                                    {useCase.created_by.value}
                                </span>
                                {useCase.visibility.value ? 
                                  <span className="badge badge-pill bg-success" style={ { marginRight:'10px'} }>Publicly visible</span>
                                  :
                                  <span className="badge badge-pill bg-warning" style={ { marginRight:'10px'} }>Restricted visibility</span>
                                }
                                {useCase.adoption.value ? 
                                  <span className="badge badge-pill bg-success" style={ { marginRight:'10px'} }>Open access</span>
                                  :
                                  <span className="badge badge-pill bg-warning" style={ { marginRight:'10px'} }>No access</span>
                                }                     
                                {useCase.share.value ? 
                                  <span className="badge badge-pill bg-success" style={ { marginRight:'10px'} }>Share Data</span>
                                  :
                                  <span className="badge badge-pill bg-warning" style={ { marginRight:'10px'} }>Hide Data</span>
                                }
                            </div>
                            <hr />          
                            <div style={{ fontSize: '16px' }}>
                                <i className="bi bi-tags"> </i>
                                {useCase.tags.value.map(tag => (
                                    <span key={tag} className="badge bg-light text-dark" style={{ marginLeft: '5px', marginBottom:'5px' }}>
                                    {tag}
                                    </span>
                                ))}
                            </div>                            
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <Row style={{ height: '160px', overflowY: 'auto' }}>
                        <Col md={4} style={{ fontSize: '12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ minWidth: '100px', fontWeight: 'bold' }}>Application:</div>
                                <div>{useCase.application.value}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ minWidth: '100px', fontWeight: 'bold' }}>Maturity:</div>
                                <div>{useCase.maturity.value}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ minWidth: '100px', fontWeight: 'bold' }}>Status:</div>
                                <div>{useCase.status.value}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ minWidth: '100px', fontWeight: 'bold' }}>Location:</div>
                                <div>{useCase.city.value}, {useCase.country.value}</div>
                            </div>
                            ---
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ minWidth: '100px', fontWeight: 'bold' }}>Created:</div>
                                <div>{formatTimestamp(useCase.created.value)}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ minWidth: '100px', fontWeight: 'bold' }}>Updated:</div>
                                <div>{formatTimestamp(useCase.updated.value)}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ minWidth: '100px', fontWeight: 'bold' }}>UCID:</div>
                                <div>{useCase.ucid.value}</div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <span style={{ fontSize: '12px' }}><b>Description:</b></span> 
                            <br /><br />
                            {parseDescription(useCase.description.value)}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                    <div className="d-flex justify-content-end">

                        <OverlayTrigger placement="top" overlay={<Tooltip>Look up the use case and process it</Tooltip>}>
                            <Button size='sm' style={{ marginLeft: '10px' }} variant="outline-primary" onClick={() => handleInspectUseCase(useCase.id)}>
                                <i className="bi bi-link"></i> Inspect Use Case
                            </Button>
                        </OverlayTrigger>

                        {useCase.adoption.value && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>Use this use case as a template</Tooltip>}>
                            <Button size='sm' style={{ marginLeft: '10px' }} variant="outline-primary" onClick={() => handleUseAsTemplate(useCase.id)}>
                                <i className="bi bi-database"></i> Use as Template
                            </Button>                       
                        </OverlayTrigger> 
                        )}

                        {getUserID() === useCase.user_id.value && (
                            <Button size='sm' style={{ marginLeft: '10px' }} variant="outline-danger" onClick={() => handleDeleteUseCase(useCase.id)}>
                                <i className="bi bi-trash"></i> Delete
                            </Button>
                        )}                            
                    </div>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        ))}
    </Accordion>
);

export default renderAccordionView;