

import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { getCurrentDate } from '../utils/utils_date';

import Papierboot from '../../assets/img/Papierboot.png';
import { BarChart, ActorListTable, ActorBarChart, ComponentBarChart, ComponentBarChartNested, ComponentListTable } from '../analytics_handling/analytics_charts';

const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return '';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

/**
 * Cards to display the statistics of the analysis
 * @param {*} param0 
 * @returns 
 */
const StatisticsCard = ({ col, number, caption }) => {

  return (
      <Col md={col}>
      <Card className="text-center h-100" style={{ padding: '10px' }}>
          <Card.Body>
          <Card.Title style={{ fontSize: '48px', fontWeight: 'bold' }}>
              {number}
          </Card.Title>
          <Card.Text style={{ fontSize: '14px' }}>
              {caption}
          </Card.Text>
          </Card.Body>
      </Card>
      </Col>
);
};

export const ReportHTML = ({ selectedUseCase, comparisonData, stats, matchingScore, handleChartHover }) => (
  
  <Row>
    <Col md={12}>
      <div style={{ padding: '20px', marginTop: '20px', border: '1px solid #ccc', borderRadius: '5px', overflowY: 'scroll', maxHeight: '600px' }}>
        <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h1 style={{ margin: 0 }}>Use Case Report: {selectedUseCase.title.value}</h1>
          <img src={Papierboot} alt="Logo" style={{ width: '100px', height: 'auto' }} />
        </header>
        
        <section style={{ marginBottom: '20px' }}>
          <span>Generated by UCM on {getCurrentDate()}</span>
          <br />
          <span>UCM can make mistakes. Verify important information.</span>
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2 style={{ borderBottom: '2px solid #ccc', paddingBottom: '5px' }}>Use Case Score</h2>
          <Row>
            <Col md={5}>
              <Row style={{ height: '100%' }}>
                <StatisticsCard col="6" number={matchingScore} caption="Matching Score" />                    
                <Col md={6}>
                    <span>
                        <small>
                            <i className="bi bi-info-circle"></i> The matching score indicates how well the selected use case maps in the ecosystem of modeled use cases.                                                                                              
                        </small>
                    </span>
                </Col>
              </Row>
            </Col>
            <Col md={7}>
                {/* Findings at a glance */}
                <Row style={{ height: '100%' }}>
                    <StatisticsCard col="4" number={stats.similar} caption="Similar Use Cases" />
                    <StatisticsCard col="4" number={stats.overlapping} caption="Overlapping Use Cases" />   
                    <StatisticsCard col="4"number={stats.distinct} caption="Distinct Use Cases" />                                       
                </Row>       
            </Col>          
          </Row>
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2 style={{ borderBottom: '2px solid #ccc', paddingBottom: '5px' }}>General Information</h2>
          <Row>
            <Col md={4}>
              <h5>Use Case Information</h5>
              <p><strong>Acronym:</strong> {selectedUseCase.acronym.value}</p>
              <p><strong>Application:</strong> {selectedUseCase.application.value}</p>
              <p><strong>Status:</strong> {selectedUseCase.status.value}</p>
              <p><strong>Maturity:</strong> {selectedUseCase.maturity.value}</p>
            </Col>
            <Col md={4}>
              <h5>Use Case Details</h5>
              <p><strong>Created by:</strong> {selectedUseCase.created_by.value}</p>
              <p><strong>Date Created:</strong> {formatTimestamp(selectedUseCase.created.value)}</p>
              <p><strong>Date Updated:</strong> {formatTimestamp(selectedUseCase.updated.value)}</p>
              <p><strong>Use Case ID:</strong> {selectedUseCase.ucid.value}</p>
            </Col>
            <Col md={4}>
              <h5>Use Case Properties</h5>
              <p><strong>Visibility:</strong> {selectedUseCase.visibility.value ? 'Yes' : 'No'}</p>
              <p><strong>Adoption:</strong> {selectedUseCase.adoption.value ? 'Yes' : 'No'}</p>
              <p><strong>Share:</strong> {selectedUseCase.share.value}</p>
              <p><strong>Semantic model:</strong> {selectedUseCase.semantic_model.value ? 'Yes' : 'No'}</p>
              <p><strong>Domain model:</strong> {selectedUseCase.domain_model.value ? 'Yes' : 'No'}</p>
            </Col>
          </Row>
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2 style={{ borderBottom: '2px solid #ccc', paddingBottom: '5px' }}>Use Case Description</h2>
          <p>{selectedUseCase.description.value}</p>
          <p><strong>Tags:</strong> {selectedUseCase.tags.value.join(', ')}</p>
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2 style={{ borderBottom: '2px solid #ccc', paddingBottom: '5px' }}>Use Case Classification</h2>
          <p>Below charts show how the {selectedUseCase.title.value} classifies across all modeled use cases.</p>
          <Row>
            <Col md={4}>
              <BarChart
                comparisonData={comparisonData}
                attribute={'application'}
                selectedUseCase={selectedUseCase}
                handleChartClick={handleChartHover}
                handleChartHover={handleChartHover}
              />
            </Col>
            <Col md={4}>
              <BarChart
                comparisonData={comparisonData}
                attribute={'maturity'}
                selectedUseCase={selectedUseCase}
                handleChartClick={handleChartHover}
                handleChartHover={handleChartHover}
              />
            </Col>
            <Col md={4}>
              <BarChart
                comparisonData={comparisonData}
                attribute={'status'}
                selectedUseCase={selectedUseCase}
                handleChartClick={handleChartHover}
                handleChartHover={handleChartHover}
              />
            </Col>
          </Row>
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2 style={{ borderBottom: '2px solid #ccc', paddingBottom: '5px' }}>Actors</h2>
          <p>The modeled actors are assigned categories shown in the bar chart. Subsequent table provides further insights into how the actors distribute across modeled categories.</p>
          <ActorBarChart list={selectedUseCase.actors.value.list} handleChartClick={handleChartHover} handleChartHover={handleChartHover} />
          <ActorListTable list={selectedUseCase.actors.value.list} />
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2 style={{ borderBottom: '2px solid #ccc', paddingBottom: '5px' }}>Components</h2>
          <p>The modeled components are assigned categories shown in the bar chart. Subsequent table provides further insights into how the components distribute across modeled categories.</p>
          <ComponentBarChart components={selectedUseCase.components.value} handleChartClick={handleChartHover} handleChartHover={handleChartHover} />
          <ComponentBarChartNested components={selectedUseCase.components.value} handleChartClick={handleChartHover} handleChartHover={handleChartHover} />
          <ComponentListTable components={selectedUseCase.components.value} />
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2 style={{ borderBottom: '2px solid #ccc', paddingBottom: '5px' }}>Use Case Comparison</h2>
          <p>Compare the selected use case against the ecosystem.</p>
        </section>
      </div>
    </Col>
  </Row>
);