/**
 * Survey: Question Set (QS) Value
 *
 * Description: The QS Value provides questions with regards to the added value of the UCM tool.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-13 (updated on Oct 15)
 */

import React, { useState} from 'react';
import { Row, Form, Button } from 'react-bootstrap';

import { getCurrentDateTime } from '../../utils/utils_date';

export default function SurveyRankedFeatures({ aspects, setSurveyData, studyExpertData }) {

    const [rankedAspects, setRankedAspects] = useState(aspects);

    // Move aspect up
    const moveUp = (index) => {
        if (index === 0) return; // Prevent moving the first item up
        const updatedRanks = [...rankedAspects];
        [updatedRanks[index - 1], updatedRanks[index]] = [updatedRanks[index], updatedRanks[index - 1]];
        setRankedAspects(updatedRanks);
        updateSurveyData(updatedRanks);
    };

    // Move aspect down
    const moveDown = (index) => {
        if (index === rankedAspects.length - 1) return; // Prevent moving the last item down
        const updatedRanks = [...rankedAspects];
        [updatedRanks[index], updatedRanks[index + 1]] = [updatedRanks[index + 1], updatedRanks[index]];
        setRankedAspects(updatedRanks);
        updateSurveyData(updatedRanks);
    };

    // Update surveyData with new ranked aspects
    const updateSurveyData = (updatedRanks) => {
        setSurveyData((prev) => ({
            ...prev,
            addedValue: {
                ...prev.addedValue,
                ucm_ranked_aspects: {
                    ...prev.addedValue.ucm_ranked_aspects,
                    answer: updatedRanks,
                    anonymous_id: studyExpertData?.anonymous_id ?? '',
                    created_timestamp: getCurrentDateTime(),
                }
            }
        }));
    };

    return (
        <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
            <Form.Label>
                <b>Rank the following features of the UCM method in terms of importance (1 being most important):</b>
            </Form.Label>
            <div style={{ marginTop: '30px', maxWidth: '700px' }}>
                <ol>
                    {rankedAspects.map((aspect, index) => (
                        <li key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '50px', textAlign: 'center', fontWeight: 'bold' }}>{index + 1}</span>
                            <span style={{ flex: 1 }}>{aspect}</span>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                disabled={index === 0}
                                onClick={() => moveUp(index)}
                                style={{ marginRight: '5px' }}
                            >
                                ↑
                            </Button>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                disabled={index === rankedAspects.length - 1}
                                onClick={() => moveDown(index)}
                            >
                                ↓
                            </Button>
                        </li>
                    ))}
                </ol>
            </div>
        </Form.Group>
    );
}

export const SurveyQSValue = ( { surveyData, setSurveyData, handleChange, currentStep, studyExpertData } ) => {

    const aspects = [
        'Four-step approach', 
        'Info View', 
        'Scenario Model', 
        'Actor Map', 
        'Component Diagram', 
        'LLM Layer (UCM Co-Pilot)', 
        'Domain-Specific Semantic Models',
    ];

    const getClarityLabel = (value) => {
        switch (value) {
            case '0':
                return 'Not clear';
            case '1':
                return 'Not so clear';
            case '2':
                return 'Somewhat clear';
            case '3':
                return 'Clear';
            case '4':
                return 'Very clear';
            default:
                return 'Somewhat clear'; // Default label
        }
    };

    const getUsefulnessLabel = (value) => {
        switch (value) {
            case '0':
                return 'Not useful';
            case '1':
                return 'Neglectable';
            case '2':
                return 'Somewhat useful';
            case '3':
                return 'Useful';
            case '4':
                return 'Very useful';
            default:
                return 'Somewhat useful'; // Default label
        }
    }

    return (
        <>
            {/* Question 1: Understanding of the Approach */}
            {currentStep === 2 && (
                <Row align="center">
                    
                    <h3 style={{ marginTop: '30px', marginBottom: '20px' }}>Understanding of the UCM Method</h3>
                    <p style={{ maxWidth: '700px', margin: '0 auto', marginBottom: '20px' }}>
                        How would you classify your obtained understanding of the UCM method and its key components (four-step approach) within this study.
                    </p>

                    {/* Rate: Slider for Importance/Impact */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>How would you rate the usefulness of the implemented<br /> UCM four-step approach for use case modeling?</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', maxWidth: '500px' }}>
                        <span style={{ marginRight: '10px', fontSize: '12px', color: '#ff4d4d' }}>Not useful</span>
                        <Form.Control 
                            type="range" 
                            name="ucm_usefullness" 
                            min="0"                            
                            max="4" 
                            step="1"
                            value={surveyData.addedValue.ucm_usefullness.answer || 2} 
                            onChange={handleChange} 
                            style={{ 
                                background: 'linear-gradient(90deg, #ff4d4d, #28a745)',
                                borderRadius: '10px',
                                width: '100%',
                            }}
                        />
                        <span style={{ marginLeft: '10px', fontSize: '12px', color: '#28a745' }}>Very useful</span>
                        </div>

                        {/* Display current slider value dynamically below the slider */}
                        <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', fontWeight: 'bold' }}>
                            {getUsefulnessLabel(surveyData.addedValue.ucm_usefullness.answer)}
                        </div>
                    </Form.Group>

                    {/* UCM Method effectiveness: Likert Scale */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>How strongly do you agree with the following statement?<br />"The UCM method is effective for use case modeling."</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '700px', margin: '0 auto', marginTop:'30px' }}>
                            <Form.Check type="radio" label="Strongly disagree" name="ucm_effectiveness" value="Strongly disagree" onChange={handleChange} />
                            <Form.Check type="radio" label="Disagree" name="ucm_effectiveness" value="Disagree" onChange={handleChange} />
                            <Form.Check type="radio" label="Neutral" name="ucm_effectiveness" value="Neutral" onChange={handleChange} />
                            <Form.Check type="radio" label="Agree" name="ucm_effectiveness" value="Agree" onChange={handleChange} />
                            <Form.Check type="radio" label="Strongly agree" name="ucm_effectiveness" value="Strongly agree" onChange={handleChange} />
                        </div>
                    </Form.Group>

                    {/* Aspects */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>Which three aspects of the UCM method did you find most useful? (Select up to three).</b></Form.Label>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between', maxWidth: '700px', margin: '0 auto', marginTop:'30px' }}>
                            {aspects.map((aspect) => (
                                <div
                                    key={aspect}
                                    style={{
                                        padding: '5px 10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '20px',
                                        backgroundColor: surveyData.addedValue.ucm_most_useful_aspects.answer.includes(aspect) ? '#28a745' : '#f8f9fa',
                                        color: surveyData.addedValue.ucm_most_useful_aspects.answer.includes(aspect) ? '#fff' : '#000',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        const isSelected = surveyData.addedValue.ucm_most_useful_aspects.answer.includes(aspect);
                                        let newAspects;

                                        // Check if the aspect is already selected
                                        if (isSelected) {
                                            // Remove the aspect if it's already selected
                                            newAspects = surveyData.addedValue.ucm_most_useful_aspects.answer.filter((a) => a !== aspect);
                                        } else if (surveyData.addedValue.ucm_most_useful_aspects.answer.length < 3) {
                                            // Add the aspect if not already selected and less than 3 are selected
                                            newAspects = [...surveyData.addedValue.ucm_most_useful_aspects.answer, aspect];
                                        } else {
                                            // Do nothing if already 3 aspects are selected and user tries to add another
                                            return;
                                        }

                                        setSurveyData((prev) => ({ 
                                            ...prev, 
                                            addedValue: { 
                                                ...prev.addedValue, 
                                                ucm_most_useful_aspects: { 
                                                    ...prev.addedValue.ucm_most_useful_aspects, 
                                                    answer: newAspects,
                                                    anonymous_id: studyExpertData?.anonymous_id ?? '',
                                                    created_timestamp: getCurrentDateTime(),
                                                } 
                                            } 
                                        }));
                                    }}
                                >
                                    {aspect}
                                </div>
                            ))}
                        </div>
                    </Form.Group>

                    {/* Received Approach Explanation (Range Slider) */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>How clear was the UCM method presented to you overall?</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', maxWidth: '500px', position: 'relative' }}>
                            <span style={{ marginRight: '10px', fontSize: '12px', color: '#ff4d4d' }}>Not clear</span>
                            <Form.Control
                                type="range"
                                name="ucm_method_clarity"
                                min="0"
                                max="4"
                                step="1"
                                value={surveyData.addedValue.ucm_method_clarity.answer || 2}
                                onChange={handleChange}
                                style={{
                                    background: 'linear-gradient(90deg, #ff4d4d, #28a745)',
                                    borderRadius: '10px',
                                    width: '100%',
                                }}
                            />
                            <span style={{ marginLeft: '10px', fontSize: '12px', color: '#28a745' }}>Very clear</span>
                        </div>

                        {/* Display current slider value dynamically below the slider */}
                        <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', fontWeight: 'bold' }}>
                            {getClarityLabel(surveyData.addedValue.ucm_method_clarity.answer)}
                        </div>
                    </Form.Group>

                    {/* Usefulness of the approach */}                
                    <SurveyRankedFeatures 
                        aspects={aspects} 
                        setSurveyData={setSurveyData}
                        studyExpertData={studyExpertData}
                    />

                    {/* Additional Comments */}
                    <Form.Group style={{ marginTop: '40px', marginBottom:'40px' }}>
                        <Form.Label><b>{surveyData.addedValue.ucm_additional_comments.question}</b></Form.Label>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', maxWidth: '500px' }}>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            name="additional_comments"
                            value={surveyData.addedValue.ucm_additional_comments.answer}
                            onChange={
                                (e) => {
                                    setSurveyData((prev) => ({
                                        ...prev,
                                        addedValue: {
                                            ...prev.addedValue,
                                            ucm_additional_comments: {
                                                ...prev.addedValue.ucm_additional_comments,
                                                answer: e.target.value,
                                                anonymous_id: studyExpertData?.anonymous_id ?? '',
                                                created_timestamp: getCurrentDateTime(),
                                            }
                                        }
                                    }));
                                }
                            }
                            placeholder="Your feedback here..."
                        />
                        </div>
                    </Form.Group>

                </Row>
            )}
    </>
    );
}