/**
 * Use Case Repository
 *
 * Description: Provides all modeled and stored use cases in the repository.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-09
 * 
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import renderCardView from './uc_stacked_view';
import renderAccordionView from './uc_list_view';

import { UserInformation } from '../auth_mgmt/auth_handling';

//import AnalyticsUCDashboard from '../analytics_handling/analytics_uc_dashboard'; //uc_inspect';
import EcoUCDashboard from '../eco_analytics_handling/eco_uc_dashboard';

import { fetchUseCaseData } from '../db_mgmt/read';
import { deleteUseCaseData } from '../db_mgmt/write';
import UCModalDelete from './uc_modal_delete';

import { transformToNewStructure } from '../db_mgmt/transform_data';
import { FilterSearchBar } from './uc_filter_bar';

const filterUserUseCases = (useCases, userId) => {
    return useCases.filter(useCase => useCase.user_id.value === userId);
};

const RepositoryView = ({ showUserCases, setShowUserCases }) => { // data, setData, useCaseDetails

    const [useCases, setUseCases] = useState([]);
    const [filteredUseCases, setFilteredUseCases] = useState([]);
    // used for selecting multiple use cases
    const [selectedUseCases, setSelectedUseCases] = useState([]);

    // load spinner
    const [loading, setLoading] = useState(true);
    
    // used for inspecting a use case
    //const [selectedUseCaseId, setSelectedUseCaseId] = useState(null);
    const [viewMode, setViewMode] = useState('card'); // 'card', 'accordion', 'inspect'

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const maxItemsAccordion = 6;
    const maxItemsCard = 9;
    const itemsPerPage = (viewMode === 'card') ? maxItemsCard : maxItemsAccordion;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredUseCases.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredUseCases.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const getUserID = () => {
        return UserInformation().uid || '';
    };

    const [isDelete, setIsDelete] = useState(false);
    const [deleteUseCase, setDeleteUseCase] = useState({});

    // filter states
    const [uniqueCreator, setUniqueCreator] = useState([]);
    const uniqueKeywords = [...new Set(useCases.flatMap(useCase => useCase.tags.value))]; // currentItems

    // Individual use case inspection / dashboard handling
    const [selectedUseCase, setSelectedUseCase] = useState({});
    const [comparisonType, setComparisonType] = useState('all'); // initial state in the dashboard view
    const [selectedComparisonUseCases, setSelectedComparisonUseCases] = useState([]);

    const navigate = useNavigate();

    //const [useCaseData, setUseCaseData] = useState([]);
    /*
    useEffect(() => {
        const getData = async () => {
          const data = await fetchUseCaseData();
          setUseCases(data);
          setFilteredUseCases(data);
        };
    
        getData();        
    }, []);
    */

    useEffect(() => {
        const getData = async () => {
            setLoading(true); // Start loading
            const data = await fetchUseCaseData(); // Fetch the data
        
            // Transform all use cases to the new structure if needed
            const transformedData = data.map(useCase => transformToNewStructure(useCase));
            //console.log('Transformed data:', transformedData);
            setUseCases(transformedData);
            setFilteredUseCases(transformedData);
            setLoading(false); // Stop loading
        };
      
        getData();
    }, []);

    useEffect(() => {
        if (showUserCases) {
          const userId = getUserID();
          const userUseCases = filterUserUseCases(useCases, userId);
          setFilteredUseCases(userUseCases);
        } else {
          setFilteredUseCases(useCases);
        }
    }, [showUserCases, useCases]);

    // get unique list of users
    useEffect(() => {
        setUniqueCreator([...new Set(useCases.map(useCase => useCase.user.value))]);
    }, [useCases]);

    // Filter use cases

    // Combine Filters
    /*
    useEffect(() => {
        let filtered = useCases;

        if (showUserCases) {
            filtered = filterUserUseCases(useCases, getUserID());
        }

        // Filter by keyword
        if (filterKeyword) {
            filtered = filtered.filter(useCase => 
                useCase.tags.value.some(tag => tag.toLowerCase().includes(filterKeyword.toLowerCase()))
            );
        }

        // Filter by date
        if (filterDate) {
            filtered = filtered.filter(useCase => {
                const createdDate = formatTimestamp(useCase.created?.value);                
                return createdDate === filterDate;
            });
        }

        // Filter by selected tags (multi-select)
        if (selectedTags.length > 0) {
            filtered = filtered.filter(useCase => 
                selectedTags.some(tag => useCase.tags.value.includes(tag))
            );
        }

        // Filter by city
        if (selectedCity) {
            filtered = filtered.filter(useCase => 
                Array.isArray(useCase.city.value) && useCase.city.value.some(city => city === selectedCity)
            );
        }

        // Filter by country
        if (selectedCountry) {
            filtered = filtered.filter(useCase => 
                Array.isArray(useCase.country.value) && useCase.country.value.some(country => country === selectedCountry)
            );
        }

        // Filter by continent
        if (selectedContinent) {
            filtered = filtered.filter(useCase => 
                Array.isArray(useCase.continents.value) && useCase.continents.value.some(continent => continent === selectedContinent)
            );
        }

        // Filter by creator/user
        if (selectedCreator) {
            filtered = filtered.filter(useCase => useCase.user.value === selectedCreator);
        }        

        setFilteredUseCases(filtered);

    }, [filterKeyword, filterDate, selectedTags, selectedCreator, useCases, showUserCases, selectedCity, selectedCountry, selectedContinent]);
    */

    // Toggle between card and accordion view as well as user view

    const handleToggleView = () => {
        setViewMode(viewMode === 'card' ? 'accordion' : 'card');

        const newItemsPerPage = (viewMode === 'card') ? maxItemsCard : maxItemsAccordion;   
        const totalPages = Math.ceil(useCases.length / newItemsPerPage);
        setCurrentPage((prevPage) => (prevPage > totalPages ? totalPages : prevPage));
    };

    const handleToggleUserCases = () => {
        setShowUserCases(prevShow => !prevShow);
    };

    const handleInspectUseCase = (id) => {
        //setSelectedUseCaseId(id);

        setSelectedUseCase(useCases.find(useCase => useCase.id === id));

        setViewMode('inspect');
    };

    const handleUseAsTemplate = (id) => {
        let template = useCases.find(useCase => useCase.id === id);     
        //setSelectedUseCaseId(id);
        navigate('/Create', { state: { template } });
    };

    // Handle delete use case, filter the use case to be deleted
    const handleDeleteUseCase = (id) => {
        setDeleteUseCase(useCases.filter(useCase => useCase.id === id));
        setIsDelete(true);
    };

    // Update the use case data in the database
    const handleSubmitDelete = async () => {
        if (!deleteUseCase) return;
        
        try {
        await deleteUseCaseData(deleteUseCase[0].id);

        console.log('Use case data updated successfully!');      
        } catch (error) {
        console.error('Error updating use case data:', error);
        }     
        
        setIsDelete(false);
    };

    const handleCloseDeleteModal = () => {
        setIsDelete(false);
    };

    const handleCheckboxChange = (id) => {
        setSelectedUseCases((prevSelected) =>
        prevSelected.includes(id)
            ? prevSelected.filter((useCaseId) => useCaseId !== id)
            : [...prevSelected, id]
        );
    };

    /* Retrieve displayed use cases per page */
    const displayedUseCases = currentItems;
      
    return (
        <div>
            <Row style={ { marginBottom:'15px'} }>
                {/* Headline */}
                <Col md={1}></Col>
                <Col md={5}>
                    <div>
                        <h3 className="text-start">Modeled Use Cases</h3>
                        <h6 className="text-start">Look up, compare or use as template</h6>
                    </div>
                </Col>  
                <Col md={5} className="d-flex justify-content-end align-items-center">
                    {/* toolbar */}
                    <div className="d-flex justify-content-end">                   
                        {(viewMode === 'inspect') && (
                            <Button variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={() => setViewMode('card')}>
                                <i className="bi bi-arrow-left"></i>
                            </Button>
                        )}                        
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>
            {/* use cases */}
            <Row>
                <Col md={1}></Col>
                <Col md={10} style={{ textAlign: 'left' }}>
                    
                    {viewMode !== 'inspect' && (
                    <FilterSearchBar 
                        useCases={useCases}
                        uniqueCreators={uniqueCreator}
                        uniqueKeywords={uniqueKeywords}
                        filteredUseCases={filteredUseCases}
                        setFilteredUseCases={setFilteredUseCases}
                        viewMode={viewMode}
                        handleToggleView={handleToggleView}
                        showUserCases={showUserCases}
                        handleToggleUserCases={handleToggleUserCases}
                    />            
                    )}

                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                    <>
                    {viewMode === 'card' && (
                        renderCardView(
                            displayedUseCases, 
                            selectedUseCases,
                            handleCheckboxChange, 
                            handleUseAsTemplate, 
                            getUserID, 
                            handleDeleteUseCase, 
                            handleInspectUseCase
                        )
                    )}

                    {viewMode === 'accordion' && (
                        renderAccordionView(
                            displayedUseCases, 
                            selectedUseCases, 
                            handleCheckboxChange, 
                            handleInspectUseCase, 
                            handleUseAsTemplate, 
                            getUserID, 
                            handleDeleteUseCase
                        )
                    )}

                    </>
                    )}

                    {viewMode === 'inspect' && (
                        //<InspectUC data={useCases[0]} /> // setData={setData} useCaseDetails={useCaseDetails}
                        //<AnalyticsUCDashboard data={useCases.find(useCase => useCase.id === selectedUseCaseId)} />
                        <EcoUCDashboard
                            mode={'Dashboard'}
                            data={useCases} 
                            selectedUseCase={selectedUseCase} 
                            setSelectedUseCase={setSelectedUseCase}
                            comparisonType={comparisonType}
                            setComparisonType={setComparisonType}
                            selectedComparisonUseCases={selectedComparisonUseCases}
                            setSelectedComparisonUseCases={setSelectedComparisonUseCases}
                        />
                    )}

                </Col>
                <Col md={1}></Col>
                {/* pagination */}
                {viewMode !== 'inspect' && (
                <Row>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">

                                {/* Previous Button */}
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        onClick={() => paginate(currentPage - 1)}
                                        className="page-link"
                                        style={{
                                        textDecoration: 'none',
                                        cursor: 'pointer'
                                        }}
                                        disabled={currentPage === 1}
                                    >
                                        &laquo; Previous
                                    </button>
                                </li>

                                {/* Page Numbers */}
                                {pageNumbers.map(number => (
                                <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                                    <button
                                        onClick={() => paginate(number)}
                                        className="page-link"
                                        style={{                         
                                            textDecoration: 'none',
                                            cursor: 'pointer'
                                        }}
                                        >
                                        {number}
                                    </button>
                                </li>
                                ))}

                                {/* Next Button */}
                                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                                    <button
                                        onClick={() => paginate(currentPage + 1)}
                                        className="page-link"
                                        style={{
                                        textDecoration: 'none',
                                        cursor: 'pointer'
                                        }}
                                        disabled={currentPage === pageNumbers.length}
                                    >
                                        Next &raquo;
                                    </button>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </Row>
                )}
            </Row>            
            {/* show modal to save the modeled use case */}
            {isDelete && (
                <UCModalDelete
                    onClose={handleCloseDeleteModal}
                    uc_name={deleteUseCase[0].title.value}
                    handleDelete={handleSubmitDelete}
                />
            )}
        </div>
    );
};

export default RepositoryView;
