/**
 * Generate a use case report
 *
 * Description: ...
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-21
 * 
 */

import React, { useState, useEffect } from 'react';

import { Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { pdf } from '@react-pdf/renderer';

import { ReportHTML } from './uc_report_html';
import { ReportPDF, ReportPDFViewer } from './uc_report_pdf';

import { categorizeUseCases, calculateMatchingScore } from '../analytics_handling/analytics_prepare_data';

export const Report = ({ data, comparisonData, selectedUseCase }) => {

  const [isHTMLView, setIsHTMLView] = useState(true);
  const [isPDFPreview, setIsPDFPreview] = useState(false);

  const [stats, setStats] = useState({ similar: 0, distinct: 0, overlapping: 0 });
  const [matchingScore, setMatchingScore] = useState(0);
  
  const generatePDF = async () => {
      const blob = await pdf(
        <ReportPDF 
          selectedUseCase={selectedUseCase}
          comparisonData={comparisonData}
          stats={stats}
          matchingScore={matchingScore}
          handleChartHover={handleChartHover}
        />).toBlob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'use_case_report.pdf';
      link.click();
  };

  const togglePDFPreview = () => {
      setIsPDFPreview(!isPDFPreview);
      setIsHTMLView(false);        
  }

  const toggleHTMLView = () => {
      setIsHTMLView(!isHTMLView);
      setIsPDFPreview(false);
  }

  // Handling for statistics: score, similar, distinct, overlapping
  useEffect(() => {
      if (selectedUseCase && data.length > 0) {
          const categorizedStats = categorizeUseCases(selectedUseCase, data);
          setStats(categorizedStats);

          const score = calculateMatchingScore(selectedUseCase, data);
          setMatchingScore(score);
      }
  }, [selectedUseCase, data]);

  const handleChartHover = (evt, elems) => {
      if (elems.length > 0) {
        evt.native.target.style.cursor = 'pointer';
      } else {
        evt.native.target.style.cursor = 'default';
      }
  };

  return (
    <Row style={{ marginTop: '20px' }}>
      {/* Use Case Insights - General Statistics */}
      <Row>
        <Col md={12}>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <h5>Generate Use Case Report</h5>
            </Col>
            <Col className="d-flex justify-content-end">
                {!isHTMLView && (
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Toggle view between PDF Viewer and Report View</Tooltip>}>
                        <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} onClick={toggleHTMLView}>
                        <i className="bi bi-file-bar-graph"></i> HTML Report
                        </Button>
                    </OverlayTrigger>
                )}
                {!isPDFPreview && (
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Toggle view between PDF Viewer and Report View</Tooltip>}>
                        <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} onClick={togglePDFPreview}>
                        <i className="bi bi-filetype-pdf"></i> Preview PDF
                        </Button>
                    </OverlayTrigger>
                )}                
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Download the Use Case Report as a PDF file</Tooltip>}>
                <Button variant="success" className="btn-sm" style={{ marginRight: '10px' }} onClick={generatePDF}>
                  <i className="bi bi-download"></i> Download PDF
                </Button>
              </OverlayTrigger>
              <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                <i className="bi bi-info-circle"></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isHTMLView && (
        <Row>
            <Col md={12}>
                <ReportHTML selectedUseCase={selectedUseCase} comparisonData={comparisonData} stats={stats} matchingScore={matchingScore} handleChartHover={handleChartHover} />
            </Col>
        </Row>
    )}
    {isPDFPreview && (
    <Row>
        <Col md={12}>
          <ReportPDFViewer selectedUseCase={selectedUseCase} comparisonData={comparisonData} stats={stats} matchingScore={matchingScore} handleChartHover={handleChartHover} />
        </Col>
    </Row>
    )}
    </Row>
  );
};
