/**
 * Analyze how the use case elements classifies
 *
 * Description: Visualize the distribution of the use case elements in the ecosystem.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-17
 * 
 */

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { ActorRelationshipChart } from './analytics_charts';

export const UCDistribution = ({ data, selectedUseCase, analysisResults, comparisonData, setModalData, setShowModal }) => {

    return (
        <>
        <Row style={ { marginTop:'20px'} }>
            <Col md={12}>
                <Row style={ { marginBottom:'20px'} }>
                    Actor Relationship Analysis
                    <Col className="d-flex justify-content-end">
                    <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                        <i className="bi bi-info-circle"></i>
                    </Button>
                    </Col>                
                </Row>     
            </Col>
        </Row>    
        {/* Actor relation analysis */}
        <Row style={ { marginBottom:'20px'} }>
            <Col md={12}>
                {/* Actor relation analysis */}
                <ActorRelationshipChart useCase={selectedUseCase} />
            </Col>
        </Row>
        </>
    );
}