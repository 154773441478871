/**
 * Chart elements for the analytics page
 *
 * Description: Provides the necessary chart elements for the analytics page.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-14
 * 
 */

import React from 'react';
import { Row, Col, Card, Table, ListGroup } from 'react-bootstrap';

import { Bar, Pie, Scatter, Bubble } from 'react-chartjs-2';

import { prepareBarChartData, preparePieChartData, prepareBarData, prepareOverlapData, prepareBarChartDataComponents, prepareBarChartDataComponentsNested, prepareBarChartActor } from '../analytics_handling/analytics_prepare_data';

// Bar Chart: Use Case Comparison
export const BarChart = ({ comparisonData, attribute, selectedUseCase, handleChartClick, handleChartHover }) => {

  const cmp_data = prepareBarChartData(comparisonData, attribute, selectedUseCase);

  const chartData = {
      labels: cmp_data.map(d => d[attribute]),
      datasets: [
          {
              label: attribute || 'Number of Use Cases',
              data: cmp_data.map(d => d.count),  // Always use the actual count for the bar height
              backgroundColor: cmp_data.map(d => d.isSelected ? 'rgba(255,99,132,0.6)' : 'rgba(75,192,192,0.6)'),
              borderColor: cmp_data.map(d => d.isSelected ? 'rgba(255,99,132,1)' : 'rgba(75,192,192,1)'),
              borderWidth: 1,
          }
      ]
  };

  return (
      <Bar
          data={chartData}
          options={{
              onClick: (evt, elems) => handleChartClick(evt, elems, chartData, attribute),
              onHover: (evt, elems) => handleChartHover(evt, elems),
          }}
      />
  );
};

// Pie Chart: Use Case Comparison
export const PieChart = ({ comparisonData, attribute }) => {

    const cmp_data = preparePieChartData(comparisonData, attribute);

    const chartData = {
        labels: cmp_data.map(d => d[attribute]),
        datasets: [
        {
            label: attribute || 'Number of Use Cases',
            data: cmp_data.map(d => d.count),
            backgroundColor: [
            'rgba(75,192,192,0.6)',
            'rgba(192,75,75,0.6)',
            'rgba(75,75,192,0.6)',
            ],
            borderColor: 'rgba(255,255,255,1)',
            borderWidth: 1,
        }
        ]
    };

  return <Pie data={chartData} />;
};

// Component List Table
export const ComponentListTable = ({ components }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Category</th>
          <th>Component</th>
          <th>Items</th>
        </tr>
      </thead>
      <tbody>
        {components.map((componentCategory, index) => (
          <React.Fragment key={index}>
            {componentCategory.components.map((component, i) => (
              <tr key={`${index}-${i}`}>
                {i === 0 && (
                  <td rowSpan={componentCategory.components.length}>
                    {componentCategory.category}
                  </td>
                )}
                <td>{component.description || component.id}</td>
                <td>
                  {component.items && component.items.map((item, j) => (
                    <span key={j} className="badge bg-light text-dark me-1">
                      {item}
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

// Component Bar Chart
export const ComponentBarChart = ({ components, handleChartClick, handleChartHover }) => {
  const chartData = prepareBarChartDataComponents(components);

  return (
    <div>
      <Bar 
        data={chartData} 
        options={{ 
          //responsive: true, 
          //maintainAspectRatio: false,
          scales: {
            x: { title: { display: true, text: 'Categories' } },
            y: { title: { display: true, text: 'Number of Items' } }
          },          
          onClick: (evt, elems) => handleChartClick(evt, elems, chartData, 'Components per Category'),
          onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
      />
    </div>
  );
};

// Component Bar Chart Nested
export const ComponentBarChartNested = ({ components, handleChartClick, handleChartHover }) => {
  const chartData = prepareBarChartDataComponentsNested(components);

  return (
    <div>
      <Bar 
        data={chartData} 
        options={{ 
          //responsive: true, 
          //maintainAspectRatio: false,
          scales: {
            x: { 
              title: { display: true, text: 'Subcategories' },
              ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 45
              }
            },
            y: { 
              title: { display: true, text: 'Number of Items' },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                min: 0,
              }
            }
          },        
          onClick: (evt, elems) => handleChartClick(evt, elems, chartData, 'Components per Category'),
          onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
      />
    </div>
  );
};

// Actor List Table
export const ActorListTable = ({ list }) => {
  
  const categories = Object.keys(list);
  
  return (
      <Table striped bordered hover>
      <thead>
          <tr>
          <th>Category</th>
          <th>Actors</th>
          </tr>
      </thead>
      <tbody>
          {categories.map((category, index) => (
          <tr key={index}>
              <td>{category}</td>
              <td>
              {list[category].value.map((actor, i) => (
                  <span key={i} className="badge bg-light text-dark me-1">
                  {actor}
                  </span>
              ))}
              </td>
          </tr>
          ))}
      </tbody>
      </Table>
  );
};

export const ActorBarChart = ({ list, handleChartClick, handleChartHover }) => {
  const chartData = prepareBarChartActor(list);

  return (
    <div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: { title: { display: true, text: 'Categories' } },
            y: { title: { display: true, text: 'Number of Actors' }, beginAtZero: true }
          },
          onClick: (evt, elems) => handleChartClick(evt, elems, chartData, 'Actors per Category'),
          onHover: (evt, elems) => handleChartHover(evt, elems),
        }}
      />
    </div>
  );
};

// Actor analysis
export const ActorRelationshipChart = ({ useCase }) => {

  const { nodes, edges, list } = useCase.actors;

  // Calculate the degree of each node
  const nodeDegree = {};
  edges.forEach(edge => {
    nodeDegree[edge.from] = (nodeDegree[edge.from] || 0) + 1;
    nodeDegree[edge.to] = (nodeDegree[edge.to] || 0) + 1;
  });

  const colors = [
    'rgba(75,192,192,0.4)', 'rgba(75,192,192,0.6)', 'rgba(75,192,192,0.8)', 'rgba(75,192,192,1)',
    'rgba(153,102,255,0.4)', 'rgba(153,102,255,0.6)', 'rgba(153,102,255,0.8)', 'rgba(153,102,255,1)',
    'rgba(255,159,64,0.4)', 'rgba(255,159,64,0.6)', 'rgba(255,159,64,0.8)', 'rgba(255,159,64,1)'
  ];

  const categories = Object.keys(list);
  const categoryPositions = categories.reduce((acc, category, index) => {
    acc[category] = (index + 1) * 20; // Assign a unique x position for each category
    return acc;
  }, {});

  // Generate unique random y positions
  const generateUniqueRandomY = (existingYValues) => {
    let y;
    do {
      y = Math.random() * 100;
    } while (existingYValues.has(y));
    existingYValues.add(y);
    return y;
  };

  const existingYValues = new Set();

  // Prepare data for the bubble chart
  const chartData = {
    datasets: nodes.map((node, index) => ({
      label: node.label,
      data: [{
        x: categoryPositions[node.group], // Position x based on the category
        y: generateUniqueRandomY(existingYValues), //nodeDegree[node.id] || 1, // Position y based on the degree
        r: (nodeDegree[node.id] || 1) * 5  // Bubble radius based on degree
      }],
      backgroundColor: colors[index % colors.length],
      borderColor: colors[index % colors.length],
      borderWidth: 1
    }))
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Actor Categories'
        },
        ticks: {
          callback: (value) => {
            const index = value / 20 - 1;
            return categories[index] || '';
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Number of Relations'
        },
        min: 0,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.raw.r / 5;
            return `${label}: ${value} relations`;
          }
        }
      }
    }
  };

  return (
    <div style={{ height: '600px', width: '100%' }}>
      <Bubble data={chartData} options={options} />
    </div>
  );
};

// Node centrality
export const NodeCentrality = ({ nodes, edges }) => {
  const centralityScores = calculateCentrality(nodes, edges);

  const barData = {
    labels: centralityScores.map(score => score.node.label),
    datasets: [
      {
        label: 'Centrality',
        data: centralityScores.map(score => score.value),
        backgroundColor: 'rgba(153,102,255,0.4)',
        borderColor: 'rgba(153,102,255,1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Bar data={barData} /> //options={{ responsive: true, maintainAspectRatio: false }} />
  );
};

const calculateCentrality = (nodes, edges) => {
  // Create a map to store centrality scores for each node
  const centralityMap = {};

  // Initialize the map with each node having a centrality score of 0
  nodes.value.forEach(node => {
    centralityMap[node.id] = 0;
  });

  // Iterate over the edges and increase the centrality score for each connected node
  edges.value.forEach(edge => {
    centralityMap[edge.from]++;
    centralityMap[edge.to]++;
  });

  // Return centrality scores for each node
  return nodes.value.map(node => ({
    node,
    value: centralityMap[node.id] || 0, // Use 0 if no edges are connected
  }));
};

// Top connected nodes
export const TopConnectedNodes = ({ nodes, edges }) => {
  const nodeDegrees = nodes.value.map(node => {
    const degree = edges.value.filter(edge => edge.from === node.id || edge.to === node.id).length;
    return { ...node, degree };
  });

  const sortedNodes = nodeDegrees.sort((a, b) => b.degree - a.degree).slice(0, 5);

  return (
    <ListGroup>
      {sortedNodes.map(node => (
        <ListGroup.Item key={node.id}>
          <strong>{node.label}</strong> - {node.degree} connections
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

// -----------------------------

// Comparison Charts

export const ComparisonCharts = ({ analysisResults, selectedUseCase }) => {
  return (
    <div>
      <Row style={{ marginTop: '20px' }}>
      <Col md={6}>
        <Card className="h-100">
          <Card.Header>Similar Use Cases</Card.Header>
          <Card.Body>
            <Bar data={prepareBarData(analysisResults.similarUseCases)} options={{ responsive: true, maintainAspectRatio: false }} />
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <Card className="h-100">
          <Card.Header>Distinct Use Cases</Card.Header>
          <Card.Body>
            <Bar data={prepareBarData(analysisResults.distinctUseCases)} options={{ responsive: true, maintainAspectRatio: false }} />
          </Card.Body>
        </Card>
      </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col md={6}>
          <Card className="h-100">
            <Card.Header>Overlapping Actors</Card.Header>
            <Card.Body>
              <Bar data={prepareOverlapData(analysisResults.overlaps)} options={{ responsive: true, maintainAspectRatio: false }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100">
            <Card.Header>Overlapping Components</Card.Header>
            <Card.Body>
              <Bar data={prepareOverlapData(analysisResults.overlaps)} options={{ responsive: true, maintainAspectRatio: false }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

// -----------------------------

// Outlier Analysis

// -----------------------------

// ...

// Scatter Plot Component
export const ScatterPlot = ({ data }) => {
  const chartData = {
    datasets: [
      {
        label: 'Use Case Comparison',
        data: data.map(d => ({ x: d.x, y: d.y, label: d.label })),
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      }
    ]
  };

  return <Scatter data={chartData} />;
};

