/**
 * Use Case Repository: Filter Top Bar
 *
 * Description: Filter top bar component for the repository page.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-09-20
 * 
 */

import React, { useState } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';

import ModalCreateMode from '../create_handling/create_modal_mode';

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
};

export const FilterSearchBar = ({ useCases, uniqueCreators, uniqueKeywords, filteredUseCases, setFilteredUseCases, viewMode, handleToggleView, showUserCases, handleToggleUserCases }) => {

    const [activeFilters, setActiveFilters] = useState([]);  // List of active filters (e.g., creator: John)
    const [selectedFilterType, setSelectedFilterType] = useState(null); // Currently selected filter type (e.g., Creator)
    const [filterValues, setFilterValues] = useState({});  // Stores selected values for each filter type (e.g., {creator: 'John', city: 'Berlin'})
    const [showModalCreateMode, setShowModalCreateMode] = useState(false);

    const filterOptions = [
        { value: 'application', label: 'Application area' },
        { value: 'city', label: 'City' },
        { value: 'country', label: 'Country' },
        { value: 'continents', label: 'Continent' },
        { value: 'creator', label: 'Creator' },     
        { value: 'date', label: 'Date' },                   
        { value: 'score', label: 'Score' },    
        { value: 'status', label: 'Status' },    
        { value: 'tags', label: 'Tags' },
        { value: 'maturity', label: 'Maturity level' },        
    ];
    
    const handleFilterSelect = (selectedOption) => {
        setSelectedFilterType(selectedOption);  // Update the selected filter type (Creator, City, etc.)
    };

    const handleValueSelect = (selectedValue) => {
        if (!selectedFilterType) return;
    
        const newFilter = { key: selectedFilterType.value, value: selectedValue.value };
        
        // Update active filters
        const updatedFilters = [...activeFilters, newFilter];
        setActiveFilters(updatedFilters);
    
        setFilterValues({
            ...filterValues,
            [selectedFilterType.value]: selectedValue.value
        });
    
        // Apply the filters after selection
        applyFilters(updatedFilters);
    
        setSelectedFilterType(null); // Reset the filter type to allow selecting a new one
    };

    const handleRemoveFilter = (key) => {

        const updatedFilters = activeFilters.filter(filter => filter.key !== key);
        setActiveFilters(updatedFilters);
    
        const updatedFilterValues = { ...filterValues };
        delete updatedFilterValues[key];
        setFilterValues(updatedFilterValues);
    
        // Apply the filters after removing
        applyFilters(updatedFilters);
    };

    const handleResetFilters = () => {
        setActiveFilters([]);
        setFilterValues({});
        setFilteredUseCases(useCases);
    };

    // Apply the combined filter to useCases
    const applyFilters = (filters) => {
        let filtered = useCases;

        filters.forEach((filter) => {
            switch (filter.key) {
                case 'creator':
                    filtered = filtered.filter(useCase => useCase.user.value === filter.value);
                    break;
                case 'city':
                    filtered = filtered.filter(useCase => useCase.city.value.includes(filter.value));
                    break;
                case 'country':
                    filtered = filtered.filter(useCase => useCase.country.value.includes(filter.value));
                    break;
                case 'continent':
                    filtered = filtered.filter(useCase => useCase.continents.value.includes(filter.value));
                    break;
                case 'tags':
                    filtered = filtered.filter(useCase => useCase.tags.value.includes(filter.value));
                    break;
                case 'date':
                    filtered = filtered.filter(useCase => {
                        const createdDate = formatTimestamp(useCase.created.value);
                        return createdDate === filter.value;
                    });
                    break;
                case 'score':
                    break;
                case 'application':
                    filtered = filtered.filter(useCase => useCase.application.value.includes(filter.value));
                    break;
                case 'maturity':
                    filtered = filtered.filter(useCase => useCase.maturity.value.includes(filter.value));
                    break;
                case 'status':
                    filtered = filtered.filter(useCase => useCase.status.value.includes(filter.value));                    
                    break;
                default:
                    break;
            }
        });

        setFilteredUseCases(filtered);
    };

    // Options for values to be selected based on the selected filter type
    const getFilterValueOptions = () => {
        switch (selectedFilterType?.value) {
            case 'creator':
                return uniqueCreators.map(creator => ({ value: creator, label: creator }));
            case 'city':
            case 'country':
            case 'continents':
                return useCases
                    .flatMap(useCase => useCase[selectedFilterType.value].value)
                    .filter((value, index, self) => self.indexOf(value) === index) // Unique values
                    .map(value => ({ value, label: value }));
            case 'tags':
                return uniqueKeywords.map(tag => ({ value: tag, label: tag }));
            case 'date':
                return useCases
                    .map(useCase => formatTimestamp(useCase.created.value))
                    .filter((value, index, self) => self.indexOf(value) === index) // Unique dates
                    .sort((a, b) => new Date(b) - new Date(a)) // Sort in descending order
                    .map(date => ({ value: date, label: date }));
            case 'score':
                break;
            case 'application':
                return useCases
                    .flatMap(useCase => useCase.application.value)
                    .filter((value, index, self) => self.indexOf(value) === index) // Unique values
                    .map(value => ({ value, label: value }));
            case 'maturity':
                return useCases
                    .flatMap(useCase => useCase.maturity.value)
                    .filter((value, index, self) => self.indexOf(value) === index) // Unique values
                    .map(value => ({ value, label: value }));
            case 'status':
                return useCases
                    .flatMap(useCase => useCase.status.value)
                    .filter((value, index, self) => self.indexOf(value) === index) // Unique values
                    .map(value => ({ value, label: value }));                    
            default:
                return [];
        }
    };

    // Filter use cases based on the search keyword
    const handleSearch = (e) => {
    
        const searchKeyword = e.target.value.toLowerCase().trim();
    
        if (!searchKeyword) {
            setFilteredUseCases(useCases);
            return;
        }
    
        // Filter the use cases based on the search keyword
        const filtered = useCases.filter(useCase => {
            const titleMatch = useCase.title?.value?.toLowerCase().includes(searchKeyword);
            const acronymMatch = useCase.acronym?.value?.toLowerCase().includes(searchKeyword);
            const descriptionMatch = useCase.description?.value?.toLowerCase().includes(searchKeyword);
            const tagsMatch = useCase.tags?.value?.some(tag => tag.toLowerCase().includes(searchKeyword));
    
            return titleMatch || acronymMatch || descriptionMatch || tagsMatch;
        });
    
        setFilteredUseCases(filtered);
    };

    const handleSortDown = () => {
        setFilteredUseCases([...filteredUseCases].sort((a, b) => a.title.value.localeCompare(b.title.value)));
    };

    const handleSortUp = () => {
        setFilteredUseCases([...filteredUseCases].sort((a, b) => b.title.value.localeCompare(a.title.value)));
    };

    // creator, cockpit
    const handleCreateMode = () => {
        setShowModalCreateMode(true);
    }

    return (
        <div className="filter-container" style={ { marginBottom:'30px' } }>
            {/* Filter Bar */}
            <div className="filter-bar d-flex flex-wrap align-items-center mb-3">
                {/* Keyword Search */}
                <div className="filter-item">
                    <input
                        style={{ maxWidth: '300px' }}
                        type="text"
                        className="form-control"
                        placeholder="Search use case"
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleSearch(e);
                            }
                        }}
                        onChange={e => {
                            handleSearch(e);
                        }}
                    />
                </div>
    
                {/* Multi select tag filter */}
                <div className="filter-item">
                    <Select
                        isMulti
                        options={uniqueKeywords.map((tag) => ({ value: tag, label: tag }))}
                        value={activeFilters.filter(filter => filter.key === 'tags').map((filter) => ({ value: filter.value, label: filter.value }))}
                        onChange={(selectedOptions) => {
                            const newFilters = selectedOptions.map((option) => ({ key: 'tags', value: option.value }));
                            const updatedFilters = activeFilters.filter(filter => filter.key !== 'tags');
                            setActiveFilters([...updatedFilters, ...newFilters]);
                            applyFilters(newFilters);
                        }}
                    />
                </div>
    
                <span style={{ marginRight: '10px' }}>or</span>
    
                {/* Main input which acts as a dropdown to select filter types */}
                <div className="filter-item">
                    <Select
                        options={filterOptions}
                        placeholder="Select filter type"
                        value={selectedFilterType}
                        onChange={handleFilterSelect}
                    />
                </div>
    
                {/* Dynamic dropdown to select the value for the active filter */}
                {selectedFilterType && (
                    <div className="filter-item">
                        <Select
                            options={getFilterValueOptions()}
                            placeholder={`Select ${selectedFilterType.label}`}
                            onChange={handleValueSelect}
                        />
                    </div>
                )}
    
                <div className="ms-auto d-flex align-items-center">
                    
                    {/* Sort Alphabetically */}
                    <OverlayTrigger placement="top" overlay={<Tooltip>Sort alphabetically down</Tooltip>}>
                        <Button className='btn-sm' style={{ marginRight: '10px' }} variant="outline-secondary" onClick={handleSortDown}>
                            <i className="bi bi-sort-alpha-down"></i>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Sort alphabetically up</Tooltip>}>
                        <Button className='btn-sm' style={{ marginRight: '10px' }} variant="outline-secondary" onClick={handleSortUp}>
                            <i className="bi bi-sort-alpha-up"></i>
                        </Button>
                    </OverlayTrigger>
    
                    {/* Clear Filters */}
                    <OverlayTrigger placement="top" overlay={<Tooltip>Reset filters</Tooltip>}>
                        <Button className='btn-sm' variant="outline-secondary" style={{ marginRight:'10px' }} onClick={handleResetFilters}>
                            <i className="bi bi-x"></i>
                        </Button>
                    </OverlayTrigger>
    
                    <div style={{ borderLeft: '1px solid #dee2e6', height: '30px', marginLeft: '10px' }}></div>          
    
                    {(viewMode === 'card') && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>Show list view</Tooltip>}>
                            <Button className='btn-sm' variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleView}>
                                <i className="bi bi-view-list"></i> 
                            </Button>
                        </OverlayTrigger>
                    )}
    
                    {(viewMode === 'accordion') && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>Show card view</Tooltip>}>
                            <Button className='btn-sm' variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleView}>
                                <i className="bi bi-view-stacked"></i>
                            </Button>
                        </OverlayTrigger>
                    )}
    
                    {viewMode !== 'inspect' && (
                        <>
                            {showUserCases ? (
                                <OverlayTrigger placement="top" overlay={<Tooltip>Show all use cases</Tooltip>}>
                                    <Button className='btn-sm' variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleUserCases}>
                                        <i className="bi bi-boxes"></i>
                                    </Button>
                                </OverlayTrigger>    
                            ) : (
                                <OverlayTrigger placement="top" overlay={<Tooltip>Show own use cases</Tooltip>}>
                                    <Button className='btn-sm' variant="outline-secondary" style={{ marginLeft: '10px' }} onClick={handleToggleUserCases}>
                                        <i className="bi bi-box"></i>
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </>
                    )}
    
                    <div style={{ borderLeft: '1px solid #dee2e6', height: '30px', marginLeft: '10px' }}></div>          
    
                    <OverlayTrigger placement="top" overlay={<Tooltip>Create a new use case</Tooltip>}>
                        <Button className='btn-sm' style={{ marginLeft: '10px' }} variant="outline-secondary" onClick={handleCreateMode}>
                            <i className="bi bi-pencil-square"></i>
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
    
            {/* Active Filters below the filter bar */}
            {activeFilters.length > 0 && (
            <div className="active-filters d-flex flex-wrap align-items-center mt-3">
                {activeFilters.map((filter, idx) => (
                    <div key={idx} className="filter-tag d-flex align-items-center" style={{ marginRight: '5px' }}>
                        <span className="badge bg-dark mr-2">
                            {filter.key}: {filter.value}
                            {/* Remove filter button; show only if key !== tag */}
                            {filter.key !== 'tags' && (
                                <span
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    onClick={() => handleRemoveFilter(filter.key)}
                                >
                                    ×
                                </span>
                            )}
                        </span>
                    </div>
                ))}
            </div>      
            )}

            {/*
                LIST ALL TAGS AS BADGES
                
                {isFilterTags && (
                <>
                    <div className="text-center">

                        <div className="mt-3">
                            {uniqueKeywords.map((keyword, index) => (
                                <Badge
                                    key={index}
                                    bg={selectedTags.includes(keyword) ? "primary" : "light"}
                                    text={selectedTags.includes(keyword) ? "white" : "dark"}
                                    style={{ marginRight: '10px', cursor: 'pointer' }}
                                    onClick={() => handleTagSelection(keyword)}
                                >
                                    {keyword}
                                </Badge>
                            ))}
                        </div>
                    
                    </div>
                </>
                )}
            */}

            {/* Modal for Create Mode */}
            {showModalCreateMode && (
                <ModalCreateMode 
                show={showModalCreateMode} 
                onHide={() => setShowModalCreateMode(false)}         
                />  
            )}
        </div>
    );
};