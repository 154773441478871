/**
 * Ontology matching module
 * 
 * Description: Provides the functionaliy to match concepts to a given structure
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-08-09
 * 
 */

import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const MatchingModule = ( { enrichment, setEnrichment } ) => {

    // retrieve enrichment values
    const enrichmentValues = Object.values(enrichment);

    const handleSelectChange = (component, event) => {
        //const newMapping = { ...compSemanticEnrichment, [component]: event.target.value };
        //setCompSemanticEnrichment(newMapping);
    };

    return (
        <Row align='left'>
            <h4 style={ { marginBottom:'30px'} }>Map semantic model to component structure</h4>
                {Object.keys(enrichment).map((component, index) => (
                <Row key={index} className="mb-2">
                    <Col md={2}></Col>
                    <Col md={3}>
                        <p>{component}</p>
                    </Col>
                    <Col>
                    <Form.Control as="select" value={enrichment[component]} onChange={(e) => handleSelectChange(component, e)} disabled>
                        <option value="">Select a concept</option>
                        {enrichmentValues.map((concept, idx) => (
                        <option key={idx} value={concept}>{concept}</option>
                        ))}
                    </Form.Control>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                ))}
        </Row>
    );
};

export default MatchingModule;