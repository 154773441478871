/**
 * Compare a use case against the others in the database.
 *
 * Description: Provides the necessary chart elements for the analytics page.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-17
 * 
 */

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { ComparisonCharts } from './analytics_charts';

export const EcoAnalyticsUCCompare = ({ data, selectedUseCase, analysisResults, comparisonData, setModalData, setShowModal }) => {

    return (
        <>
        <Row style={ { marginTop:'20px'} }>
            <Col md={12}>
                <Row style={ { marginBottom:'20px'} }>
                    Compare Use Case
                    <Col className="d-flex justify-content-end">
                    <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                        <i className="bi bi-info-circle"></i>
                    </Button>
                    </Col>                
                </Row>     
            </Col>
        </Row>    
        {/* Actor relation analysis */}
        <Row style={ { marginBottom:'20px'} }>
            <Col md={12}>
            {/* Compare selected use case against the ecosystem */}
            <ComparisonCharts analysisResults={analysisResults} />  
            </Col>
        </Row>
        </>
    );
}