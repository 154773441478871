/**
 * Generalized Model Component
 *
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-10-06
 * 
 */

import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import '../../App.css';
//import { ResponsiveTreeMap } from '@nivo/treemap';

import NetworkAnalysis from './eco_model_network';

const PlatformEconomyCube = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [rotation, setRotation] = useState({ rotateX: -30, rotateY: -45 });

    const rotateCube = (axis, value) => {
        setRotation((prev) => ({ ...prev, [axis]: prev[axis] + value }));
    };

    const cubeFaces = [
        {
            id: 'front',
            label: 'Front',
            position: 'front',
            content: ['Hardware', 'Middleware', 'ADS', 'Platform', 'Applications']
        },
        {
            id: 'right',
            label: 'Right',
            position: 'right',
            content: ['Vehicle', 'Edge', 'Cloud']
        },
        {
            id: 'left',
            label: 'Left',
            position: 'left',
            content: ['Producer', 'Consumer']
        },
        {
            id: 'top',
            label: 'Top',
            position: 'top',
            content: ['Ecosystem']
        },
        {
            id: 'bottom',
            label: 'Bottom',
            position: 'bottom',
            content: []
        },
        {
            id: 'back',
            label: 'Back',
            position: 'back',
            content: []
        }
    ];

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <Container className="text-center">
            <Row>
                <Col>
                    <h1>PEAM Platform Economy Model for Autonomous Mobility</h1>
                </Col>
            </Row>

            {/* Cube Container */}
            <Row className="justify-content-center">
                <Col md={8}>
                    <div className="cube-container">
                        <div
                            className="cube"
                            style={{
                                transform: `rotateX(${rotation.rotateX}deg) rotateY(${rotation.rotateY}deg)`
                            }}
                        >
                            {cubeFaces.map((face) => (
                                <div key={face.id} className={`cube-face ${face.position}`}>
                                    {face.content.map((item, index) => (
                                        <div
                                            key={index}
                                            className="cube-item"
                                            onClick={() => handleItemClick(item)}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>

            {/* Rotation Controls */}
            <Row className="justify-content-center mt-3">
                <Col md={6} className="text-center">
                    <Button variant="primary" onClick={() => rotateCube('rotateX', 30)} className="m-1">
                        Rotate X+
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateX', -30)} className="m-1">
                        Rotate X-
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateY', 30)} className="m-1">
                        Rotate Y+
                    </Button>
                    <Button variant="primary" onClick={() => rotateCube('rotateY', -30)} className="m-1">
                        Rotate Y-
                    </Button>
                </Col>
            </Row>

            {/* Data Viewer */}
            {selectedItem && (
                <Row className="justify-content-center mt-4">
                    <Col md={6}>
                        <div className="data-viewer p-3 border rounded">
                            <h3>Selected Item - {selectedItem}</h3>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

const GeneralizedModel = ({ usecases, handleClear }) => {

    return (
        <div>
            <Row>
                <Col md={12} style={{ textAlign: 'left' }} id="content">
                    {/* Title */}
                    <Row style={ { marginBottom:'20px', marginTop:'20px'} }>        
                        <Col>
                        <span>Generalized Autonomous Mobility System Model in the Platform Economy</span>
                        </Col>          
                        <Col className="d-flex justify-content-end">                  
                        <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} onClick={handleClear}>
                            <i className="bi bi-arrow-left"></i> Go back
                        </Button>
                        </Col>   
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {/* Platform Economy Model */}
                    <PlatformEconomyCube />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {/* Generalized Model Network */}
                    <NetworkAnalysis useCases={usecases} />
                </Col>
            </Row>
        </div>
    );
}

export default GeneralizedModel;
