// import RDF, OWL
import * as RDF from 'rdflib';

/**
 * Fetches the ontology data from the given URL and parses it using the given RDF store.
 * @param {*} ontologyURL 
 * @param {*} store 
 * @returns 
 */
const fetchDataAndParse = async (ontologyURL, store) => {
    try {
    const response = await fetch(ontologyURL);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const owlData = await response.text();
    RDF.parse(owlData, store, ontologyURL, 'application/rdf+xml');
    return store;
  } catch (error) {
    console.error('Error fetching or parsing the ontology: ', error);
    return null;
  }
};

/**
 * Extracts class information from the RDF store. 
 * @param {*} store 
 * @param {*} rdfType 
 * @param {*} rdfsLabel 
 * @param {*} owlClass 
 * @param {*} rdfsSubClassOf 
 * @param {*} owlOnProperty 
 * @param {*} owlSomeValuesFrom 
 * @param {*} owlHasValue 
 * @returns {Array} An array of class information objects.
 */
function getClassInfoFromStore(store, rdfType, rdfsLabel, owlClass, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue) {
    // Initialize an array to store class information
    const classInfo = [];

    // Iterate through the triples in the store to extract class information
    store.statementsMatching(null, rdfType, owlClass).forEach(classStatement => {
        const classNode = classStatement.subject;
        const classData = extractClassData(store, classNode, rdfsLabel, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue);
        const color = determineNodeColor(store, classNode, rdfType);
        classInfo.push({ ...classData, color });
    });

    return classInfo;
}
  
/**
 * Extracts class data from the RDF store for a given class node.
 * @param {*} store 
 * @param {*} classNode 
 * @param {*} rdfsLabel 
 * @param {*} rdfsSubClassOf 
 * @param {*} owlOnProperty 
 * @param {*} owlSomeValuesFrom 
 * @param {*} owlHasValue 
 * @returns {Object} Class data object.
 */
function extractClassData(store, classNode, rdfsLabel, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue) {
  const labelStatement = store.any(classNode, rdfsLabel);
  const label = labelStatement ? labelStatement.value : null;
  const subclasses = extractSubclasses(store, classNode, rdfsSubClassOf);
  const properties = extractProperties(store, classNode, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue);
  const types = extractTypes(store, classNode);
  return { classNode: classNode.value, label, subclasses, properties, types };
}
  
/**
 * Extracts subclasses of a given class node.
 * @param {*} store 
 * @param {*} classNode 
 * @param {*} rdfsSubClassOf 
 * @returns {Array} Array of subclass values.
 */
function extractSubclasses(store, classNode, rdfsSubClassOf) {
  const subclassStatements = store.statementsMatching(classNode, rdfsSubClassOf);
  return subclassStatements.map(subclassStatement => subclassStatement.object.value);
}

/**
 * Extracts properties of a given class node.
 * @param {*} store 
 * @param {*} classNode 
 * @param {*} rdfsSubClassOf 
 * @param {*} owlOnProperty 
 * @param {*} owlSomeValuesFrom 
 * @param {*} owlHasValue 
 * @returns {Array} Array of property objects.
 */
function extractProperties(store, classNode, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue) {
const subclassStatements = store.statementsMatching(classNode, rdfsSubClassOf);
return subclassStatements.map(subclassStatement => {
    const onProperty = store.any(subclassStatement.object, owlOnProperty);
    const someValuesFrom = store.any(subclassStatement.object, owlSomeValuesFrom);
    const hasValue = store.any(subclassStatement.object, owlHasValue);
    return { property: onProperty ? onProperty.value : null, someValuesFrom: someValuesFrom ? someValuesFrom.value : null, hasValue: hasValue ? hasValue.value : null };
});
}

/**
 * Extracts types of individuals for a given class node.
 * @param {*} store 
 * @param {*} classNode 
 * @param {*} rdfType 
 * @returns {Array} Array of type values.
 */
function extractTypes(store, classNode, rdfType) {
const types = store.statementsMatching(classNode, rdfType);
return types.map(typeStatement => typeStatement.object.value);
}

/**
 * Defines RDF predicates used in parsing.
 * @returns {Object} Object containing RDF predicates.
 */
const defineRdfPredicates = () => {
return {
    rdfType: RDF.namedNode('http://www.w3.org/1999/02/22-rdf-syntax-ns#type'),
    rdfsLabel: RDF.namedNode('http://www.w3.org/2000/01/rdf-schema#label'),
    owlClass: RDF.namedNode('http://www.w3.org/2002/07/owl#Class'),
    rdfsSubClassOf: RDF.namedNode('http://www.w3.org/2000/01/rdf-schema#subClassOf'),
    owlOnProperty: RDF.namedNode('http://www.w3.org/2002/07/owl#onProperty'),
    owlSomeValuesFrom: RDF.namedNode('http://www.w3.org/2002/07/owl#someValuesFrom'),
    owlHasValue: RDF.namedNode('http://www.w3.org/2002/07/owl#hasValue'),
    owlNamedIndividual: RDF.namedNode('http://www.w3.org/2002/07/owl#NamedIndividual'),
    owlObjectProperty: RDF.namedNode('http://www.w3.org/2002/07/owl#ObjectProperty'),
    rdfsSubPropertyOf: RDF.namedNode('http://www.w3.org/2000/01/rdf-schema#subPropertyOf')
  };
};

/**
 * Determines the color of the class node based on its type.
 * @param {*} store 
 * @param {*} classNode 
 * @param {*} rdfType 
 * @returns {string} Color string.
 */
function determineNodeColor(store, classNode, rdfType) {
  const typeIndividual = store.any(classNode, rdfType);
  return typeIndividual && typeIndividual.value === 'http://www.w3.org/2002/07/owl#NamedIndividual' ? 'red' : 'blue';
  }

/**
 * Extracts individuals from the RDF store for a given type predicate.
  * @param {*} store
  * @param {*} rdfType
  * @param {*} owlClass
  * @param {*} rdfsLabel
  * @param {*} subclasses
  * @returns {Array} Array of individual objects.
  * @returns {null} If an error occurs during extraction.
  */
const extractIndividuals = (store, rdfType, owlNamedIndividual, rdfsLabel) => {
  const individuals = [];
  // Iterate over all statements in the store
  store.statementsMatching(null, rdfType, owlNamedIndividual).forEach(statement => {
    const classNode = statement.subject;
    const typeIndividual = store.any(classNode, rdfType);
    const labelStatement = store.any(classNode, rdfsLabel);
    const label = labelStatement ? labelStatement.value : null;
    // used rdfType instead of rdfsSubClassOf for the individuals
    const subclasses = extractSubclasses(store, classNode, rdfType)
    const color = determineNodeColor(store, classNode, rdfType);
    individuals.push({ classNode: classNode.value, label, subclasses, type: typeIndividual.value, color });
  });
  return individuals;
};

/**
 * Extracts individual labels from the RDF store for a given type predicate.
 * @param {*} store
 * @param {*} rdfType
 * @param {*} owlNamedIndividual
 * @param {*} rdfsLabel
 */
const extractIndividualLabels = (store, rdfType, owlNamedIndividual, rdfsLabel) => {
  const labels = [];
  // Iterate over all statements in the store
  store.statementsMatching(null, rdfType, owlNamedIndividual).forEach(statement => {
    const classNode = statement.subject;
    const labelStatement = store.any(classNode, rdfsLabel);
    const label = labelStatement ? labelStatement.value : null;
    labels.push(label);
  });
  return labels;
};

// Create an RDF store
const store = RDF.graph();

// Define the RDF predicates
const { rdfType, rdfsLabel, owlClass, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue, owlNamedIndividual } = defineRdfPredicates();

/**
 * Parses the OWL file and extracts the classes and their properties.
 * @param {*} ontologyURL
 * @returns {Array} An array of class information
 * @returns {null} If an error occurs during parsing
 */
const parseOWLFile = async (ontologyURL) => {
  try {
    // Clear the store before parsing
    store.removeMatches();

    await fetchDataAndParse(ontologyURL, store);

    // Extract class information from the store
    const classInfo = getClassInfoFromStore(store, rdfType, rdfsLabel, owlClass, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue);
    const individuals = extractIndividuals(store, rdfType, owlNamedIndividual, rdfsLabel);
    //console.log('Parsed OWL file:', classInfo);
    return classInfo.concat(individuals);
  } catch (error) {
      console.error('Error parsing the OWL file:', error);
    return null;
  }
};

/**
 * Retrieve the individuals from the OWL file.
 * @param {*} ontologyURL 
 * @returns 
 */
const retrieveIndividuals = async (ontologyURL) => {
  try {
    await fetchDataAndParse(ontologyURL, store);
    const individuals = extractIndividualLabels(store, rdfType, owlNamedIndividual, rdfsLabel);

    return individuals;
  } catch (error) {
      console.error('Error parsing the OWL file:', error);
    return null;
  }
};

/**
 * Extracts the subclass labels of a given class label from the RDF data.
 * @param {*} rdfData
 * @param {*} existingNodeLabel
 * @returns {Array} An array of subclass labels
 */
const extractSubClassLabels = (rdfData, existingNodeLabel) => {
  const subClassLabels = [];
  
  const existingNode = rdfData.find(item => item.label === existingNodeLabel);

  // Return an empty array if not found
  if (!existingNode) {
    return [];
  }

  existingNode.subclasses.forEach(subclassId => {
    const rdfTypeIds = rdfData.find(item => item.classNode === subclassId)?.label;
    if (rdfTypeIds) {
      subClassLabels.push(rdfTypeIds);  
    }
  });
  // Return the labels of the matching RDF types
  return subClassLabels; 
};

/**
 * Retrieve the linkages of a given node label from the RDF data.
 * @param {*} rdfData
 * @param {*} existingNodeLabel
 * @returns {Array} An array of linkage labels
 */
const getLinkages = (rdfData, existingNodeLabel) => {
  return extractSubClassLabels(rdfData, existingNodeLabel); // Return the labels of the matching RDF types
};

/**
 * Parses the OWL file and extracts the classes and their properties.
 * @param {*} ontologyURL
 * @returns {Array} An array of class information
 * @returns {null} If an error occurs during parsing
 */
/*
const parseOWLFile0 = async (ontologyURL) => {
    try {
      // Create an RDF store
      const store = RDF.graph();
  
      // Fetch and parse the ontology data
      await fetchDataAndParse(ontologyURL, store);
  
      // Extract class information from the store
      const classInfo = getClassInfoFromStore(store, rdfType, rdfsLabel, owlClass, rdfsSubClassOf, owlOnProperty, owlSomeValuesFrom, owlHasValue);
      console.log('Parsed OWL file:', classInfo);

      return classInfo;
    } catch (error) {
      console.error('Error parsing the OWL file:', error);
      return null;
    }
};*/

// Parse OWL file and return RDF data
const OWLFileParser = (ontologyURL, useState, useEffect) => {
  const [rdfData, setRdfData] = useState(null);

  useEffect(() => {

    if (!ontologyURL || ontologyURL.trim() === '') {
      console.warn('Ontology URL is empty or invalid.');
      setRdfData(null);
      return;
    }

    parseOWLFile(ontologyURL)
        .then((data) => {
            setRdfData(data);
        })
        .catch((error) => {
            console.error('Error parsing OWL file:', error);
        });
  }, [ontologyURL]);

  return rdfData;
}

export { parseOWLFile, retrieveIndividuals, getLinkages, OWLFileParser };