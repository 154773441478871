/**
 * Provides a BPMN model of the use case.
 *
 * Description: This component provides a BPMN model of the use case.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-19
 * 
 */

import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import BpmnJS from 'bpmn-js/lib/Modeler'; // 'bpmn-js/dist/bpmn-modeler.production.min.js';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';

//import diagram0 from '../bpmn_handling/diagram0.bpmn';

import OpenAI from 'openai';
import { getOpenAIKey } from '../db_mgmt/read';

const generateBpmnXml = (useCaseData) => {
    const actorCategories = Object.keys(useCaseData.actors.value.list);

    const laneHeight = 80;
    const poolSpacing = 20;

    const xPoolPos = 10;
    const poolWidth = 1000;
    const xLanePos = xPoolPos + 30;

    let currentY = 0;

    // Generate BPMN XML: Participants, Processes, Lanes
    const participantsXml = actorCategories.map(category => `
        <bpmn:participant id="Participant_${category}" name="${category}" processRef="Process_${category}" />
    `).join('');

    // Generate BPMN XML: Processes
    const processesXml = actorCategories.map(category => {

        // Retrieve the actorsObject for the current category
        const actorsObject = useCaseData.actors.value.list[category] || { value: [] };
        const actors = Array.isArray(actorsObject.value) ? actorsObject.value : [];

        // Generate XML for lanes within the current category
        const lanesXml = actors.map(actor => `
            <bpmn:lane id="Lane_${category}_${actor.replace(/\s+/g, '')}" name="${actor}">
                <bpmn:flowNodeRef>StartEvent_${category}_${actor.replace(/\s+/g, '')}</bpmn:flowNodeRef>
            </bpmn:lane>
        `).join('');

        // Generate the complete process XML for the current category
        return `
            <bpmn:process id="Process_${category}" isExecutable="false">
                <bpmn:laneSet id="LaneSet_${category}">
                    ${lanesXml}
                </bpmn:laneSet>
                ${actors.map(actor => `
                    <bpmn:startEvent id="StartEvent_${category}_${actor.replace(/\s+/g, '')}" />
                `).join('')}
            </bpmn:process>
        `;
    }).join('');


    // Generate BPMN XML: Shapes
    const shapesXml = actorCategories.map((category) => {
        const actorCount = useCaseData.actors.value.list[category].length || 1;
        const poolHeight = laneHeight * actorCount;
        const shapeXml = `
            <bpmndi:BPMNShape id="Participant_${category}_di" bpmnElement="Participant_${category}" isHorizontal="true">
                <dc:Bounds x="${xPoolPos}" y="${currentY}" width="${poolWidth}" height="${poolHeight}"/>
                <bpmndi:BPMNLabel>
                    <dc:Bounds x="${xPoolPos}" y="${currentY}" width="30" height="${poolHeight}"/>
                </bpmndi:BPMNLabel>
            </bpmndi:BPMNShape>
        `;
        currentY += poolHeight + poolSpacing;
        return shapeXml;
    }).join('');

    let laneYPositions = {};
    currentY = 0;

    // Generate BPMN XML: Lanes
    const lanesShapesXml = actorCategories.flatMap((category) => {
        const actorsObject = useCaseData.actors.value.list[category] || { value: [] };
        const actors = Array.isArray(actorsObject.value) ? actorsObject.value : [];
        const actorCount = actors.length || 1;
        const poolHeight = laneHeight * actorCount;
        laneYPositions[category] = currentY;
        currentY += poolHeight + poolSpacing;
    
        return actors.map((actor, laneIndex) => `
            <bpmndi:BPMNShape id="Lane_${category}_${actor.replace(/\s+/g, '')}_di" bpmnElement="Lane_${category}_${actor.replace(/\s+/g, '')}" isHorizontal="true">
                <dc:Bounds x="${xLanePos}" y="${laneYPositions[category] + (laneIndex * laneHeight)}" width="${poolWidth - 30}" height="${laneHeight}"/>
                <bpmndi:BPMNLabel>
                    <dc:Bounds x="${xLanePos}" y="${laneYPositions[category] + (laneIndex * laneHeight)}" width="30" height="${laneHeight}"/>
                </bpmndi:BPMNLabel>
            </bpmndi:BPMNShape>
        `).join('');
    }).join('');

    // Generate BPMN XML
    const bpmnXml = `
        <?xml version="1.0" encoding="UTF-8"?>
        <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                          xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL"
                          xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
                          xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
                          xmlns:di="http://www.omg.org/spec/DD/20100524/DI"
                          xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd"
                          id="sample-diagram"
                          targetNamespace="http://bpmn.io/schema/bpmn">
            <bpmn:collaboration id="Collaboration_1">
                ${participantsXml}
            </bpmn:collaboration>
            ${processesXml}
            <bpmndi:BPMNDiagram id="BPMNDiagram_1">
                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_1">
                    ${shapesXml}
                    ${lanesShapesXml}
                </bpmndi:BPMNPlane>
            </bpmndi:BPMNDiagram>
        </bpmn:definitions>
    `;

    //console.log('Baseline Generated BPMN XML:', bpmnXml);

    return bpmnXml;
};

// extract data for BPMN model
const extractInformation = (data) => {

    const title = data.title;
    const tags = data.tags.join(', ');
    const scenarios = data.actions.map(action => ({
        name: action.name,
        description: action.description,            
    }));

    const conditions = data.conditions.map(condition => ({
        scenario: condition.scenario,
        preCondition: condition.preConditions || "No pre-condition provided",
        postCondition: condition.postConditions || "No post-condition provided",
        constraints: condition.constraints || "No constraints provided",
        assumptions: condition.assumptions || "No assumptions provided"
    }));

    const actors = data.actors.list;

    const components = data.components //
    /*
    .map(component => ({
        id: component.id,
        description: component.description,
        status: component.status,
        type: component.type || "N/A",
        items: component.items || []
    }));
    */

    return { title, tags, scenarios, conditions, actors, components };
};

// define the prompt format
const formatPrompt = (information, bpmnXml) => {
        
    const { title, tags, scenarios, conditions, actors, components } = information;

    let prompt = `Title: ${title}\n`;
    prompt += `Tags: ${tags}\n\n`;
    prompt += `Scenarios:\n`;

    scenarios.forEach((scenario, index) => {
        prompt += `Scenario ${index + 1}: ${scenario.name}\n`;
        prompt += `Description: ${scenario.description}\n`;
    });

    conditions.forEach((condition, index) => {
        prompt += `Scenario Type: ${condition.scenario}\n`;
        prompt += `Pre-Conditions: ${condition.preCondition}\n`;
        prompt += `Post-Conditions: ${condition.postCondition}\n`;
        prompt += `Constraints: ${condition.constraints}\n`;
        prompt += `Assumptions: ${condition.assumptions}\n\n`;
    });

    prompt += `Actors:\n`;
    for (const [category, actorList] of Object.entries(actors)) {
        prompt += `${category}: ${actorList.join(', ')}\n`;
    }

    prompt += `\nComponents:\n`;
    const extract_components = components.map(component => ({
        category: component.category,
        components: component.components.map(subComponent => ({
            id: subComponent.id,
            description: subComponent.description || "No description provided",
            status: subComponent.status,
            type: subComponent.type || "N/A",
            items: subComponent.items || []
        }))
    }));
    prompt += JSON.stringify(extract_components, null, 2);

    prompt += `\nBaseline BPMN XML:\n${bpmnXml}\n\n`;
    /*
    prompt += `\nBaseline BPMN XML:\n${bpmnXml}\n\n`;
    prompt += `Given the baseline XML, enhance it by adding tasks, start events, and connecting them according to the provided scenarios and descriptions. Make sure to:\n`;
    prompt += `1. Add start events for each lane where necessary.\n`;
    prompt += `2. Add tasks based on the scenario descriptions.\n`;
    prompt += `3. Connect the tasks and events logically with sequence flows.\n`;
    prompt += `4. Maintain the existing structure and only enhance by adding new elements.\n`;
    prompt += `5. Ensure all tags are correctly closed and nested.\n`;
    prompt += `6. Provide only the XML data, nothing else.\n`;

    prompt += `Example:\n`;
    prompt += `Baseline BPMN XML:\n${diagram0}\n\n`;
    */

    prompt += `Given the baseline XML, model the respective start flows and tasks inside the pools and connect these accordingly.
    Strictly adopt the baseline structure. Only add the tasks and connections.
    Ensure each process contains:
    - A startEvent
    - Tasks with appropriate IDs and names
    - An endEvent
    - SequenceFlows connecting the startEvent to tasks, tasks to tasks, and tasks to endEvent.
    Use the provided baseline as a template, and do not alter existing structures. Focus on adding missing elements.
    
    Example Structure:
    <bpmn:process id="Process_Example" isExecutable="false">
        <bpmn:laneSet id="LaneSet_Example">
            <bpmn:lane id="Lane_Example_1" name="Example Lane 1">
                <bpmn:flowNodeRef>StartEvent_Example_1</bpmn:flowNodeRef>
                <bpmn:flowNodeRef>Task_Example_1</bpmn:flowNodeRef>
                <bpmn:flowNodeRef>EndEvent_Example_1</bpmn:flowNodeRef>
            </bpmn:lane>
        </bpmn:laneSet>
        <bpmn:startEvent id="StartEvent_Example_1" />
        <bpmn:task id="Task_Example_1" name="Example Task" />
        <bpmn:endEvent id="EndEvent_Example_1" />
        <bpmn:sequenceFlow id="Flow_1" sourceRef="StartEvent_Example_1" targetRef="Task_Example_1" />
        <bpmn:sequenceFlow id="Flow_2" sourceRef="Task_Example_1" targetRef="EndEvent_Example_1" />
    </bpmn:process>

    Model the full BPMN for each given scenario; only provide the XML data, nothing else. Ensure the XML is well-formed and valid.\n`;

    //prompt += `Baseline BPMN XML:\n${bpmnXml}\n\n`;
    prompt += `Given the baseline XML, model the respective start flows and tasks inside the pools and connect these accordingly.\n`;
    prompt += `Strictly adopt the baseline structure. Just copy it and stick to it; only add the tasks and connections.\n`;
    prompt += `Ensure all tasks, start events, and sequence flows are correctly positioned within their lanes. Add BPMNShape and BPMNEdge elements to the BPMNDiagram section for proper visualization.\n`;
    prompt += `Model the BPMN for each given scenario; only provide the XML data, nothing else.\n`;

    return prompt;
};

const generatePrompt = (data) => {
    const information = extractInformation(data);
    //return formatPrompt(information, bpmnXml);
    return [
        { role: 'system', content: 'You are modeling BPMNs based on given use case data.' },
        { role: 'user', content: formatPrompt(information, generateBpmnXml(data)) }
    ];
};

const correctXmlNamespace = (xml) => {
    return xml
        .replace(/<bipmdi:/g, '<bpmndi:')
        .replace(/<\/bipmdi:/g, '</bpmndi:')
        .replace(/<\/bpmnndi:/g, '</bpmndi:')
        .replace(/<bpmnndi:/g, '<bpmndi:')
        .replace(/<\/bpmn:/g, '</bpmn:')
        .replace(/<bpmn:/g, '<bpmn:')
        .replace(/<\/dc:/g, '</dc:')
        .replace(/<dc:/g, '<dc:');
};


function BpmnModeler({ useCaseData }) {
    const containerRef = useRef(null);
    const modelerRef = useRef(null);

    useEffect(() => {
        if (!modelerRef.current) {
            // create a new modeler instance
            modelerRef.current = new BpmnJS({
                container: containerRef.current,
                keyboard: { bindTo: window }
            });
        }

        const importDiagram = async () => {
            try {
                // fetch the BPMN diagram file
                //const response = await fetch(diagram);
                //const diagramXml = await response.text();

                // import the BPMN diagram XML
                await modelerRef.current.importXML(useCaseData); // 

                // access modeler components
                const canvas = modelerRef.current.get('canvas');
                //const overlays = modelerRef.current.get('overlays');

                // zoom to fit full viewport
                //canvas.zoom('fit-viewport');
                canvas.zoom('fit-viewport', 'auto');
                
                /*
                // attach an overlay to a node
                overlays.add('SCAN_OK', 'note', {
                position: { bottom: 0, right: 0 },
                html: '<div class="diagram-note">Mixed up the labels?</div>'
                });

                // add marker
                canvas.addMarker('SCAN_OK', 'needs-discussion');
                */

            } catch (error) {
                console.error('Could not import BPMN diagram:', error);
            }
        };

        importDiagram();
    }, [useCaseData]);

    const exportDiagram = async () => {
        try {
        const result = await modelerRef.current.saveXML({ format: true });
        console.log('DIAGRAM', result.xml);
        } catch (error) {
        console.error('Could not save BPMN diagram:', error);
        }
    };

    return (
        <>
            <div ref={containerRef} style={{ width: '100%', height: '600px', border: '1px solid #ccc' }}></div>
            
            <Button variant='outline-secondary' onClick={exportDiagram}>Print to console</Button>
            <Button variant='outline-secondary' style={ { marginLeft:'15px' } } onClick={() => modelerRef.current.get('canvas').zoom('fit-viewport', 'auto')}>Fit to screen</Button>
        </>
    );
}

export const BPMNModel = ({ data }) => {

    const [selectedModel, setSelectedModel] = useState('generic');
    const [bpmnXml, setBpmnXml] = useState(generateBpmnXml(data));
    const [BPMNScenarios, setBPMNScenarios] = useState([]);

    const scenarios = data.actions.value.map((action, index) => ({
        name: action.name,
        id: `scenario_${index}`
    }));    

    useEffect(() => {

        if (!BPMNScenarios || BPMNScenarios.length === 0) { return; }

        console.log('BPMN Scenarios:', BPMNScenarios.length);

        const loadBpmnXml = () => {
            if (selectedModel === 'generic') {
                // Load the generic BPMN XML for the use case
                console.log('generic');
                console.log('BPMN Scenarios:', BPMNScenarios[0].name);
                setBpmnXml(BPMNScenarios[0].bpmnXml);
            } else if (selectedModel === 'add') {
                console.log('add');
                // Generate a new BPMN XML for the use case
                setBpmnXml(generateBpmnXml(data));
            } else {
                console.log('scenario ' + selectedModel);
                // Load the BPMN XML for the selected scenario in the dropdown
                const scenario = BPMNScenarios.find(scenario => scenario.id === selectedModel);
                if (scenario) {
                    console.log('scenario data', scenario.name);
                    setBpmnXml(scenario.bpmnXml);
                }
            }
        };
        loadBpmnXml();
    }, [selectedModel, data, BPMNScenarios]);

    // Initialize OpenAI
    const [openai, setOpenai] = useState(null);
    useEffect(() => {
      const initializeOpenAI = async () => {
        try {
          const apiKey = await getOpenAIKey();
          const openaiInstance = new OpenAI({
            apiKey: apiKey,
            dangerouslyAllowBrowser: true,
          });
          setOpenai(openaiInstance);
        } catch (error) {
          console.error('Error fetching API key:', error);
        }
      };

      initializeOpenAI();
    }, []);

    // Function to handle the generation and API call
    const handleGeneratePrompt = async () => {

        console.log('Generating BPMN XMLs...');

        if (!data || !data.actions) {
            console.error('No actions data available');
            return;
        }

        const prompt = generatePrompt(data);

        try {
            const completion = await openai.chat.completions.create({
                model: 'gpt-4o-mini', //'gpt-3.5-turbo', // 'gpt-4o'
                messages: prompt,
                n: data.actions.value.length +1, // Number of scenarios
                //stop: ["</bpmn:definitions>"], // Assuming you want to end at this tag
            });      

            // Extract only the XML content from the response
            /*
            const extractXmlContent0 = (text) => {
                const match = text.match(/```xml\n([\s\S]*?)```/);
                return match ? match[1].replace(/\\n/g, '\n').trim() : '';
            };*/

            const extractXmlContent = (text) => {
                const match = text.match(/```xml\n([\s\S]*?)```/);
                if (match) {
                    // Replace \n with actual newlines
                    return match[1].replace(/\\n/g, '\n').trim();
                }
                return '';
            };

            // Assuming the response data contains an array of XMLs
            //console.log('Completion:', completion.choices[0].message.content);
            //const xmls_n = completion.choices.map(choice => choice.message.content);
            //console.log('XMLs neu:', xmls_n);
            const xmls = completion.choices.map(choice => extractXmlContent(choice.message.content));
            //console.log('XMLs:', xmls);

            // Ensure no typos in XML tags and namespaces
            /*
            const correctXmlNamespace = (xml) => {
                return xml
                    .replace(/<bipmdi:/g, '<bpmndi:')
                    .replace(/<\/bipmdi:/g, '</bpmndi:');
            };*/
            
            //const correctXmlNamespace = (xml) => xml.replace(/<bipmdi:/g, '<bpmndi:').replace(/<\/bipmdi:/g, '</bpmndi:');

            const cleanedXmls = xmls.map(correctXmlNamespace);

            // Update the scenarios with the generated XMLs
            const updatedScenarios = data.actions.value.map((action, index) => ({
                ...action,
                id: `scenario_${index}`,
                bpmnXml: cleanedXmls[index+1] || '' // Skip the first one for scenarios
            }));

            // Set the generic BPMN XML
            //setBpmnXml(cleanedXmls[0] || '');
            //console.log('Generic BPMN:', xmls[0]);

            // Now you can update your state or do something with updatedScenarios
            //console.log(updatedScenarios);

            // For example, if you are using a state
            setBPMNScenarios(updatedScenarios);
            //console.log('Scenarios:', updatedScenarios[0].name);
            //console.log('Scenarios:', updatedScenarios[1].bpmnXml);

        } catch (error) {
            console.error('Error generating BPMN XMLs:', error);
        }
    };

    return (
        <>
        <Row style={ { marginTop:'20px'} }>
            <Col md={12}>
                <Row style={ { marginBottom:'20px'} }>
                    BPMN Model
                    <Col className="d-flex justify-content-end">
                    <Button variant="outline-primary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                        <i className="bi bi-info-circle"></i>
                    </Button>
                    </Col>
                </Row>     
            </Col>
        </Row>    
        {/* Select BPMN model */}
        <Row style={ { marginBottom:'20px'} }>
            <Col md={12}>
                <Row>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${selectedModel === 'generic' ? 'active' : ''}`} 
                            onClick={() => setSelectedModel('generic')}
                        >
                            Generic BPMN for {data.acronym.value}
                        </button>
                    </li>
                    <li className="nav-item dropdown">
                        <button 
                            className="nav-link dropdown-toggle" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                        >
                            Specific Scenarios BPMNs
                        </button>
                        <ul className="dropdown-menu">
                            {scenarios.map(scenario => (
                                <li key={scenario.id}>
                                    <button 
                                        className={`dropdown-item ${selectedModel === scenario.id ? 'active' : ''}`} 
                                        onClick={() => setSelectedModel(scenario.id)}
                                    >
                                        {scenario.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link`} 
                            onClick={() => setSelectedModel('add')}
                        >
                            Add BPMN
                        </button>
                    </li>
                    <Col className='d-flex justify-content-end'>
                    <li className="nav-item">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Generate BPMN XMLs for the modeled use case scenarios</Tooltip>}>
                            <Button variant="outline-secondary" className="btn-sm" style={{ marginRight: '10px' }} onClick={handleGeneratePrompt}>
                                <i className="bi bi-magic"></i> Generate BPMN
                            </Button>
                        </OverlayTrigger>
                    </li>
                    <li className="nav-item">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Resize to fit screen</Tooltip>}>
                            <Button variant="outline-secondary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                                <i className="bi bi-arrow-repeat"></i> Fit to screen
                            </Button>
                        </OverlayTrigger>
                    </li>
                    <li className="nav-item">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Print the BPMN XML to console</Tooltip>}>
                            <Button variant="outline-secondary" className="btn-sm" style={{ marginRight: '10px' }} disabled>
                                <i className="bi bi-print"></i> Print to console
                            </Button>
                        </OverlayTrigger>
                    </li>
                    </Col>
                </ul>
                
                </Row>
            </Col>
        </Row>
        {/* Actor relation analysis */}
        <Row style={ { marginBottom:'20px'} }>
            <Col md={12}>
            {/* BPMN Model */}
            {data && <BpmnModeler useCaseData={bpmnXml} /> }
            </Col>
        </Row>
        </>
    );
}