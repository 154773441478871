/**
 * Prepares data for analytics visualization
 *
 * Description: Provides the necessary functions to prepare the data for comparison and visualization.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-14
 * 
 */

  import { mean, median, standardDeviation } from 'simple-statistics';

  // used to retrieve the use cases that are similar, distinct, and overlapping
  export const categorizeUseCases = (selectedUseCase, allUseCases) => {
    const similarUseCases = [];
    const distinctUseCases = [];
    const overlappingUseCases = [];
  
    allUseCases.forEach((useCase) => {
      if (useCase.id === selectedUseCase.id) {
        // Skip the selected use case itself
        return;
      }
  
      const commonTags = useCase.tags.value.filter(tag => selectedUseCase.tags.value.includes(tag));
      if (commonTags.length === selectedUseCase.tags.value.length && commonTags.length === useCase.tags.value.length) {
        similarUseCases.push(useCase);
      } else if (commonTags.length === 0) {
        distinctUseCases.push(useCase);
      } else {
        overlappingUseCases.push(useCase);
      }
    });
  
    return {
      similar: similarUseCases.length,
      distinct: distinctUseCases.length,
      overlapping: overlappingUseCases.length
    };
  };

  // Calculate matching score
  export const calculateMatchingScore = (selectedUseCase, allUseCases) => {
    let totalOverlapActors = 0;
    let totalOverlapComponents = 0;
    let totalTags = 0;
    let totalApplication = 0;
    let totalMaturity = 0;
    let totalStatus = 0;
  
    const selectedActors = new Set(selectedUseCase.actors.value.nodes.value.map(actor => actor.id));
    const selectedComponents = new Set();
    selectedUseCase.components.value.forEach(category => {
      category.components.forEach(component => selectedComponents.add(component.description));
    });
  
    allUseCases.forEach(useCase => {
      if (useCase.id === selectedUseCase.id) return; // Skip the selected use case itself
  
      const useCaseActors = new Set(useCase.actors.value.nodes.value.map(actor => actor.id));
      const useCaseComponents = new Set();
      useCase.components.value.forEach(category => {
        category.components.forEach(component => useCaseComponents.add(component.description));
      });
  
      // Calculate overlap
      const overlapActors = new Set([...selectedActors].filter(actor => useCaseActors.has(actor)));
      const overlapComponents = new Set([...selectedComponents].filter(component => useCaseComponents.has(component)));
  
      totalOverlapActors += overlapActors.size;
      totalOverlapComponents += overlapComponents.size;
  
      // Additional parameters
      if (useCase.tags.value.some(tag => selectedUseCase.tags.value.includes(tag))) totalTags++;
      if (useCase.application.value === selectedUseCase.application.value) totalApplication++;
      if (useCase.maturity.value === selectedUseCase.maturity.value) totalMaturity++;
      if (useCase.status.value === selectedUseCase.status.value) totalStatus++;
    });
  
    // Normalize the score
    const totalActors = selectedUseCase.actors.value.nodes.value.length;
    const totalComponents = selectedComponents.size;
    const totalUseCases = allUseCases.length - 1; // excluding the selected use case itself
  
    const scoreActors = totalActors ? totalOverlapActors / totalActors : 0;
    const scoreComponents = totalComponents ? totalOverlapComponents / totalComponents : 0;
    const scoreTags = totalTags / totalUseCases;
    const scoreApplication = totalApplication / totalUseCases;
    const scoreMaturity = totalMaturity / totalUseCases;
    const scoreStatus = totalStatus / totalUseCases;
  
    const matchingScore = scoreActors + scoreComponents + scoreTags + scoreApplication + scoreMaturity + scoreStatus;
  
    return matchingScore.toFixed(2); // Adjust precision as needed
  };  

  // Custom groupBy function
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      // Access the value property of the key if it's an object
      const attributeValue = currentValue[key]?.value || currentValue[key];
      if (!attributeValue) return result;

      // Initialize the group if it doesn't exist
      if (!result[attributeValue]) {
        result[attributeValue] = [];
      }

      // Push the current value to the correct group
      result[attributeValue].push(currentValue);
      return result;
    }, {});
  };

  // Prepare data for comparison
  export const prepareComparisonData = (data, selectedUseCase, comparisonType, selectedComparisonUseCases) => {
    let comparisonData = [];
    if (comparisonType === 'all') {
      comparisonData = data;
    } else if (comparisonType === 'specific') {
      comparisonData = selectedComparisonUseCases;
    }
    return comparisonData;
  };

  // Calculate statistics for use case performance
  export const calculateStatistics = (data, selectedUseCase) => {
    const adoptionRates = data.map(uc => uc.adoption ? 1 : 0);
    const avgAdoptionRate = mean(adoptionRates);
    const medianAdoptionRate = median(adoptionRates);
    const sdAdoptionRate = standardDeviation(adoptionRates);

    return {
      avgAdoptionRate,
      medianAdoptionRate,
      sdAdoptionRate,
    };
  };

  // Prepare data for bar chart with dynamic attribute
  export const prepareBarChartData = (data, attribute, selectedUseCase) => {
  
    // Function to safely access attribute value
    const getAttr = (item, attr) => item[attr]?.value ?? item[attr];
  
    // Group data by the attribute
    const groupedByAttribute = data.reduce((acc, item) => {
      const attrValue = getAttr(item, attribute);
      if (attrValue) {
        acc[attrValue] = (acc[attrValue] || 0) + 1;
      }
      return acc;
    }, {});
  
    // Create bar chart data
    const selectedValue = getAttr(selectedUseCase, attribute);
    const barChartData = Object.keys(groupedByAttribute).map(attr => ({
      [attribute]: attr,
      count: groupedByAttribute[attr],
      isSelected: selectedValue === attr
    }));
  
    return barChartData;
  };

  // Function to prepare data for bar chart (e.g. comparison of use cases)
  export const prepareBarData = (data) => {
      const labels = Object.keys(data);
      const values = labels.map(label => data[label]);
    
      return {
        labels: labels,
        datasets: [
          {
            label: 'Number of Use Cases',
            data: values,
            backgroundColor: 'rgba(75,192,192,0.6)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
          }
        ]
      };
  };

  // Prepare data for pie chart with dynamic attribute
  export const preparePieChartData = (data, attribute) => {

    const groupedByStatus = groupBy(data, attribute);
    const pieChartData = Object.keys(groupedByStatus).map(attr => ({
      [attribute]: attr,
      count: groupedByStatus[attr].length,
    }));

    return pieChartData;
  };

  // Prepare data for scatter plot
  export const prepareScatterPlotData = (data) => {
    return data.map(uc => ({
      x: uc.actions.length, // Example: number of actions
      y: uc.actors.nodes.length, // Example: number of actors
      label: uc.title,
    }));
  };

  // Function to prepare data for overlap bar chart
  export const prepareOverlapData = (data) => {
    const labels = Object.keys(data);
    const actorOverlap = labels.map(label => data[label].actorOverlap);
    const componentOverlap = labels.map(label => data[label].componentOverlap);
  
    return {
      labels: labels,
      datasets: [
        {
          label: 'Actor Overlap',
          data: actorOverlap,
          backgroundColor: 'rgba(153,102,255,0.6)',
          borderColor: 'rgba(153,102,255,1)',
          borderWidth: 1,
        },
        {
          label: 'Component Overlap',
          data: componentOverlap,
          backgroundColor: 'rgba(255,159,64,0.6)',
          borderColor: 'rgba(255,159,64,1)',
          borderWidth: 1,
        }
      ]
    };
  };

  // Function to analyze use cases
  export const analyzeUseCases = (selectedUseCase, comparisonData) => {
    const similarUseCases = {};
    const distinctUseCases = {};
    const overlaps = {};
  
    comparisonData.forEach(useCase => {
      if (useCase.id !== selectedUseCase.id) {
        // Calculate similarity
        const similarity = calculateSimilarity(selectedUseCase, useCase);
        similarUseCases[useCase.title.value] = similarity;
  
        // Calculate distinctiveness
        const distinctiveness = calculateDistinctiveness(selectedUseCase, useCase);
        distinctUseCases[useCase.title.value] = distinctiveness;
  
        // Calculate overlaps
        overlaps[useCase.title.value] = {
          actorOverlap: calculateActorOverlap(selectedUseCase, useCase),
          componentOverlap: calculateComponentOverlap(selectedUseCase, useCase)
        };
      }
    });
  
    return { similarUseCases, distinctUseCases, overlaps };
  };

  // ----------------------------
  
  // Helper functions for similarity, distinctiveness, and overlaps
  const calculateSimilarity = (useCase1, useCase2) => {
    // Implement your similarity calculation logic here
    return Math.random() * 100; // Placeholder
  };
  
  const calculateDistinctiveness = (useCase1, useCase2) => {
    // Implement your distinctiveness calculation logic here
    return Math.random() * 100; // Placeholder
  };
  
  const calculateActorOverlap = (useCase1, useCase2) => {
    // Implement your actor overlap calculation logic here
    return Math.random() * 10; // Placeholder
  };
  
  const calculateComponentOverlap = (useCase1, useCase2) => {
    // Implement your component overlap calculation logic here
    return Math.random() * 10; // Placeholder
  };

  // ----------------------------

  // Function to prepare data for component chart
  export const prepareBarChartDataComponents = (components) => {
    const categories = components.map(category => category.category);
    const itemsCount = components.map(category => 
      category.components.reduce((acc, comp) => acc + (comp.items ? comp.items.length : 0), 0)
    );
  
    return {
      labels: categories,
      datasets: [
        {
          label: 'Number of Items',
          data: itemsCount,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1
        }
      ]
    };
  };

  // Function to prepare data for nested component chart
  export const prepareBarChartDataComponentsNested = (components) => {
    //const categories = components.map(category => category.category);
    const subcategories = [];
    const itemsCount = [];
  
    components.forEach(category => {
      category.components.forEach(subcategory => {
        subcategories.push(`${category.category} - ${subcategory.description}`);
        itemsCount.push(subcategory.items ? subcategory.items.length : 0);
      });
    });
  
    return {
      labels: subcategories,
      datasets: [
        {
          label: 'Number of Items',
          data: itemsCount,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1
        }
      ]
    };
  };

  // Function to prepare data for actor chart
  export const prepareBarChartActor = (list) => {
    const labels = Object.keys(list);
    const data = labels.map(label => list[label].value.length);

    return {
      labels,
      datasets: [
        {
          label: 'Number of Actors',
          data,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        }
      ]
    };
  };

  // ----------------------------



  // ----------------------------

  // Function to prepare data for visualization - application, maturity, status - NOT USED
  export const prepareComponentChartData = (useCase, comparisonUseCases) => {
    const allUseCases = [useCase, ...comparisonUseCases];

    // Collect unique component categories
    const categories = [...new Set(allUseCases.flatMap(uc => uc.components.map(comp => comp.category)))];

    // Prepare data for each category
    const data = categories.map(category => {
      const categoryData = {
        category,
        components: [],
      };

      allUseCases.forEach(uc => {
        uc.components.forEach(comp => {
          if (comp.category === category) {
            categoryData.components.push({
              useCase: uc.id,
              items: comp.components.flatMap(c => c.items.map(item => item.trim())),
            });
          }
        });
      });

      return categoryData;
    });

    return data;
  };