/**
 * Modal
 * 
 * Description: 
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-27
 * 
 */

import React from 'react';
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ModelModalExecuteSave = ({ onClose, uc_name, isEdit, handleSubmitSave, handleSubmitEdit }) => {

    return (
        <Modal show={true} onHide={onClose} placement="end">
            <Modal.Header closeButton>
                <Modal.Title>Saved Use Case to Repository</Modal.Title> {/* Association Guide */}
            </Modal.Header>
            <Modal.Body>       
                <Row className="justify-content-center text-center">
                    <Col>                                            
                        <p style={{ color:'green' }}><b>Awesome - {uc_name} is now saved in the repository !</b></p>
                        <p><b>Let the fun continue. Check out, what's up next.</b></p>
                        
                        <p>
                            Continue to analyze the use case in the <b>Analytics</b> section or
                            go to your <b>Dashboard</b> to manage your use cases.
                        </p>                              

                    </Col>
                </Row> 
                
            </Modal.Body>
            <Modal.Footer>
                <OverlayTrigger placement="top" overlay={<Tooltip>Check initial use case details</Tooltip>}>
                <Button 
                    variant="outline-success" 
                    onClick={() => {
                        onClose();
                    }}
                >
                    <i className="bi bi-success"></i> Continue
                </Button>
                </OverlayTrigger>
            </Modal.Footer>
        </Modal>
      );
    };

export default ModelModalExecuteSave;               