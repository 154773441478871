/**
 * Matching Score Modal
 *
 * Description: Provides the necessary modal explanation for the matching score calculation.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-17
 * 
 */

import React from 'react';
import { Modal, Button, Row, Col, Card, ListGroup } from 'react-bootstrap';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

export const MatchingScoreModal = ({ show, handleClose }) => {

    const handleCloseModalScore = () => {
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Matching Score Calculation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p style={{ fontSize: '12px' }}>
                The matching score indicates how well the selected use case matches with existing modeled components. It is calculated based on the number of overlapping actors and components with other use cases.
            </p>
            <h5>Matching Score Formula:</h5>
            <Card className="mb-3">
                <Card.Body>
                    <div style={{ textAlign: 'center', fontSize: '10px' }}>
                    Matching Score = 
                    <BlockMath>
                        {`\\left( \\frac{\\text{Overlap in Actors}}{\\text{Total Actors}} \\right) + \\left( \\frac{\\text{Overlap in Components}}{\\text{Total Components}} \\right) + \\text{Additional Factors}`}
                    </BlockMath>
                    </div>
                </Card.Body>
            </Card>
    
            <h5>Step-by-Step Calculation</h5>
            <ListGroup style={{ fontSize: '12px' }}>
                <ListGroup.Item>
                <strong>Step 1: Calculate Overlap in Actors</strong>
                <p>Count the number of actors in the selected use case that are also present in other use cases.</p>
                </ListGroup.Item>
                <ListGroup.Item>
                <strong>Step 2: Calculate Overlap in Components</strong>
                <p>Count the number of components in the selected use case that are also present in other use cases.</p>
                </ListGroup.Item>
                <ListGroup.Item>
                <strong>Step 3: Calculate Additional Factors</strong>
                <p>Consider additional factors such as tags, application domain, maturity, and status.</p>
                </ListGroup.Item>
                <ListGroup.Item>
                <strong>Step 4: Combine Scores</strong>
                <p>Combine the scores from the above steps to get the final matching score.</p>
                </ListGroup.Item>
            </ListGroup>
    
            <h5 style={ { marginTop:'15px' } }>Example</h5>
            <Card className="mb-3">
                <Card.Body>
                <Row style={{ fontSize: '12px' }}>
                    <Col>
                    <p><strong>Selected Use Case:</strong></p>
                    <ul>
                        <li>Actors: 20</li>
                        <li>Components: 100</li>
                        <li>Tags: [Tag1, Tag2]</li>
                        <li>Application: Research</li>
                    </ul>
                    </Col>
                    <Col>
                    <p><strong>Comparison Use Case:</strong></p>
                    <ul>
                        <li>Actors: 10 overlapping</li>
                        <li>Components: 40 overlapping</li>
                        <li>Tags: [Tag1, Tag3, Tag4]</li>
                        <li>Application: Research</li>
                    </ul>
                    </Col>
                </Row>
                    <div style={{ textAlign: 'center', fontSize: '12px' }}>
                        Matching Score = 
                        <BlockMath>
                            {`\\left( \\frac{10}{20} \\right) + \\left( \\frac{40}{100} \\right) + 1 + 1 = 2.9`}
                        </BlockMath>
                    </div>
                </Card.Body>
            </Card>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalScore}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    );
};
  