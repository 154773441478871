/**
 * Define new concept to be added to the ontology tree
 * 
 * Description: This form provides the fields representing the use case information
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-05-12
 * 
 */

// Recursive function to find a concept by title within a list of concepts
const findConceptByTitle = (title, concepts) => {
    for (const concept of concepts) {
        if (concept.title === title) {
            return concept; // Found the concept, return it
        }
        // If the concept has subconcepts, recursively search through them
        if (concept.subconcepts) {
            const foundConcept = findConceptByTitle(title, concept.subconcepts);
            if (foundConcept) {
                return foundConcept; // Found the concept within subconcepts, return it
            }
        }
    }
    return null; // Concept not found
};

// Function to add a new concept to the ontology tree
const addConcept = (newConceptTitle, parentConceptTitle, sidebarItems, setSidebarItems) => {

    // Find the parent concept in the sidebarItems list based on the parentConceptTitle
    const parentConcept = findConceptByTitle(parentConceptTitle, sidebarItems);
    if (parentConcept) {
        // Create the new concept object
        const newConcept = {
            title: newConceptTitle,
            subconcepts: [],
            collapsed: true,
            linkedData: true // True as the user added it; so-called linked data
        };

        // Add the new concept to the subconcepts array of the parent concept
        parentConcept.subconcepts.push(newConcept);

        // Update the state with the modified sidebarItems list
        setSidebarItems([...sidebarItems]);
        return true;
    } else {
        console.log(`Parent concept "${parentConceptTitle}" not found.`);
        return false;
    }

};

export { addConcept };