/**
 * Use Case Repository: stacked view
 *
 * Description: Renders the use case repository in a stacked view.
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-06-09
 * 
 */

import React from 'react';
import { Row, Col, Card, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleDateString(); // + ' ' + date.toLocaleTimeString()
    }
    return 'N/A';
};

const renderCardView = ( currentItems, selectedUseCases, handleCheckboxChange, handleUseAsTemplate, getUserID, handleDeleteUseCase, handleInspectUseCase ) => (

    <Row>
        {currentItems.map((useCase) => (
            
            <Col xs={12} sm={6} md={4} key={useCase.id} style={{ marginBottom: '15px' }}>
                
                <Card style={{ height: '360px', overflowY: 'auto' }}>
                    <Card.Body style={{ maxHeight: '360px', overflowY: 'auto' }}>
                    <Row className="align-items-center">
                        <Col xs="auto">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Select to include in Ecosystem Analysis</Tooltip>}
                        >
                            <Form.Check
                            type="checkbox"
                            checked={selectedUseCases.includes(useCase.id)}
                            onChange={() => handleCheckboxChange(useCase.id)}
                            className="me-2"
                            />
                        </OverlayTrigger>
                        </Col>
                        <Col>
                            <Card.Title className="h6">
                                <i className="bi bi-box"> </i>
                                {useCase.acronym.value}: {useCase.title.value}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{useCase.created_by.value || 'N/A'}</Card.Subtitle>
                            <div style={{ fontSize: '12px' }}>
                                {useCase.visibility.value ? 
                                  <span className="badge badge-pill bg-success" style={ { marginRight:'10px'} }>Publicly visible</span>
                                  :
                                  <span className="badge badge-pill bg-warning" style={ { marginRight:'10px'} }>Restricted visibility</span>
                                }
                                {useCase.adoption.value ? 
                                  <span className="badge badge-pill bg-success" style={ { marginRight:'10px'} }>Open access</span>
                                  :
                                  <span className="badge badge-pill bg-warning" style={ { marginRight:'10px'} }>No access</span>
                                }                     
                                {useCase.share.value ? 
                                  <span className="badge badge-pill bg-success" style={ { marginRight:'10px'} }>Share Data</span>
                                  :
                                  <span className="badge badge-pill bg-warning" style={ { marginRight:'10px'} }>Hide Data</span>
                                }
                            </div>
                        </Col>
                    </Row>
                    <hr />
                        <div style={{ fontSize: '12px' }}>
                            <Row>
                                <Col>  
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <div style={{ minWidth: '80px', fontWeight: 'bold' }}>Application:</div>
                                        <div>{useCase.application.value}</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <div style={{ minWidth: '80px', fontWeight: 'bold' }}>Maturity:</div>
                                        <div>{useCase.maturity.value}</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <div style={{ minWidth: '80px', fontWeight: 'bold' }}>Status:</div>
                                        <div>{useCase.status.value}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <div style={{ minWidth: '80px', fontWeight: 'bold' }}>Created:</div>
                                        <div>{formatTimestamp(useCase.created.value)}</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                        <div style={{ minWidth: '80px', fontWeight: 'bold' }}>Updated:</div>
                                        <div>{formatTimestamp(useCase.updated.value)}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={ { marginTop:'0px'} }>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <div style={{ minWidth: '80px', fontWeight: 'bold' }}>Location:</div>
                                    {useCase.continents.value !== undefined && useCase.continents.value.length > 0 && (
                                    <div>{useCase.continents.value.join(", ")}: </div>
                                    )}
                                    {useCase.continents.value !== undefined && useCase.continents.length > 0 && useCase.country && (
                                        <div style={{ margin: '0 2px' }}> {/* This div adds a blank space between continents and country */}
                                        </div>
                                    )}
                                    {Array.isArray(useCase.country.value) ? (
                                       <div> {useCase.country.value.join(", ")}</div>
                                    ) : (
                                        <div>{useCase.country.value}</div>
                                    )}
                                    {useCase.city.value !== undefined && useCase.city.value.length > 0 && (
                                        <div style={{ margin: '0 2px' }}> {/* This div adds a blank space between continents and country */}
                                        ,
                                        </div>
                                    )}
                                    {useCase.city.value !== undefined && useCase.city.value.length > 0 && (
                                        <>
                                        {Array.isArray(useCase.city.value) ? (
                                            <div> {useCase.city.value.join(", ")}</div>
                                         ) : (
                                             <div>{useCase.city.value}</div>
                                         )}
                                        </>
                                    )}
                                    {/*<div>{useCase.city}, {useCase.country_code}</div>*/}
                                </div>
                            </Row>
                            <Row style={ { marginTop:'10px'} }>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <div style={{ minWidth: '80px', fontWeight: 'bold' }}>Keywords:</div>
                                    <div>
                                        {useCase.tags.value.map(tag => (
                                        <span key={tag} className="badge bg-light text-dark" style={{ marginLeft: '5px' }}>
                                            {tag}
                                        </span>
                                        ))}
                                    </div>
                                </div>
                            </Row>                                            
                        </div>                                          
                    </Card.Body>
                    <Card.Footer style={{ backgroundColor: 'white' }}>
                        <Row className="mt-2">
                            <Col xs={12} className="d-flex flex-wrap justify-content-end">

                            <OverlayTrigger placement="top" overlay={<Tooltip>Look up the use case and process it</Tooltip>}>
                            <Button size='sm' style={{ margin: '2px', fontSize: '10px' }} variant="outline-primary" onClick={() => handleInspectUseCase(useCase.id)}>
                                <i className="bi bi-link"></i> Inspect Use Case
                            </Button>
                            </OverlayTrigger>

                            {useCase.adoption.value && (
                            <OverlayTrigger placement="top" overlay={<Tooltip>Use this use case as a template</Tooltip>}>
                            <Button size='sm' style={{ margin: '2px', fontSize: '10px' }} variant="outline-primary" onClick={() => handleUseAsTemplate(useCase.id)}>
                                <i className="bi bi-search"></i> Use as Template
                            </Button>
                            </OverlayTrigger>
                            )}

                            {getUserID() === useCase.user_id.value && (
                                <Button size='sm' style={{ margin: '2px', fontSize: '10px' }} variant="outline-danger" onClick={() => handleDeleteUseCase(useCase.id)}>
                                    <i className="bi bi-trash"></i> Delete
                                </Button>
                            )}
                            </Col>
                        </Row>    
                    </Card.Footer>
                </Card>
            </Col>
        ))}
    </Row>
);

export default renderCardView;