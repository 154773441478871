/**
 * Used to select a use case to compare it with other use cases
 *
 * Description: Allows the selection of use cases
 * Author: Marc Guerreiro Augusto
 * Version: 1.0.0
 * Date: 2024-07-13
 * 
 */

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Accordion, Badge } from 'react-bootstrap';

/**
 * Add Node Modal
 * 
 * @param {*} param0
 * @returns 
 */
const ModalCompare = ({ show, onHide, data, selectedUseCase, setSelectedUseCase, comparisonType, setComparisonType, selectedComparisonUseCases, setSelectedComparisonUseCases, handleStartAnalysis }) => {
  
  const [activeKey, setActiveKey] = useState(null);

  const openAccordionItem = (key) => {
    setActiveKey(key);
  };

  const handleUseCaseChange = (e) => {
    const useCaseId = e.target.value;
    const useCase = data.find((uc) => uc.id === useCaseId);
    setSelectedUseCase(useCase);
    if (useCase) {
      openAccordionItem('1');
    }
  };

  const handleComparisonTypeChange = (e) => {
    const type = e.target.value;
    setComparisonType(type);
    if (type === 'specific') {
      openAccordionItem('2');
    }
  };

  const handleSpecificUseCasesChange = (e) => {
    const useCaseId = e.target.value;
    const useCase = data.find((uc) => uc.id === useCaseId);
    if (useCase) {
      setSelectedComparisonUseCases((prev) => {
        if (prev.find((uc) => uc.id === useCase.id)) {
          return prev.filter((uc) => uc.id !== useCase.id); // Deselect
        } else {
          return [...prev, useCase]; // Select
        }
      });
    }
  };

  const handleBadgeClick = (useCaseId) => {
    setSelectedComparisonUseCases((prev) => prev.filter((uc) => uc.id !== useCaseId));
  };

  useEffect(() => {
    if (!selectedUseCase) {
      setActiveKey('0');
    }
  }, [selectedUseCase]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Use Case Analysis</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Set search parameter for ecosystem analysis</h6>

        <Accordion activeKey={activeKey} onSelect={setActiveKey} style={{ marginTop: '15px' }}>
          {/* Select use case in question */}
          <Accordion.Item eventKey="0">
            <Accordion.Header>Select use case in question</Accordion.Header>
            <Accordion.Body>
              <Form.Group controlId="useCaseSelect">
                <Form.Label>Select Use Case</Form.Label>
                <Form.Control as="select" onChange={handleUseCaseChange}>
                  <option value=''>-- Select use case --</option>
                  {data.map((useCase) => (
                    <option key={useCase.id} value={useCase.id}>
                      {useCase.title.value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>

          {/* Select whether the selected use case is compared to all modeled use cases, specific ones or none */}
          {selectedUseCase && (
            <Accordion.Item eventKey="1">
              <Accordion.Header>Compare to all or specific use cases</Accordion.Header>
              <Accordion.Body>
                <Form.Group controlId="comparisonTypeSelect">
                  <Form.Label>Compare <i>{selectedUseCase.title.value}</i> to</Form.Label>
                  <Form.Control as="select" onChange={handleComparisonTypeChange}>
                    <option value=''>-- Compare to --</option>
                    <option value='none'>None</option>
                    <option value='all'>All use cases</option>
                    <option value='specific'>Specific use cases</option>
                  </Form.Control>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          )}

          {/* Select specific use cases to compare to */}
          {comparisonType === 'specific' && (
            <Accordion.Item eventKey="2">
              <Accordion.Header>Select specific use cases</Accordion.Header>
              <Accordion.Body>
                <Form.Group controlId="specificUseCasesSelect">
                  <Form.Label>Select Use Cases</Form.Label>
                  <Form.Control as="select" multiple onChange={handleSpecificUseCasesChange}>
                    {data.map((useCase) => (
                      <option key={useCase.id} value={useCase.id}>
                        {useCase.title.value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {/* Add selected as badges */}
                {selectedComparisonUseCases.length > 0 && (
                  <div style={ { marginTop:'15px'} }>
                    <span><small>Added</small></span>
                    <div className="d-flex flex-wrap" style={ { marginTop:'15px'} }>
                      {selectedComparisonUseCases.map((useCase) => (
                        <Badge
                          key={useCase.id}
                          className="bg-light text-dark me-1"
                          onClick={() => handleBadgeClick(useCase.id)}
                          style={{ cursor: 'pointer', marginBottom: '5px'}}
                        >
                          {useCase.title.value}
                        </Badge>
                      ))}
                    </div>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          <i className="bi bi-arrow-left"></i> Go back
        </Button>
        {/* Start analysis button */}
        {selectedUseCase && comparisonType && (
          <Button 
            variant="success" 
            onClick={() => {
              handleStartAnalysis();
              onHide();
            }}
            >
            <i className="bi bi-cpu"></i> Start analysis
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCompare;